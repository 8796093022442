import React, { useContext } from 'react';

import { ENGAGEMENT_REPORT_CHART_DIMENSIONS } from '@learned/constants';
import Tippy from '@tippyjs/react';

import { DescriptionContainer, FirstText, LabelTextWrap } from './LucaDescription.design';

import { stripHtml } from '~/utils/stripHtml';

import { EngagementReportContext } from '../EngagementContext';

type TProps = {
  primaryDimensionValue?: string;
};

const LucaDescription = ({ primaryDimensionValue }: TProps): JSX.Element => {
  const { themesOptions, skillOptions, dimensions } = useContext(EngagementReportContext);

  let description = '';

  if (dimensions.primary === ENGAGEMENT_REPORT_CHART_DIMENSIONS.THEME) {
    description = stripHtml(
      themesOptions.find(({ key }) => key === primaryDimensionValue)?.description || '',
    ).trim();
  } else if (dimensions.primary === ENGAGEMENT_REPORT_CHART_DIMENSIONS.SKILL) {
    description = stripHtml(
      skillOptions.find(({ key }) => key === primaryDimensionValue)?.description || '',
    ).trim();
  }

  return description.length > 0 ? (
    <DescriptionContainer>
      <LabelTextWrap>
        <Tippy content={description}>
          <FirstText>{description}</FirstText>
        </Tippy>
      </LabelTextWrap>
    </DescriptionContainer>
  ) : (
    <></>
  );
};

export { LucaDescription };
