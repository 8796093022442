import React, { useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import nanoid from 'nanoid';
import { Controller, useWatch } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Editor from '~/components/Editor';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import { Input } from '~/components/Input';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { StepFooter } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/components/StepFooter';
import { useSkillCategories } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/hooks';

import { EditAliasModal } from './components/EditAliasModal';
import {
  CombinedSection,
  Form,
  InputContainer,
  Label,
  Section,
  SkillDescriptionHeader,
  StyledDropdown,
  Tag,
  Tags,
  Title,
} from './design';

import type { IBaseLanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import {
  generateSkillDescriptionSuperAdmin,
  generateTextTranslationSuperAdmin,
} from '~/services/completions';

import type { IGeneralForm } from './types';
import type { IMultiLangString, ISkillCategory } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

type StepDetailsProps = {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: IBaseLanguageStateReturn;
  skillCategory: ISkillCategory | undefined;
  setSkillCategory: (skillCategory?: ISkillCategory) => void;
};

function StepDetails({
  sectionState,
  formMethods,
  languageState,
  skillCategory,
  setSkillCategory,
}: StepDetailsProps) {
  const { skillCategories } = useSkillCategories();
  const { i18n } = useLingui();

  const [alias, setAlias] = useState('');
  const [editAlias, setEditAlias] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTranslations, setIsLoadingTranslations] = useState(false);
  const editAliasForm = formMethods.getValues('aliases')?.find((alias) => alias.id === editAlias);

  const { watch, getValues, register, unregister, control, trigger, handleSubmit, setValue } =
    formMethods;
  const skillName = getValues('name')?.find(
    (name) => name.locale === languageState.primaryLanguage.locale,
  );
  const skillNameWatch = useWatch({ name: 'name', control });
  const skillDescriptionWatch = useWatch({ name: 'description', control });
  const skillNameEnglish = skillNameWatch.find((value) => value.locale === 'en_GB');
  const skillDescriptionEnglish = skillDescriptionWatch.find((value) => value.locale === 'en_GB');
  const hasEnglishDescription =
    skillDescriptionEnglish?.value !== '' && skillDescriptionEnglish?.value !== undefined;

  const hasSomethingToTranslate = useMemo(() => {
    const locales = languageState.languages
      .map(({ locale }) => locale)
      .filter((locale) => locale !== 'en_GB');

    return (
      hasEnglishDescription &&
      !every(
        locales.map(
          (locale) => skillDescriptionWatch.find((value) => value.locale === locale)?.value,
        ),
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEnglishDescription, skillDescriptionWatch]);

  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'description',
    control,
    unregister,
    languageState,
  });

  const addAlias = () => {
    if (!alias) {
      return;
    }

    const aliasArray = formMethods.getValues('aliases') || [];
    aliasArray.push({ [languageState.primaryLanguage.locale]: alias, id: nanoid() });
    formMethods.setValue('aliases', aliasArray);
    setAlias('');
  };

  const updateAlias = (newValues: IMultiLangString & { id: string }) => {
    const aliasArray = formMethods.getValues('aliases') || [];
    const aliasIndex = aliasArray.findIndex((alias) => alias.id === newValues.id);
    aliasArray[aliasIndex] = newValues;
    formMethods.setValue('aliases', aliasArray);
    setEditAlias('');
  };

  const deleteAlias = (id: string) => {
    const aliasArray = formMethods.getValues('aliases') || [];
    const aliasIndex = aliasArray.findIndex((alias) => alias.id === id);
    aliasArray.splice(aliasIndex, 1);
    formMethods.setValue('aliases', aliasArray);
    setEditAlias('');
    trigger('aliases');
  };

  const onSubmit = () => sectionState.setCurrentSection(1);

  const translateDescription = async () => {
    setIsLoadingTranslations(true);
    const locales = languageState.languages
      .map(({ locale }) => locale)
      .filter((locale) => locale !== 'en_GB');
    const englishDescription = skillDescriptionWatch.find((value) => value.locale === 'en_GB');

    const textsToTranslate = englishDescription ? [englishDescription.value] : [];

    const response = await generateTextTranslationSuperAdmin(textsToTranslate, locales);

    if (response?.code !== 200 || isEmpty(response?.data?.translatedTexts)) {
      return;
    }
    const translatedTexts = response.data.translatedTexts as { [locale: string]: string }[];

    if (englishDescription) {
      const translatedDescription = translatedTexts.shift();
      Object.entries(translatedDescription!).forEach(([locale, value]) => {
        const index = skillDescriptionWatch.findIndex(
          (value) =>
            value.locale === locale &&
            (value.value === '' || value.value === undefined || value.value === null),
        );
        if (index === -1) {
          return;
        }
        setValue(`description.${index}.value`, value);
      });
    }

    setIsLoadingTranslations(false);
  };

  const generateDescription = async () => {
    if (!skillNameEnglish?.value || !skillCategory) {
      return;
    }

    setIsLoading(true);

    const response = await generateSkillDescriptionSuperAdmin(
      skillNameEnglish.value,
      skillCategory.id,
    );

    if (response.code !== 200) {
      setIsLoading(false);
      return;
    }

    const { description } = response.data;
    const index = watch('description').findIndex((value) => value.locale === 'en_GB');
    setValue(`description.${index}.value`, description);
    setIsLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <Title>
        <Trans>Skill or KPI details</Trans>
      </Title>
      <CombinedSection>
        <Section $marginTop={14}>
          <Label>
            <Trans>Customise name</Trans>
          </Label>
          <InputContainer>
            {nameFieldArray.fields.map((field) => (
              <Controller
                key={field.id}
                {...register(`name.${field.index}.value`)}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      value={value}
                      placeholder={i18n._(t`Name`)}
                      error={sectionState.triedToSubmit && !!formMethods.formState.errors?.name}
                      onChange={(args) => {
                        onChange(args);
                        trigger('name');
                      }}
                      key={field.id}
                      width="318px"
                      height="38px"
                      leftIcon={
                        size(languageState.languages) > 1
                          ? getUnicodeFlagIcon(
                              field.locale.substring(field.locale.indexOf('_') + 1),
                            )
                          : undefined
                      }
                    />
                  );
                }}
              />
            ))}
          </InputContainer>
        </Section>
        <Section $marginTop={14}>
          <Label>
            <Trans>Category</Trans>
          </Label>
          <InputContainer>
            <Controller
              {...register('skillCategory')}
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledDropdown
                  $error={
                    sectionState.triedToSubmit && !!formMethods.formState.errors?.skillCategory
                  }
                  selectedItem={skillCategories.find((item) => item.id === value)}
                  items={skillCategories}
                  onChange={(item) => {
                    onChange(item?.id);
                    setSkillCategory(item);
                  }}
                  stringifyItem={(item) => item.name[languageState.primaryLanguage.locale]}
                  isSingleSelect
                />
              )}
            />
          </InputContainer>
        </Section>
      </CombinedSection>
      <Section $marginTop={32}>
        <Label>
          <Trans>Aliases</Trans>
        </Label>
        <Input
          type="text"
          placeholder={i18n._(t`[Alias name]`)}
          value={alias}
          onChange={(e) => setAlias(e.target.value)}
          width="281px"
          height="38px"
          rightIcon={
            <Button
              type="button"
              variant={ButtonVariant.ICON}
              size={ButtonSize.MEDIUM}
              icon={ICONS.ADD_PLUS}
              iconSize={ICON_SIZES.SMALL}
              onClick={(event) => {
                addAlias();
                event.preventDefault();
              }}
            />
          }
        />
        <Tags>
          {formMethods
            .getValues('aliases')
            ?.map((alias: IMultiLangString & { id: string }, index: number) => (
              <Tag onClick={() => setEditAlias(alias.id)} key={index}>
                {alias[languageState.primaryLanguage.locale]}
                <Button
                  type="button"
                  variant={ButtonVariant.ICON}
                  size={ButtonSize.MEDIUM}
                  icon={ICONS.CLOSE_2}
                  iconSize={ICON_SIZES.SMALL}
                  onClick={(e) => {
                    deleteAlias(alias.id);
                    e.stopPropagation();
                  }}
                />
              </Tag>
            ))}
        </Tags>
      </Section>
      <Section $marginTop={47}>
        <SkillDescriptionHeader>
          <Label>
            <Trans>Description</Trans>
          </Label>
          <div>
            <Button
              disabled={isLoadingTranslations || !hasSomethingToTranslate}
              type="button"
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              label={<Trans>Translate description</Trans>}
              onClick={translateDescription}
              isLoading={isLoadingTranslations}
            />
            <Tooltip
              size={TOOLTIP_SIZES.DEFAULT}
              disabled={!!skillNameEnglish?.value}
              tooltip={i18n._(
                t`Please fill the name of the skill to make use of AI generated content.`,
              )}
            >
              <div>
                <Button
                  disabled={!skillNameEnglish?.value || isLoading || hasEnglishDescription}
                  type="button"
                  variant={ButtonVariant.SECONDARY}
                  size={ButtonSize.MEDIUM}
                  label={<Trans>Generate description</Trans>}
                  onClick={generateDescription}
                  isLoading={isLoading}
                />
              </div>
            </Tooltip>
          </div>
        </SkillDescriptionHeader>
        <InputContainer>
          {descriptionFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`description.${field.index}.value`)}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Editor
                  minHeight={'231px'}
                  value={value}
                  compact
                  onChange={onChange}
                  big
                  leftIcon={
                    size(languageState.languages) > 1
                      ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                      : undefined
                  }
                />
              )}
            />
          ))}
        </InputContainer>
      </Section>
      <StepFooter onPrev={() => sectionState.setCurrentSection(0)} />
      {editAliasForm && (
        <EditAliasModal
          skillName={skillName?.value}
          alias={editAliasForm}
          languageState={languageState}
          onSave={(newValues) => updateAlias({ ...newValues, id: editAlias })}
          onDelete={(id) => deleteAlias(id)}
          onClose={() => setEditAlias('')}
        />
      )}
    </Form>
  );
}

export { StepDetails };
