import { useSelector } from 'react-redux';

import { getInactiveUsers } from '~/selectors/baseGetters';

const useInactiveUsers = () => {
  const inactiveUsers = useSelector(getInactiveUsers);

  const removeInactiveUsers = (userIds?: string[]) => {
    return userIds?.filter((id) => !inactiveUsers[id]) || [];
  };

  return { removeInactiveUsers };
};

export { useInactiveUsers };
