import React, { useEffect, useState } from 'react';

import { GOAL_STATUSES_NEW } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';

import { Icon, ICONS } from '~/components/Icon';

import { Section, GoalCardWrapper, Label, PlaceholderWrapper, PlaceholderText } from './design';
import { GoalProgress } from './GoalProgress';

import noResult from '~/assets/no-result.webp';

import convertToTimeString from '~/utils/convertToTimeString';

import type { IGoalsSectionDashboard } from '../types';
import type { IGoalCycle } from '@learned/types';

export const GoalsSection = ({
  sections,
  onGoalClick,
}: {
  sections: IGoalsSectionDashboard[];
  onGoalClick: (goalId: string) => void;
}) => {
  const { i18n } = useLingui();
  const [goalsSections, setGoalsSections] = useState<IGoalsSectionDashboard[]>(
    sections.map((item) => ({ ...item, isOpen: false })),
  );

  useEffect(() => {
    setGoalsSections((prevSections) =>
      sections.map((item) => {
        // keep the current state of section after updating the goals
        const isOpen = prevSections.find((section) => section.key === item.key)?.isOpen || false;
        return { ...item, isOpen };
      }),
    );
  }, [sections]);

  const visibleSections = goalsSections.filter(({ items }) => items?.length);

  return (
    <>
      {isEmpty(visibleSections) ? (
        <PlaceholderWrapper>
          <img src={noResult} alt={i18n._(t`No goals created`)} width="216px" />
          <PlaceholderText>{i18n._(t`No goals created`)}</PlaceholderText>
        </PlaceholderWrapper>
      ) : (
        visibleSections.map(({ key, title, color, icon, items, isCollapsible, isOpen }) => {
          return (
            <Section key={key}>
              <Label
                color={color}
                isOpen={isOpen}
                isClickable={isCollapsible}
                onClick={() => {
                  if (isCollapsible) {
                    setGoalsSections((prevState) =>
                      prevState.map((item) => ({
                        ...item,
                        isOpen: item.key === key ? !item.isOpen : item.isOpen,
                      })),
                    );
                  }
                }}
              >
                <div className="left">
                  <Icon icon={icon} />
                  <span>{title}</span>
                </div>
                {isCollapsible && (
                  <div className="right">
                    <Icon icon={ICONS.BACK} />
                  </div>
                )}
              </Label>
              {isCollapsible && !isOpen ? (
                <></>
              ) : (
                <>
                  {items?.map((goal) => {
                    const goalCycle = goal.goalCycles?.[0] as unknown as IGoalCycle | undefined;

                    return (
                      <GoalCardWrapper key={goal.id} onClick={() => onGoalClick(goal.id)}>
                        <div className="name">{goal.name}</div>
                        {key === GOAL_STATUSES_NEW.IN_PROGRESS ? (
                          <GoalProgress goal={goal} />
                        ) : (
                          <>
                            {goalCycle && (
                              <div className="timeFrame">
                                {convertToTimeString(goalCycle.startDate)} |{' '}
                                {convertToTimeString(goalCycle.endDate)}
                              </div>
                            )}
                          </>
                        )}
                      </GoalCardWrapper>
                    );
                  })}
                </>
              )}
            </Section>
          );
        })
      )}
    </>
  );
};
