import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getNote(noteId) {
  return cloudRequest(serverRoutes.notes.getNote(noteId));
}

export function getNotes() {
  return cloudRequest(serverRoutes.notes.getNotes);
}

export function createNote(userId, note) {
  return cloudRequest(serverRoutes.notes.createNote(userId), {}, { value: note });
}

export function updateNote(noteId, updatedNote) {
  return cloudRequest(serverRoutes.notes.updateNote(noteId), {}, { value: updatedNote });
}

export function removeNote(noteId) {
  return cloudRequest(serverRoutes.notes.removeNote(noteId));
}
