import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';

import { API_RETURN_FIELDS, NEXT_STEP_SORT_OPTIONS } from '@learned/constants';
import { ITaskOld, ITaskWithId, TASKS_STATUSES } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import {
  AutocompleteFilterConversations,
  AutocompleteFilterReviews,
} from '~/components/AutocompleteFilters';
import { Dropdown } from '~/components/Dropdown';
import OverviewHeading from '~/components/OverviewHeading';
import { TableList } from '~/components/TableList';
import BaseLayout from '~/layouts/BaseLayout';

import { COLUMNS } from './nextStepsColumns';

import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { checkModuleReviews, checkModuleConversations } from '~/selectors/baseGetters';
import { fetchNextSteps, switchCompletedState } from '~/services/nextSteps';
import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);

  tr {
    &:hover {
      svg {
        stroke: ${COLORS.COMPANY};
      }
    }
  }
`;

const FilterConversations = styled(AutocompleteFilterConversations)`
  width: 121px;
  height: 32px;
  font-size: 12px;
`;

const FilterReviews = styled(AutocompleteFilterReviews)`
  width: 121px;
  height: 32px;
  font-size: 12px;
`;

const StatusDropDown = styled(Dropdown)`
  width: 121px;
  height: 32px;
`;

const FiltersWrapper = styled.div`
  display: flex;
`;

const GapWrapper = styled.div`
  margin-right: 9px;
`;

type IFilter = {
  search: string;
  statuses: TASKS_STATUSES[];
  sortBy: NEXT_STEP_SORT_OPTIONS;
  oneOnOnes: Array<string>;
  conversations: Array<string>;
  pagination: { skip: number; limit: number; index: number };
};

type StatusDropDownItem = {
  name: string;
  value: string;
};

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };

const initialFilters: IFilter = {
  search: '',
  statuses: [TASKS_STATUSES.ACTIVE],
  sortBy: NEXT_STEP_SORT_OPTIONS.CREATED_NEW_OLD,
  oneOnOnes: [],
  conversations: [],
  pagination: DEFAULT_PAGINATION,
};

const statusDropDownItems: Array<StatusDropDownItem> = [
  { name: 'Open', value: TASKS_STATUSES.ACTIVE },
  { name: 'Completed', value: TASKS_STATUSES.COMPLETED },
  { name: 'All', value: 'all' },
];

const NextSteps: FunctionComponent = (): ReactElement => {
  const getMultiLangString = useMultiLangString();
  const [nextSteps, setNextSteps] = useState<Array<ITaskWithId>>([]);
  const [currentFilters, setCurrentFilters] = useState<IFilter>(initialFilters);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOneOnOnes, setSelectedOneOnOnes] = useState([]);
  const [selectedConversations, setSelectedConversations] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState(
    statusDropDownItems.find((item) => item.value === TASKS_STATUSES.ACTIVE),
  );
  const [totalCount, setTotalCount] = useState(0);
  const [selectedSteps, setSelectedSteps] = useState<string[]>([]);
  const [isAllDone, setIsAllDone] = useState<boolean>(false);
  const { i18n } = useLingui();
  const history = useHistory();

  const isModuleReviewsEnabled = useSelector(checkModuleReviews);
  const isModuleMeetingsEnabled = useSelector(checkModuleConversations);

  const debCurrentFilters = useDebounce(currentFilters, 500);

  useEffect(() => {
    if (!isModuleMeetingsEnabled && !isModuleReviewsEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleReviewsEnabled, isModuleMeetingsEnabled, history]);

  const transfromData = (steps: Array<ITaskOld> = []): Array<ITaskWithId> => {
    return steps.map((step) => ({
      ...step,
      id: step.target.taskId!,
      target: {
        ...step.target,
        targetName: getMultiLangString(step?.target?.targetName ?? ''),
      },
    }));
  };

  const setSelectedStepsWhileLoading = async (steps: Array<ITaskWithId>): Promise<void> => {
    steps.forEach((step: ITaskWithId) => {
      if (step.taskStatus === TASKS_STATUSES.COMPLETED) {
        setSelectedSteps((prev) => [...prev, step.id]);
      }
    });
  };

  const updateSteps = ({ stepId, isCompleted }: { stepId: string; isCompleted: boolean }) => {
    setNextSteps((prev) => {
      return prev.map((step: ITaskWithId) => {
        if (step.id === stepId) {
          return {
            ...step,
            taskStatus: isCompleted ? TASKS_STATUSES.COMPLETED : TASKS_STATUSES.ACTIVE,
          };
        }
        return step;
      });
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    const { data } = await fetchNextSteps({
      onlyActiveStepsCount: false,
      filters: currentFilters,
      options: { sortBy: currentFilters.sortBy, ...currentFilters.pagination },
    });
    const transformedData = transfromData(data[API_RETURN_FIELDS.NEXT_STEPS]);
    setNextSteps(transformedData);
    setSelectedStepsWhileLoading(transformedData);
    setTotalCount(data[API_RETURN_FIELDS.TOTAL_NEXT_STEPS]);
    setIsAllDone(data[API_RETURN_FIELDS.TOTAL_ACTIVE_NEXT_STEPS] === 0);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debCurrentFilters]);

  const filters = {
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value, pagination: DEFAULT_PAGINATION });
    },
  };

  const isAllSelected = nextSteps.every((step) => {
    return selectedSteps.includes(step.id);
  });

  const markAndUnmarkAllAsCompleted = async (
    selectedSteps: Array<string>,
    isSelected: boolean,
  ): Promise<void> => {
    const promiseList = selectedSteps.map(async (stepId: string) => {
      const data = { stepId, isCompleted: isSelected };
      updateSteps(data);
      return switchCompletedState(data);
    });
    await Promise.all(promiseList);
  };

  const onSelectAll = () => {
    const allStepIds = nextSteps.map((step) => step.id);
    const selectedStepsIds = isAllSelected ? [] : allStepIds;
    isAllSelected
      ? markAndUnmarkAllAsCompleted(allStepIds, false)
      : markAndUnmarkAllAsCompleted(allStepIds, true);
    setSelectedSteps(selectedStepsIds);
  };

  const onSelectStep = async (step: ITaskWithId) => {
    const isSelected = selectedSteps.includes(step.id);
    const data = { stepId: step.id, isCompleted: false };

    if (isSelected) {
      setSelectedSteps(selectedSteps.filter((stepId) => stepId !== step.id));
    } else {
      data.isCompleted = true;
      setSelectedSteps([...selectedSteps, step.id]);
    }
    updateSteps(data);
    await switchCompletedState(data);
  };

  const multiSelect = {
    checkedCount: selectedSteps.length,
    onCheckAll: onSelectAll,
    onSelectItem: onSelectStep,
    isItemChecked: (step: ITaskWithId) => selectedSteps.includes(step.id),
    isAllChecked: isAllSelected,
  };

  const onSelectOneOnOneFilter = (value: []) => {
    const oneOnOneTypes = value.map((oneOnOne: any) => oneOnOne.name);
    setCurrentFilters({
      ...currentFilters,
      oneOnOnes: oneOnOneTypes,
      pagination: DEFAULT_PAGINATION,
    });
    setSelectedOneOnOnes(value);
  };

  const onSelectConversationFilter = (value: []) => {
    const conversationTypes = value.map((conversation: any) => conversation.name);
    setCurrentFilters({
      ...currentFilters,
      conversations: conversationTypes,
      pagination: DEFAULT_PAGINATION,
    });
    setSelectedConversations(value);
  };

  const onSelectStatusesFilter = (selectedItem: any) => {
    const statuses =
      selectedItem.value === 'all'
        ? [TASKS_STATUSES.ACTIVE, TASKS_STATUSES.COMPLETED]
        : selectedItem.value === TASKS_STATUSES.ACTIVE
        ? [TASKS_STATUSES.ACTIVE]
        : [TASKS_STATUSES.COMPLETED];
    setCurrentFilters({ ...currentFilters, statuses, pagination: DEFAULT_PAGINATION });
    setSelectedStatuses(selectedItem as any);
  };

  const checkForFilters = (): boolean => {
    const isFilterSelected =
      !!currentFilters.search.length ||
      !!currentFilters.oneOnOnes.length ||
      !!currentFilters.conversations.length;

    return isAllDone && !isFilterSelected;
  };

  const onResetSearchFilters = (): void => {
    setCurrentFilters(initialFilters);
    setSelectedStatuses(statusDropDownItems.find((item) => item.value === TASKS_STATUSES.ACTIVE));
    setSelectedConversations([]);
    setSelectedOneOnOnes([]);
  };

  return (
    <>
      {/* @ts-ignore */}
      <OverviewHeading title={i18n._(t`Next steps`)} smallWidth />
      <BaseLayout smallWidth>
        <Wrapper>
          <TableList
            data={nextSteps}
            columns={COLUMNS}
            paginationProps={{
              pagination: currentFilters.pagination,
              changePagination: ({ skip, limit, index }) =>
                setCurrentFilters({
                  ...currentFilters,
                  pagination: { ...currentFilters.pagination, skip, limit, index },
                }),
              totalCount,
            }}
            isLoading={isLoading}
            filtersProps={{
              filters,
              resetFilters: onResetSearchFilters,
              isFiltered:
                !!currentFilters.search.length ||
                !!currentFilters.statuses.length ||
                !!currentFilters.oneOnOnes.length ||
                !!currentFilters.conversations.length,
              isToggleHideFilterVisible: true,
              filterComponents: (
                <FiltersWrapper>
                  <GapWrapper>
                    <FilterConversations
                      checkedList={selectedOneOnOnes}
                      onChange={onSelectOneOnOneFilter}
                      showTooltip
                      placeholder={i18n._(t`1:1 Meeting`)}
                    />
                  </GapWrapper>
                  <GapWrapper>
                    <FilterReviews
                      checkedList={selectedConversations}
                      onChange={onSelectConversationFilter}
                      showTooltip
                    />
                  </GapWrapper>
                  <StatusDropDown
                    placeholder={i18n._(t`Status`)}
                    items={statusDropDownItems}
                    selectedItem={selectedStatuses}
                    onChange={onSelectStatusesFilter}
                    stringifyItem={(item: any) => `${i18n._(t`${item.name}`)}`}
                    isSingleSelect
                  />
                </FiltersWrapper>
              ),
            }}
            placeholderProps={{
              emptyStateText: i18n._(t`All Next Steps completed!`),
            }}
            sortProps={{
              sortBy: currentFilters.sortBy,
              setSortBy: (sortBy: NEXT_STEP_SORT_OPTIONS) =>
                setCurrentFilters({ ...currentFilters, sortBy }),
            }}
            multiSelectProps={{
              isMultiSelectVisible: true,
              isSelectedCountVisible: false,
              multiSelect,
              isSelectAllVisible: false,
            }}
            isAllDone={checkForFilters()}
          />
        </Wrapper>
      </BaseLayout>
    </>
  );
};

export { NextSteps };
