import { ROLES } from '~/constants';
import {
  pageDashboard,
  pageCreate,
  pageUpdate,
  pageSupervisionUser,
} from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'reviews';

// overview
// for REVIEWS overview page we use route
// history.push(routes.CONVERSATIONS.build(
//         { role: ROLES.USER },
//         { hash: 'development-conversation_cycle' },
//       ))

/**
 * @deprecated since Review 3.0 (check reviews.ts)
 */
// dashboard
export const REVIEW = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ reviewId = ':reviewId' }) => pageDashboard(routeName, { id: reviewId }),
);

/**
 * @deprecated since Review 3.0 (check reviews.ts)
 */
// create
export const REVIEW_CREATE = new LinkConstructor([ROLES.USER, ROLES.COACH, ROLES.ADMIN], () =>
  pageCreate(routeName),
);

/**
 * @deprecated since Review 3.0 (check reviews.ts)
 */
// update
export const REVIEW_UPDATE = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ reviewId = ':reviewId' }) => pageUpdate(routeName, { id: reviewId }),
);

/**
 * @deprecated since Review 3.0 (check reviews.ts)
 */
// incoming requests
export const REVIEWS_INCOMING_REQUESTS = new LinkConstructor(
  [ROLES.USER],
  () => `${routeName}-incoming-requests`,
);
/**
 * @deprecated since Review 3.0 (check reviews.ts)
 */
export const REVIEWS_INCOMING_REPORT = new LinkConstructor(
  [ROLES.USER],
  ({ requestId = ':requestId' }) => `${routeName}-incoming-requests/${requestId}/report`,
);

/**
 * @deprecated since Review 3.0 (check reviews.ts)
 */
// supervision
export const REVIEW_SUPERVISION_USER = new LinkConstructor(
  [ROLES.COACH, ROLES.ADMIN],
  ({ reviewId = ':reviewId' }) => pageSupervisionUser(routeName, { id: reviewId }),
);
