import React from 'react';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import CollapseIndicator from '~/pages/Reports/Components/IconWrapper';

import { IRowTransformed } from '../../../types';
import { CellContent, CellLabelWrapper, CellWrapper, OneLineWrapper } from '../design';

interface IFirstCellThemeProps {
  item: IRowTransformed;
  onClick: (item: IRowTransformed) => void;
}

const FirstCellTheme = ({ item, onClick }: IFirstCellThemeProps) => {
  return (
    <CellWrapper
      clickable={!!onClick}
      onClick={() => {
        onClick ? onClick(item) : {};
      }}
    >
      <Tooltip tooltip={item.name} size={TOOLTIP_SIZES.BIG}>
        <OneLineWrapper>
          <CollapseIndicator
            isCollapsed={item.isCollapsed}
            handleOnClick={() => {
              onClick ? onClick(item) : {};
            }}
          />
          <CellLabelWrapper>
            <CellContent>{item.name}</CellContent>
            {item.children?.length && (
              <span>
                {item.children.length} {`${item.children.length > 1 ? 'Questions' : 'Question'}`}
              </span>
            )}
          </CellLabelWrapper>
        </OneLineWrapper>
      </Tooltip>
    </CellWrapper>
  );
};

export { FirstCellTheme };
