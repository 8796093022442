import { Locals_all, SKILL_TEMPLATE_SORT_OPTIONS } from '@learned/constants';
import FileSaver from 'file-saver';

import type { IServerRoute, ICreateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { IMultiLangString, ISkillTemplate, ISkillTemplateCreate } from '@learned/types';

export function createSkillTemplateSuperAdmin(skillTemplate: Partial<ISkillTemplateCreate>) {
  return cloudRequest(
    serverRoutes.skillTemplates.createSuperAdmin as IServerRoute,
    {},
    skillTemplate,
  );
}

export function updateSkillTemplateSuperAdmin(
  skillTemplateId: string,
  data: Partial<ISkillTemplate> & {
    skillTemplatePendingId?: string;
    addedAlias?: IMultiLangString;
  },
) {
  return cloudRequest(
    (serverRoutes.skillTemplates.updateSuperAdmin as ICreateServerRoute)(skillTemplateId),
    {},
    data,
  );
}

export function listSkillTemplatesSuperAdmin(
  filters: {
    search?: string;
    categoryId?: string;
    statuses?: string[];
    industries?: string[];
    skillTemplateIds?: string[];
    isQualityFilter?: boolean;
  },
  options: { skip: number; limit: number; sortBy: SKILL_TEMPLATE_SORT_OPTIONS },
) {
  const body = {
    filters: {
      search: filters.search,
      categoryId: filters.categoryId,
      statuses: filters.statuses,
      industries: filters.industries,
      skillTemplateIds: filters.skillTemplateIds,
      isQualityFilter: filters.isQualityFilter,
    },
    options: {
      skip: options.skip,
      limit: options.limit,
      sortBy: options.sortBy,
    },
  };
  return cloudRequest(serverRoutes.skillTemplates.listSuperAdmin as IServerRoute, {}, body);
}

export function listSkillTemplates(
  filters: {
    search?: string;
    categoryId?: string;
  } = {},
  options: { skip?: number; limit?: number; sortBy?: SKILL_TEMPLATE_SORT_OPTIONS } = {},
  locale: string,
) {
  const body = {
    filters: {
      search: filters.search,
      categoryId: filters.categoryId,
    },
    options: {
      skip: options.skip,
      limit: options.limit,
      sortBy: options.sortBy,
    },
    locale,
  };
  return cloudRequest(serverRoutes.skillTemplates.list as IServerRoute, {}, body);
}

export function getSkillTemplateSuperAdmin(skillTemplateId: string) {
  return cloudRequest(
    (serverRoutes.skillTemplates.getSuperAdmin as ICreateServerRoute)(skillTemplateId),
  );
}

export function getSkillTemplate(skillTemplateId: string) {
  return cloudRequest((serverRoutes.skillTemplates.get as ICreateServerRoute)(skillTemplateId));
}

export function updateSkillTemplatesSuperAdmin(
  skillTemplateIds: string[],
  data: Partial<ISkillTemplate>,
) {
  return cloudRequest(
    serverRoutes.skillTemplates.updateManySuperAdmin as IServerRoute,
    {},
    {
      skillTemplateIds,
      status: data.status,
    },
  );
}

export function downloadSkillTemplatesCSVSuperAdmin(
  filters: { search?: string; categoryId?: string; statuses?: string[]; industries?: string[] },
  options: { sortBy: SKILL_TEMPLATE_SORT_OPTIONS },
) {
  const body = {
    filters: {
      search: filters.search,
      categoryId: filters.categoryId,
      statuses: filters.statuses,
      industries: filters.industries,
    },
    options: {
      sortBy: options.sortBy,
    },
  };
  return cloudRequest(serverRoutes.skillTemplates.getCSVSuperAdmin as IServerRoute, {}, body).then(
    (response) => {
      FileSaver.saveAs(response, 'skillTemplates.csv');
    },
  );
}

export function generateSkillTemplate(name: string, locales: Locals_all[], skillCategory: string) {
  return cloudRequest(
    serverRoutes.skillTemplates.generateSkillTemplate as IServerRoute,
    {},
    { name, locales, skillCategory },
  );
}
