import React from 'react';

import { ChartBarBg, ChartBarText, ChatBarRating } from './design';

import { COLORS } from '~/styles';

interface ChartBarProps {
  text: string;
  rating: number;
  isLargeBar?: boolean;
  labelCount: number | undefined;
}

const BarColors = {
  Coach: COLORS.REVIEW_COACH,
  Self: COLORS.REVIEW_SELF,
  Peer: COLORS.PEER_REVIEW_DARK,
};

const ChartBar = ({ text, rating, labelCount = 5, isLargeBar = false }: ChartBarProps) => {
  const color = BarColors[text as keyof typeof BarColors];
  return (
    <ChartBarBg
      color={color}
      isLargeBar={isLargeBar}
      width={rating ? (rating / labelCount) * 100 : undefined}
    >
      <ChartBarText zeroValueColor={!rating ? color : undefined} isLargeBar={isLargeBar}>
        {text}
      </ChartBarText>
      {rating !== 0 && <ChatBarRating isLargeBar={isLargeBar}>{rating}</ChatBarRating>}
    </ChartBarBg>
  );
};

export { ChartBar };
