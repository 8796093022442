import { useState, useRef, useCallback } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import debounce from 'lodash/debounce';

import { LastSavedStatus } from '~/constants/lastSaved';

const DELAY = 10000;

// We use ref here primarily to:
// 1. Preserve the Callback Across Renders:
// React functions, including custom hooks, get re-executed on each render.
// If we passed the callback function directly to debounce without using a ref,
// it could result in a new debounced function instance every render.
// By using a ref for the callback function, we ensure that the debouncedSave function refers to the same callback across renders,
// preventing unnecessary re-creations of the debounced function and avoiding potential memory leaks.

// 2. Keep the Latest Callback Instance:
// The callbackRef.current is updated on each render to always hold the latest version of the callback function passed to useAutoSave.
// This is especially useful if the callback function depends on other state or props from the component.
// Using a ref allows us to safely reference the latest version of the callback
// in debouncedSave without needing to recreate the debounced function every time the component rerenders.

export type useAutoSaveFunc = {
  state: IState;
  run: (...args: any[]) => void;
  flush: () => void;
  cancel: () => void;
  executeNow: (...args: any[]) => void;
};

const initState = {
  time: undefined,
  status: undefined,
  message: undefined,
};

interface IState {
  time?: Date | undefined;
  status: LastSavedStatus | undefined;
  message: string | undefined;
}

const useAutoSave = (
  Fn: (...args: any[]) => Promise<void>,
  { onSuccess: onSuccessFunc = () => {}, onFail: onFailFunc = () => {} } = {},
): useAutoSaveFunc => {
  const { i18n } = useLingui();

  const [state, setState] = useState<IState>(initState);

  const callbackRef = useRef(Fn);

  // Update the callback ref if it changes
  callbackRef.current = Fn;

  // Helper function to execute callback with error handling
  const invokeCallback = async (...args: any[]) => {
    try {
      if (!navigator.onLine) {
        onNetworkOffline();
      } else {
        await callbackRef.current(...args);
        onSuccess();
      }
    } catch (e) {
      let message = null;
      if ((e as Error).message === 'Network Error') {
        // This is a network error.
        message = i18n._(t`Network error`);
      }
      onFail(message);
    }
  };

  // Define the debounced function for auto-saving
  const debouncedSave = useRef(debounce(invokeCallback, DELAY)).current;

  const onSuccess = () => {
    onSuccessFunc();
    setState({
      time: new Date(),
      status: LastSavedStatus.SUCCESS,
      message: undefined,
    });
  };

  const onFail = (message?: string | null) => {
    onFailFunc();
    setState({
      status: LastSavedStatus.ERROR,
      message: message || i18n._(t`Auto save is failed!`),
    });
  };

  const onNetworkOffline = () => {
    setState({
      status: LastSavedStatus.ERROR,
      message: i18n._(t`Attempting to reconnect. Please check your connection.`),
    });
  };

  // Run method triggers the debounced save
  const run = useCallback((...args: any[]) => {
    debouncedSave(...args);

    // eslint-disable-next-line
  }, []);

  // Cancel any pending debounced calls
  const cancel = useCallback(() => {
    debouncedSave.cancel();

    // eslint-disable-next-line
  }, []);

  // Immediately invoke any pending debounced calls
  const flush = useCallback(() => {
    debouncedSave.flush();

    // eslint-disable-next-line
  }, []);

  // Method to immediately execute the auto-save without debounce
  const executeNow = useCallback((...args: any[]) => {
    invokeCallback(...args); // Immediate execution

    // eslint-disable-next-line
  }, []);

  return {
    state,
    run,
    cancel,
    flush,
    executeNow,
  };
};

export { useAutoSave };
