import React, { useContext } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { COLORS } from '~/styles';

import { EngagementReportContext } from '../engagement/EngagementContext';

const Container = styled.div`
  position: absolute;
  right: 0;
  bottom: -2px;
  transform: translateY(100%);
  min-width: 220px;
  z-index: 100;
  cursor: pointer;
  background: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  padding: 2px;
  font-weight: normal;
  border-radius: 6px;
  border: 1px solid ${COLORS.BORDERS};
`;

const TextCtr = styled.button<{ isDisabled?: boolean }>`
  all: unset;
  font-size: 12px;
  padding: 11px 16px;
  color: ${({ isDisabled }) => (isDisabled ? COLORS.SUBTEXT : COLORS.TEXT_MAIN)};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${({ isDisabled }) => (isDisabled ? COLORS.TRANSPARENT : COLORS.HOVER)};
    color: ${({ isDisabled }) => (isDisabled ? COLORS.SUBTEXT : COLORS.COMPANY)};
  }
  &:first-child {
    border-radius: 6px 6px 0 0;
  }
  &:last-child {
    border-radius: 0 0 6px 6px;
  }
`;

export enum DOWNLOAD_TYPE {
  CSV = 'CSV',
  PDF = 'PDF',
}

type TProps = {
  onOptionSelect: (type: DOWNLOAD_TYPE) => void;
  reference: React.RefObject<HTMLDivElement>;
};
function DownloadMenu({ onOptionSelect, reference }: TProps): JSX.Element | null {
  const { i18n } = useLingui();
  const { tabConfiguration } = useContext(EngagementReportContext);

  return (
    <Container ref={reference}>
      {/* for now only csv is enabled */}
      {tabConfiguration?.controls.allowCSVExport && (
        <TextCtr onClick={() => onOptionSelect(DOWNLOAD_TYPE.CSV)}>
          {i18n._(t`Save as CSV`)}
        </TextCtr>
      )}
    </Container>
  );
}

export default DownloadMenu;
