import React from 'react';

import { REVIEW_QUESTION_EVALUATORS, REVIEW_QUESTION_TYPES } from '@learned/constants';
import {
  IReviewQuestion,
  IReviewQuestionDefaultData,
  IReviewTheme,
  ISkillCategory,
} from '@learned/types';
import { IMultiLangString } from '@learned/types/src/multiLangString';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import { IconNames } from '~/components/IconOld';
import { PreviewQuestionCard } from '~/components/Question/PreviewQuestionCard';
import { PopulatedReviewTheme } from '~/pages/ReviewThemeView/types';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import { DASHBOARD_TYPE } from '../constants';
import { MainInfoWrap, QuestionsBlockHeader, QuestionsListWrap, RatingScaleError } from '../design';

const StyledPreviewQuestionCard = styled(PreviewQuestionCard)`
  margin-bottom: 10px;
  width: auto;
`;

interface IQuestionsBlock {
  differentRatingScales: string[];
  questions: (IReviewQuestion & { themeName?: IMultiLangString } & { themeIcon?: IconNames } & {
    themeIconColor?: string;
  } & {
    skillCategory?: ISkillCategory;
  })[];
  languageState: ILanguageStateReturn;
  dashboardType: DASHBOARD_TYPE;
  setPreviewQuestion: (question: IReviewQuestion) => void;
  theme?: IReviewTheme | PopulatedReviewTheme;
  showTemplates?: boolean;
}

const evaluatorsOrder = [
  REVIEW_QUESTION_EVALUATORS.EMPLOYEE,
  REVIEW_QUESTION_EVALUATORS.PEER,
  REVIEW_QUESTION_EVALUATORS.COACH,
];

const QuestionsBlock = ({
  questions,
  dashboardType,
  languageState,
  differentRatingScales,
  setPreviewQuestion,
  theme,
  showTemplates = true,
}: IQuestionsBlock) => {
  const getMultiLangString = useMultiLangString();
  return (
    <MainInfoWrap>
      <QuestionsBlockHeader>
        <Trans>Questions</Trans>
      </QuestionsBlockHeader>
      {differentRatingScales?.length > 0 && (
        <RatingScaleError>
          <Banner
            type={BANNER_TYPES.WARNING}
            subTitle={i18n._(
              t`Please make sure all rated questions in a theme use the same rating scale. Otherwise it
            will not be possible to calculate an average rating of this theme.`,
            )}
          />
        </RatingScaleError>
      )}
      <QuestionsListWrap>
        {questions.map((question, idx) => {
          return (
            <StyledPreviewQuestionCard
              onClickTitle={() => setPreviewQuestion(question)}
              error={differentRatingScales.includes(question.id) ? 'Different rating scale' : ''}
              key={question.id}
              languageState={languageState}
              index={idx}
              type={question.type}
              name={question.name}
              isPreselectedLang
              showTemplates={showTemplates}
              theme={theme}
              skillCategory={
                question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY && question.skillCategory
                  ? getMultiLangString(question.skillCategory.name)
                  : undefined
              }
              hideIndex={dashboardType === DASHBOARD_TYPE.THEME}
              themeName={question.themeName}
              themeIcon={question.themeIcon}
              themeIconColor={question.themeIconColor}
              templates={question.templates}
              evaluators={
                (
                  (question.settings as IReviewQuestionDefaultData)
                    ?.evaluators as REVIEW_QUESTION_EVALUATORS[]
                )?.sort((a, b) => evaluatorsOrder.indexOf(a) - evaluatorsOrder.indexOf(b)) ||
                undefined
              }
            />
          );
        })}
      </QuestionsListWrap>
    </MainInfoWrap>
  );
};

export { QuestionsBlock };
