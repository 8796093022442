import React from 'react';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { IChildTransformed } from '../../../types';
import { CellContent, CellWrapper } from '../design';

interface IFirstCellQuestionProps {
  item: IChildTransformed;
  onClick: (item: IChildTransformed) => void;
}

const FirstCellQuestion = ({ item, onClick }: IFirstCellQuestionProps) => {
  return (
    <CellWrapper
      clickable={!!onClick}
      $padding={'0 20px'}
      onClick={() => {
        onClick ? onClick(item) : {};
      }}
    >
      <Tooltip tooltip={item.name} size={TOOLTIP_SIZES.BIG}>
        <CellContent>{item.name}</CellContent>
      </Tooltip>
    </CellWrapper>
  );
};

export { FirstCellQuestion };
