import React from 'react';

import { REVIEW_VERSION } from '@learned/constants';
import { Trans } from '@lingui/macro';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { GeneralSettings } from './components/GeneralSettings';
import { NotificationSettings } from './components/NotificationSettings';
import { PrivacySettings } from './components/PrivacySettings';
import { TimelineSection } from './components/TimelineSection';
import { Form, StepTimelineContainer, Title } from './design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

import type { IReviewSelfForm } from './types';
import type { IReviewTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface StepSettingsProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewSelfForm>;
  languageState: ILanguageStateReturn;
  resetGeneratedDates: () => void;
  handleReEnableAutoGenerate: () => void;
  reviewTemplates: IReviewTemplate[];
  version?: REVIEW_VERSION;
}

const StepSettings = ({
  formMethods,
  sectionState,
  languageState,
  resetGeneratedDates,
  handleReEnableAutoGenerate,
  reviewTemplates,
  version,
}: StepSettingsProps) => {
  return (
    <StepTimelineContainer>
      <Form>
        <Title>
          <Trans>Settings</Trans>
        </Title>
        <GeneralSettings
          formMethods={formMethods}
          reviewTemplates={reviewTemplates}
          version={version}
        />
        <NotificationSettings formMethods={formMethods} />
        <PrivacySettings formMethods={formMethods} />
      </Form>
      <TimelineSection
        formMethods={formMethods}
        sectionState={sectionState}
        languageState={languageState}
        resetGeneratedDates={resetGeneratedDates}
        handleReEnableAutoGenerate={handleReEnableAutoGenerate}
      />
    </StepTimelineContainer>
  );
};

export { StepSettings };
