import React from 'react';

import { TASK_TYPE } from '@learned/constants';
import { IMultiLangString, ITask, IUserReview, IUserReviewQuestion } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ToolTip from '~/components/Tooltip';

import { ChartBar } from './charBar';
import {
  ChartBarContainer,
  LabelContainer,
  ChartLabel,
  LabelGradientOne,
  LabelGradientTwo,
  LabelGradientThree,
  LabelGradientFour,
  LabelGradientFive,
  ParentContainer,
  GradientContainer,
} from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

interface RatingLabel {
  label: IMultiLangString;
}

interface OverallRatingProps {
  userReview?: IUserReview & {
    tasks: ITask[];
    employeeTasksInOtherUserReviews: ITask[];
    userReviewQuestions: IUserReviewQuestion[];
  };
  weightedAverage: Record<string, number>;
  ratingLabels: RatingLabel[] | undefined;
  isMoreThanFiveRating: boolean;
}

const OverallRating = ({
  userReview,
  weightedAverage,
  ratingLabels,
  isMoreThanFiveRating,
}: OverallRatingProps) => {
  const { i18n } = useLingui();
  const getMultilangString = useMultiLangString();

  const isSelfReviewRequired = userReview?.tasks.some(
    (task: { type: TASK_TYPE }) => task.type === TASK_TYPE.REVIEW_SELF_EVALUATE,
  );
  const isPeerReviewRequired = userReview?.tasks.some(
    (task: { type: TASK_TYPE }) => task.type === TASK_TYPE.REVIEW_PEER_EVALUATE,
  );
  const isCoachReviewRequired = userReview?.tasks.some(
    (task: { type: TASK_TYPE }) => task.type === TASK_TYPE.REVIEW_COACH_EVALUATE,
  );

  return (
    <>
      <ChartBarContainer>
        {isCoachReviewRequired && (
          <ChartBar
            text={i18n._(t`Coach`)}
            rating={weightedAverage.coach}
            isLargeBar
            labelCount={ratingLabels?.length}
          />
        )}
        {isSelfReviewRequired && (
          <ChartBar
            text={i18n._(t`Self`)}
            rating={weightedAverage.self}
            labelCount={ratingLabels?.length}
          />
        )}
        {isPeerReviewRequired && (
          <ChartBar
            text={i18n._(t`Peer`)}
            rating={weightedAverage.peer}
            labelCount={ratingLabels?.length}
          />
        )}
      </ChartBarContainer>

      <ParentContainer>
        <GradientContainer>
          <LabelGradientOne />
          <LabelGradientTwo />
          <LabelGradientThree />
          <LabelGradientFour />
          <LabelGradientFive />
        </GradientContainer>
        <LabelContainer>
          {isMoreThanFiveRating
            ? ratingLabels?.map((label, index) => (
                <ToolTip key={index} tooltip={getMultilangString(label.label)}>
                  <ChartLabel key={index} width={100 / ratingLabels.length} />
                </ToolTip>
              ))
            : ratingLabels?.map((label, index) => (
                <ChartLabel key={index} width={100 / ratingLabels.length}>
                  <Trans>{getMultilangString(label.label)}</Trans>
                </ChartLabel>
              ))}
        </LabelContainer>
      </ParentContainer>
    </>
  );
};

export { OverallRating };
