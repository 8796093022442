import React, { Component } from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Editor from '~/components/Editor';
import IconButton from '~/components/IconButton';
import PlusIcon from '~/components/Icons/Plus';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';

import EditIcon from '~/assets/ic-edit-24-px.svg';
import DeleteIcon from '~/assets/trash.svg';

import * as notesService from '~/services/notes';
import { COLORS, COLOR_PALETTE } from '~/styles';
import sanitizeHtml from '~/utils/sanitize';

const Wrapper = styled.div`
  height: 100%;
  min-height: 450px;
`;

const Notes = styled.div`
  overflow-y: auto;
  max-height: 300px;
`;

const NoteActions = styled.div`
  display: flex;
  margin-top: 8px;
`;

const NoteInputWrap = styled.div`
  align-items: center;
  position: relative;
  min-height: 128px;
  max-height: 180px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 6px;
  background-color: ${COLOR_PALETTE.WHITE};
  font-size: 14px;
  font-weight: 300;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-top: 24px;
  margin-bottom: 16px;
`;

const Note = styled.li`
  display: flex;
  margin-bottom: 30px;
`;

const NoteHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 28%;
  box-sizing: border-box;
  padding-right: 16px;
`;

const NoteText = styled.div`
  p {
    margin: 0;
  }
  border-radius: 10px;
  background-color: ${COLORS.BG_PAGE};
  padding: 8px 13px;
  box-sizing: border-box;
  font-size: 14px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const RightColumn = styled.div`
  width: 72%;
`;

const CreatedFor = styled.div`
  font-size: 12px;
`;

const NoteDate = styled.span`
  font-size: 12px;
  color: ${COLOR_PALETTE.GRAY_MIDDLE};
  margin-top: 8px;
`;

class Notebook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      editValue: null,
      editNote: null,
      loading: false,
    };
    this.renderNote = this.renderNote.bind(this);
  }

  static propTypes = {
    label: PropTypes.string,
  };

  componentDidMount = async () => {
    this.fetchUserNotes();
  };

  fetchUserNotes = async () => {
    this.setState({ loading: true });
    const notes = await notesService.getNotes();
    this.setState({ notes, loading: false });
  };

  onChangeValue = (value) => {
    this.setState({
      value,
    });
  };

  onChangeEditValue = (value) => {
    this.setState({
      editValue: value,
    });
  };

  createNote = async () => {
    const { user } = this.props;
    const { value } = this.state;

    this.setState({ loading: true });

    notesService
      .createNote(user.id, value)
      .then((newNote) => {
        this.setState((prevState) => ({
          notes: {
            ...prevState.notes,
            [newNote.id]: newNote,
          },
          value: '',
          loading: false,
        }));
      })
      .catch(() => {
        // In case of errors, don't block the UI
        this.setState({ loading: false });
      });
  };

  updateNote = async () => {
    const { editNote, editValue } = this.state;

    if (editNote.id) {
      this.setState({ loading: true });
      notesService
        .updateNote(editNote.id, editValue)
        .then((updatedNote) => {
          this.setState((prevState) => ({
            notes: {
              ...prevState.notes,
              [updatedNote.id]: {
                ...updatedNote,
                target: editNote.target,
              },
            },
            editValue: null,
            editNote: null,
            loading: false,
          }));
        })
        .catch(() => {
          // In case of errors, don't block the UI
          this.setState({ editValue: null, editNote: null, loading: false });
        });
    }
  };

  triggerEditNote = (note) => {
    const { editNote } = this.state;
    if (!editNote || editNote.id !== note.id) {
      this.setState({ editNote: note, editValue: note.value });
    } else {
      this.setState({ editNote: null });
    }
  };

  removeNote = async (note) => {
    this.setState({ loading: true });
    notesService
      .removeNote(note.id)
      .then(() => {
        this.setState((prevState) => {
          const { [note.id]: _, ...newNotes } = prevState.notes;
          return {
            notes: { ...newNotes },
            loading: false,
          };
        });
      })
      .catch(() => {
        // In case of errors, don't block the UI
        this.setState({ loading: false });
      });
  };

  renderNoteEditor(note) {
    const { value, editValue, loading } = this.state;

    return (
      <NoteInputWrap>
        <Editor
          value={note ? editValue : value}
          compact={true}
          onChange={note ? this.onChangeEditValue : this.onChangeValue}
        />
        <ShowSpinnerIfLoading loading={loading}>
          <IconButton
            onClick={note ? this.updateNote : this.createNote}
            fill
            size={32}
            style={{ position: 'absolute', bottom: '16px', right: '8px' }}
            disabled={loading || !(value || editValue)}
            colorIcon={COLOR_PALETTE.WHITE}
          >
            <PlusIcon />
          </IconButton>
        </ShowSpinnerIfLoading>
      </NoteInputWrap>
    );
  }

  renderNote(note, key) {
    const { editNote, loading } = this.state;

    const createdDate = get(note, 'meta.createdDate');
    return (
      <Note key={key}>
        <NoteHeader>
          <NoteDate>{moment(createdDate).format('DD.MM.YYYY HH:mm:ss')}</NoteDate>
          <NoteActions>
            <IconButton
              onClick={() => this.triggerEditNote(note)}
              size={24}
              color={COLORS.SUBTEXT}
              style={{ marginRight: '4px' }}
            >
              <ShowSpinnerIfLoading loading={loading}>
                <SvgIcon
                  width="12px"
                  height="12px"
                  url={EditIcon}
                  isDefaultColor
                  defaultColor={COLORS.SUBTEXT}
                />
              </ShowSpinnerIfLoading>
            </IconButton>
            <IconButton onClick={() => this.removeNote(note)} size={24} color={COLORS.SUBTEXT}>
              <ShowSpinnerIfLoading loading={loading}>
                <SvgIcon
                  width="12px"
                  height="12px"
                  url={DeleteIcon}
                  isDefaultColor
                  defaultColor={COLORS.SUBTEXT}
                />
              </ShowSpinnerIfLoading>
            </IconButton>
          </NoteActions>
        </NoteHeader>
        <RightColumn>
          {note.createdForName && (
            <CreatedFor>
              <Trans>Created For:</Trans> {note.createdForName}
            </CreatedFor>
          )}
          {editNote && editNote.id === note.id ? (
            this.renderNoteEditor(editNote)
          ) : (
            <NoteText dangerouslySetInnerHTML={{ __html: sanitizeHtml(note.value) }} />
          )}
        </RightColumn>
      </Note>
    );
  }

  render() {
    const { isFetching, notes } = this.state;

    let sortNotes = map(notes).sort((a, b) => {
      const dateA = get(a, 'meta.createdDate');
      const dateB = get(b, 'meta.createdDate');
      return moment(dateB).isAfter(dateA) ? 1 : -1;
    });

    return (
      <Wrapper>
        <ShowSpinnerIfLoading loading={isFetching}>
          {this.renderNoteEditor()}
          {!isEmpty(sortNotes) && <Notes>{map(sortNotes, this.renderNote)}</Notes>}
        </ShowSpinnerIfLoading>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default withI18n()(connect(mapStateToProps)(Notebook));
