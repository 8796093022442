import React, { useEffect } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentContainer,
  ModalContainer,
  ModalFooter,
  Title,
  ModalHeader,
  StyledDivider,
} from './design';

import { GOAL_TYPES } from '~/constants';
import { checkModuleRTFeedbacks, getUser } from '~/selectors/baseGetters';
import { getCurrentGoal } from '~/selectors/currentGoal';
import { getGoalForReview } from '~/services/goals';
import * as currentGoalActions from '~/store/currentGoal/actions';

import { Button, ButtonSize, ButtonVariant } from '../Buttons';
import Dialog from '../Dialog';
import GoalDashboardCard from '../GoalDashboardCard';
import { ICONS } from '../Icon';

interface IProps {
  goalId: string;
  reviewId?: string;
  isUpdatesAndNotes: boolean;
  onCloseModal: () => void;
  isNotSharedFeedback?: boolean;
  onGoalUpdate?: () => void;
}

const GoalDashboardModal = ({
  goalId,
  onCloseModal,
  isUpdatesAndNotes,
  isNotSharedFeedback,
  reviewId,
  onGoalUpdate,
}: IProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const goal = useSelector(getCurrentGoal);
  const isModuleRTFeedbacksEnabled = useSelector(checkModuleRTFeedbacks);
  const user = useSelector(getUser);

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      if (isMounted && goalId && reviewId) {
        const goal = await getGoalForReview(goalId, reviewId, {
          populate: ['skills', 'activities', 'goalCycles', 'teams'],
          isFillParent: true,
          isNotSharedFeedback,
        });
        // @ts-ignore
        dispatch(currentGoalActions.setCurrentGoal(goal));
      } else if (isMounted && goalId) {
        dispatch(currentGoalActions.fetchGoal(goalId));
      }
    };

    fetch();

    return () => {
      isMounted = false;
      dispatch(currentGoalActions.resetCurrentGoal());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalId]);

  const isShowRTFB =
    (goal?.type === GOAL_TYPES.PERSONAL || goal?.type === GOAL_TYPES.BUSINESS) &&
    isModuleRTFeedbacksEnabled;

  const isGoalOwner = () => {
    return goal?.owners?.includes(user.id);
  };

  return (
    <Dialog borderRadius={10} width={785}>
      <ModalContainer>
        <ModalHeader>
          <Title>
            <Trans>Goal preview</Trans>
          </Title>
          <Button
            variant={ButtonVariant.ICON}
            size={ButtonSize.MEDIUM}
            icon={ICONS.CLOSE}
            onClick={onCloseModal}
          />
        </ModalHeader>

        <StyledDivider />

        <ContentContainer>
          <GoalDashboardCard
            goal={goal}
            isOwner={isGoalOwner()}
            isShowRTFB={isShowRTFB}
            isUpdatesAndNotes={isUpdatesAndNotes}
            isOpenAsModal={true}
            onGoalUpdate={onGoalUpdate}
          />
        </ContentContainer>

        <StyledDivider />

        <ModalFooter>
          <Button
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            label={i18n._(t`Cancel`)}
            onClick={onCloseModal}
          />
        </ModalFooter>
      </ModalContainer>
    </Dialog>
  );
};

export { GoalDashboardModal };
