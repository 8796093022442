import React from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import {
  ITask,
  IUserReview,
  IUserReviewQuestion,
  JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID,
  JOIN_USER_REVIEW_BY_ID,
  POPULATE_USER_REVIEW_BY_ID,
} from '@learned/types';
import { useParams } from 'react-router-dom';

import PDFLayout from '~/layouts/PDFLayout';

import UserReviewPdfContent from './components/UserReviewPDFContent';

import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { getUserReview } from '~/services/userReviews';

const ReviewReportPdf = () => {
  const { userReviewId } = useParams<{ userReviewId: string }>();

  const [userReview, loading] = useAsyncFetch<
    | (IUserReview & {
        tasks: ITask[];
        employeeTasksInOtherUserReviews: ITask[];
        userReviewQuestions: IUserReviewQuestion[];
      })
    | undefined
  >(
    {
      fetch: async () => {
        const result = await getUserReview(userReviewId, {
          populate: [POPULATE_USER_REVIEW_BY_ID.QUESTIONS, POPULATE_USER_REVIEW_BY_ID.ATTACHMENTS],
          join: [
            JOIN_USER_REVIEW_BY_ID.TASKS,
            JOIN_USER_REVIEW_BY_ID.EMPLOYEE_TASKS_IN_OTHER_USER_REVIEWS,
            JOIN_USER_REVIEW_BY_ID.CALENDAR_EVENT,
          ],
          joinToQuestions: [
            JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID.REVIEW_RATINGS,
            JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID.REVIEW_THEMES,
            JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID.SKILLS,
            JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID.JOB_PROFILES,
          ],
        });
        return result.data[API_RETURN_FIELDS.USER_REVIEW];
      },
      initialState: undefined,
    },
    [userReviewId],
  );

  return (
    <PDFLayout loading={loading}>
      {userReview ? <UserReviewPdfContent userReview={userReview} /> : null}
    </PDFLayout>
  );
};

export default ReviewReportPdf;
