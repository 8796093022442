// Area chart
import React, { useContext, useEffect, useState } from 'react';

import { REPORT_CHART_TYPES, REPORT_TYPES, ROLES } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { Loader } from '~/components/Buttons/components/Loader';
import { AreaGraph } from '~/components/Graphs/custom/AreaGraph';
import { ELabelType, GraphDataType, ILineGraphPoint } from '~/components/Graphs/types';

import { OvertimeCtr, BodyCtr } from './OvertimeStyles';

import { useOutsideClick } from '~/hooks/useOutsideClick';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getEngagementCharts, TLucaModalData, TChartSeries } from '~/services/reports';
import { COLORS, PRIMARY_COLOR } from '~/styles';
import { toFixed } from '~/utils/math';
import { getDateForTimeFrame } from '~/utils/reports';

import NoDataPlaceholder from '../../Components/NoDataPlaceholder';
import { EOptions, TFacetOption } from '../../types';
import { EngagementReportContext } from '../EngagementContext';
import { HeaderIncluded } from '../Header';
import { InformationModal } from '../InformationModal';
import { Luca } from '../Luca';

type TProps = {
  optionChangeHandler: (key: EOptions, value?: string | TFacetOption[] | null) => void;
};

const createTooltipTitle = (
  i18n: I18n,
  reportType: REPORT_TYPES,
  isPersonal: boolean | undefined,
) => {
  if (reportType === REPORT_TYPES.ENGAGEMENT && !isPersonal) {
    return `${i18n._(t`Average engagement - Over time`)}`;
  } else if (reportType === REPORT_TYPES.PERFORMANCE && !isPersonal) {
    return `${i18n._(t`Average performance - Over time`)}`;
  } else if (reportType === REPORT_TYPES.PERFORMANCE && isPersonal) {
    return `${i18n._(t`Your performance - Over time`)}`;
  } else {
    // personal engagement report
    return `${i18n._(t`Your engagement - Over time`)}`;
  }
};

export const processGraphData = (
  series: TChartSeries[],
  key: keyof TChartSeries,
): GraphDataType[] => {
  return series.map((item) => ({
    key: item.month,
    value: toFixed(item[key] || 0, 2),
    deviation: item.deviation || 0,
  }));
};

export const getLabelText = (reportType: REPORT_TYPES | undefined, i18n: I18n) => {
  if (reportType === REPORT_TYPES.ENGAGEMENT) {
    return `${i18n._(t`Average engagement`)}`;
  } else if (reportType === REPORT_TYPES.PERFORMANCE) {
    return `${i18n._(t`Coach review`)}`;
  }
  return `${i18n._(t`Average`)}`;
};

function Overtime({ optionChangeHandler }: TProps): JSX.Element {
  const { i18n } = useLingui();
  const { viewAs, reportType, filters, options, shouldShowLuca, isWaiting, tabConfiguration } =
    useContext(EngagementReportContext);

  const isPersonal = tabConfiguration?.isPersonal;

  const [companyAverage, setCompanyAverage] = useState<GraphDataType[]>([]);
  const [benchmark, setBenchmark] = useState<GraphDataType[]>([]);
  const [teamAverage, setTeamAverage] = useState<GraphDataType[]>([]);
  const [selfReviewAverage, setSelfReviewAverage] = useState<GraphDataType[]>([]);
  const [peerReviewAverage, setPeerReviewAverage] = useState<GraphDataType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentCompany = useSelector(getCurrentCompany);
  const [ratingLabelsCount, setRatingLabelsCount] = useState<number | undefined>(undefined);
  const companyColor = currentCompany.color || PRIMARY_COLOR;
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [informationModalData, setInformationModalData] = useState<TLucaModalData | null>(null);
  const [showNoResultPlaceholder, setShowNoResultPlaceholder] = useState(false);

  const onItemClick = (data: { key: string; value: number }) => {
    setInformationModalData({
      primaryDimensionName:
        reportType === REPORT_TYPES.ENGAGEMENT
          ? `${i18n._(t`Average engagement`)}`
          : `${i18n._(t`Average performance`)}`,
      value: data.value,
      filters: {
        measureValue: data.key,
      },
    });
    setShowInformationModal(true);
  };

  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowInformationModal(false);
  });

  const getSelectedKeys = (selectedOptions: TFacetOption[]) =>
    selectedOptions.map((item) => item.key);

  const getData = async () => {
    try {
      setIsLoading(true);
      const payload = {
        viewAs,
        reportType,
        chartType: REPORT_CHART_TYPES.BY_MONTH,
        filters: {
          themes: getSelectedKeys(filters.themesOptionSelected),
          teams: getSelectedKeys(filters.teamsOptionSelected),
          surveys: getSelectedKeys(filters.surveysOptionSelected),
          jobs: getSelectedKeys(filters.jobsSelected),
          jobGroups: getSelectedKeys(filters.jobsGroupsSelected),
          genders: getSelectedKeys(filters.gendersSelected),
          ageGroups: getSelectedKeys(filters.ageGroupSelected),
          educationLevels: getSelectedKeys(filters.educationLevelsSelected),
          reviews: getSelectedKeys(filters.reviewsOptionSelected),
        },
        options: {
          includeCompanyAverage: options.includeCompanyAverage,
          includeBenchmark: options.includeBenchmark,
          includePeerReviewAverage: options.includePeerReviewAverage,
          includeSelfReviewAverage: options.includeSelfReviewAverage,
          ...(viewAs === ROLES.USER && { includeTeamAverage: options.includeTeamAverage }),
        },
        dateRange: getDateForTimeFrame(filters.monthSelected),
      };
      const chartData = await getEngagementCharts(payload);

      setShowNoResultPlaceholder(Boolean(chartData.data.total === 0));

      if (chartData?.data?.total) {
        const series = chartData?.data?.averageEngagement?.series || [];

        setRatingLabelsCount(chartData.data.averageEngagement.rlc);
        setCompanyAverage(processGraphData(series, 'value'));
        setBenchmark(processGraphData(series, 'benchmark'));
        setTeamAverage(processGraphData(series, 'team'));
        setSelfReviewAverage(processGraphData(series, 'self'));
        setPeerReviewAverage(processGraphData(series, 'peer'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const timeData: ILineGraphPoint[] = [
    {
      data: companyAverage || [],
      colors: [COLORS.CAREER, COLORS.WHITE],
      label: getLabelText(reportType, i18n),
      type: ELabelType.FILL,
      key: 'primary',
    },
    ...(options.includeCompanyAverage
      ? [
          {
            data: companyAverage,
            colors: [COLORS.CONFIRMATION_MODAL_SUCCESS],
            label: `${i18n._(t`Company`)}`,
            type: ELabelType.BORDER,
            key: 'company',
          },
        ]
      : []),
    ...(options.includeTeamAverage
      ? [
          {
            data: teamAverage || [],
            colors: [COLORS.PERFORMANCE],
            label: `${i18n._(t`Team`)}`,
            type: ELabelType.BORDER,
            key: 'team',
          },
        ]
      : []),
    ...(options.includePeerReviewAverage
      ? [
          {
            data: peerReviewAverage || [],
            colors: [COLORS.PEER_REVIEW_LEGEND],
            label: `${i18n._(t`Peer review`)}`,
            type: ELabelType.BORDER,
            key: 'peer',
          },
        ]
      : []),
    ...(options.includeSelfReviewAverage
      ? [
          {
            data: selfReviewAverage || [],
            colors: [COLORS.REVIEW_SELF],
            label: `${i18n._(t`Self review`)}`,
            type: ELabelType.BORDER,
            key: 'self',
          },
        ]
      : []),
    ...(options.includeBenchmark
      ? [
          {
            data: benchmark,
            colors: [COLORS.SUBTEXT],
            label: `${i18n._(t`Benchmark`)}`,
            type: ELabelType.BORDER,
            key: 'benchmark',
          },
        ]
      : []),
  ];

  useEffect(() => {
    optionChangeHandler(EOptions.reset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isWaiting) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isWaiting,
    options.includeBenchmark,
    options.includeCompanyAverage,
    options.includePeerReviewAverage,
    options.includeSelfReviewAverage,
    options.includeTeamAverage,
    filters.ageGroupSelected,
    filters.educationLevelsSelected,
    filters.gendersSelected,
    filters.jobsGroupsSelected,
    filters.jobsSelected,
    filters.monthSelected,
    filters.surveysOptionSelected,
    filters.teamsOptionSelected,
    filters.themesOptionSelected,
    filters.reviewsOptionSelected,
    filters.skillOptionsSelected,
    filters.skillCategoryOptionsSelected,
    filters.performanceCategoryOptionsSelected,
    filters.memberOptionsSelected,
  ]);

  const isLoadingState = isWaiting || isLoading;

  if (showNoResultPlaceholder) {
    return (
      <HeaderIncluded isLoading={isLoading}>
        <OvertimeCtr>
          <BodyCtr>
            <NoDataPlaceholder />
          </BodyCtr>
        </OvertimeCtr>
      </HeaderIncluded>
    );
  }

  return (
    <HeaderIncluded isLoading={isLoading}>
      <OvertimeCtr>
        {isLoadingState ? (
          <BodyCtr>
            <Loader />
          </BodyCtr>
        ) : (
          <>
            <AreaGraph
              timeData={timeData}
              height={478}
              stepSize={20}
              colorRatio={1}
              tooltipTitle={createTooltipTitle(i18n, reportType, isPersonal)}
              bgColor={companyColor}
              onPointClick={onItemClick}
              ratingLabelsCount={ratingLabelsCount}
            />
            {shouldShowLuca && <Luca />}
          </>
        )}
      </OvertimeCtr>
      {showInformationModal && informationModalData && (
        <InformationModal
          data={informationModalData}
          onClose={setShowInformationModal}
          baseRef={baseRef}
        />
      )}
    </HeaderIncluded>
  );
}

export default Overtime;
