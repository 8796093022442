import React from 'react';

import { Summaries } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Summaries';

import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { getReviewSummaries } from '~/services/reviewSummaries';

const SummariesPDFView = ({ userReviewId }: { userReviewId: string }) => {
  const [summaries] = useAsyncFetch({
    fetch: async () => {
      const { data } = await getReviewSummaries({ userReviewId });
      return data.reviewSummaries;
    },
    initialState: [],
  });

  return <Summaries reviewSummaries={summaries} onOpenMySummary={() => {}} isPDFView />;
};

export default SummariesPDFView;
