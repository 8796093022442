import React, { useEffect, useMemo, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, JOB_SORTING } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { AxiosResponse } from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import { ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TableList } from '~/components/TableList';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { useJobLevelGroups } from '~/pages/JobProfilesOverview/JobForm/hooks/useJobLevelGroups';

import { createJobColumns } from './AllJobs.columns';
import { Wrapper } from './JobProfile.design';

import { JOB_PROFILE_STATUSES } from '~/constants';
import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import {
  getJobLevelGroups,
  getJobFamilies,
  getJobProfiles,
  getCareerPlans,
} from '~/selectors/baseGetters';
import getAllUsers from '~/selectors/getAllUsers';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getJobFamilies as getJobFamilyService } from '~/services/jobFamilies';
import { getJobLevelGroups as getJobLevelGroupService } from '~/services/jobLevelGroups';
import {
  downloadJobProfilesCSV,
  getJobProfiles as getJobProfileService,
  deleteJobProfiles,
} from '~/services/jobProfiles';
import { getCareerPlans as getCareerPlansAction } from '~/store/careerPlans/actions';

import type { TJobProfileExtended, TCareerGroup, TIntegrationSettings } from './types';
import type { ICareerPlan, IJobFamily, IJobLevelGroup, IJobProfile, IUser } from '@learned/types';

type TProps = {
  settings: { [key: string]: TIntegrationSettings };
};
const JobProfiles = ({ settings }: TProps) => {
  const getMultiLangString = useMultiLangString();
  const dispatch = useDispatch();

  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const { pagination, changePagination } = usePagination(25);

  const [totalCount, setTotalCount] = useState<number>();
  const [search, setSearch] = useState('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [jobData, setJobData] = useState<TJobProfileExtended[]>([]);
  const [paginatedJobData, setPaginatedJobData] = useState<TJobProfileExtended[]>([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState(JOB_SORTING.NAME_A_Z);
  const [initiated, setInitiated] = useState(false);

  const debouncedSearch = useDebounce(search, 500);
  const jobProfiles = useSelector(getJobProfiles) as IJobProfile[];
  const jobFamilies = useSelector(getJobFamilies) as IJobFamily[];
  const jobLevelGroups = useSelector(getJobLevelGroups) as IJobLevelGroup[];
  const { jobLevelGroupsPopulated } = useJobLevelGroups();
  const careerPlans = useSelector(getCareerPlans) as { [key: string]: ICareerPlan };
  const users = useSelector(getAllUsers) as { [key: string]: IUser };
  const company = useSelector(getCurrentCompany);

  const [filteredJobProfiles, setFilteredJobProfiles] = useState(jobProfiles);
  const [filteredJobFamilies, setFilteredJobFamilies] = useState(jobFamilies);
  const [filteredJobLevelGroups, setFilteredJobLevelGroups] = useState(jobLevelGroups);

  const getMembers = (members: string, groupedCareerPlans: TCareerGroup) => {
    try {
      const filtered = groupedCareerPlans[members] || [];
      const filteredWithAvatar = filtered.map((career) => ({
        id: career?.createdFor,
        avatarUrl: users[career?.createdFor]?.avatarUrl || '',
      }));
      return filteredWithAvatar;
    } catch {
      return [];
    }
  };

  const getPaginated = (array: TJobProfileExtended[]) => {
    const pageSize = pagination.limit;
    const pageNumber = pagination.index;
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  const getSorted = (data: TJobProfileExtended[], sorted: JOB_SORTING) => {
    const compareLocale = (a?: string, b?: string, isReversed = false) => {
      if (a === undefined || a.trim().length === 0) {
        return 1;
      }
      if (b === undefined || b.trim().length === 0) {
        return -1;
      }
      const lowerA = a.toLowerCase();
      const lowerB = b.toLowerCase();
      return isReversed ? lowerB.localeCompare(lowerA) : lowerA.localeCompare(lowerB);
    };

    const sortedData = data.sort((a, b) => {
      switch (sorted) {
        case JOB_SORTING.NAME_A_Z:
          return compareLocale(getMultiLangString(a.name), getMultiLangString(b.name));
        case JOB_SORTING.NAME_Z_A:
          return compareLocale(getMultiLangString(a.name), getMultiLangString(b.name), true);
        case JOB_SORTING.JOB_FAMILY_A_Z:
          return compareLocale(a.jobFamilyName, b.jobFamilyName);
        case JOB_SORTING.JOB_FAMILY_Z_A:
          return compareLocale(a.jobFamilyName, b.jobFamilyName, true);
        case JOB_SORTING.LEVEL_A_Z:
          return compareLocale(a.jobGroupNameWithLevel, b.jobGroupNameWithLevel);
        case JOB_SORTING.LEVEL_Z_A:
          return compareLocale(a.jobGroupNameWithLevel, b.jobGroupNameWithLevel, true);
        case JOB_SORTING.CREATED_BY_A_Z:
          return compareLocale(a.createdByName, b.createdByName);
        case JOB_SORTING.CREATED_BY_Z_A:
          return compareLocale(a.createdByName, b.createdByName, true);
        case JOB_SORTING.MEMBERS_HIGH_TO_LOW:
          if (a?.members && b?.members) {
            return b.members.length - a.members.length;
          }
          return 0;
        case JOB_SORTING.MEMBERS_LOW_TO_HIGH:
          if (a?.members && b?.members) {
            return a.members.length - b.members.length;
          }
          return 0;
        default:
          return 0;
      }
    });
    return sortedData;
  };

  const initiateJobProfileData = async () => {
    setLoading(true);
    const jobProfilesIds = Object.values(filteredJobProfiles || {}).map((profile) => profile.id);
    const filteredCareerPlans = Object.values(careerPlans || {})
      .map((career) => ({
        jobProfile: career?.jobProfile,
        id: career?.id,
        createdFor: career?.createdFor,
      }))
      .filter((career) => jobProfilesIds.includes(career.jobProfile));

    const groupedCareerPlans = filteredCareerPlans.reduce((groups: TCareerGroup, career) => {
      const key = career.jobProfile;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(career);
      return groups;
    }, {});
    const integrationValues = Object.values(settings);

    const jobDataObj = Object.values(filteredJobProfiles || {}).map((job) => {
      const jobLevelGroupName = (jobLevelGroupsPopulated || []).find(
        (group) => group.groupId === job?.jobLevelGroup?.id,
      );

      const integrationSoftware =
        job.externalSource && integrationValues.length
          ? integrationValues.find((x) => x.id === job.externalSource)
          : null;

      return {
        ...job,
        jobGroupNameWithLevel: jobLevelGroupName
          ? `${getMultiLangString(jobLevelGroupName.name || '')} (${
              (jobLevelGroupName.realLevel ?? 0) + 1 + (job?.jobLevelGroup?.level || 0)
            })`
          : '',
        jobFamilyName: getMultiLangString(
          (filteredJobFamilies || []).find((family) => family.id === job.jobFamily)?.name || '',
        ),
        members: getMembers(job.id, groupedCareerPlans),
        createdByName: integrationSoftware?.name ? integrationSoftware.name : i18n._(t`Manual`),
      };
    });
    const sortedData = getSorted(jobDataObj, sortBy);
    setJobData(sortedData);

    const paginatedData = getPaginated(sortedData);

    // Set missing translation text in name after sort
    const locale = company?.primaryLang?.locale;
    paginatedData.forEach((jp) => {
      if (!jp.name[locale]) {
        jp.name[locale] = `"${i18n._(t`Missing translation`)}" - ${getMultiLangString(jp.name)}`;
      }
    });

    setPaginatedJobData(paginatedData);
    setTotalCount(filteredJobProfiles.length);
    setLoading(false);
  };

  const exportJobProfiles = async () => {
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });

    await downloadJobProfilesCSV({ search, status: JOB_PROFILE_STATUSES.ACTIVE.key });
  };

  const refreshJobProfileData = async (search = '') => {
    try {
      setLoading(true);
      if (!initiated) {
        dispatch(getCareerPlansAction());
      }

      const [jobProfiles, jobLevelGroups, jobFamilies] = await Promise.all([
        getJobProfileService(
          { status: JOB_PROFILE_STATUSES.ACTIVE.key, ...(search.trim().length > 0 && { search }) },
          {
            projection: {
              name: 1,
              jobFamily: 1,
              jobLevelGroup: 1,
              meta: 1,
              status: 1,
              externalId: 1,
              externalSource: 1,
              company: 1,
              createdBy: 1,
            },
          },
        ) as Promise<{ [key: string]: IJobProfile }[]>,
        getJobLevelGroupService() as Promise<
          AxiosResponse<{ jobLevelGroups: { [key: string]: IJobLevelGroup } }>
        >,
        getJobFamilyService() as Promise<
          AxiosResponse<{ jobFamilies: { [key: string]: IJobFamily } }>
        >,
      ]);
      const jobProfileData = Object.values(jobProfiles || {}) as unknown as IJobProfile[];
      setFilteredJobProfiles(jobProfileData);
      const jobLevelGroupsData = Object.values(jobLevelGroups?.data?.jobLevelGroups || {});
      setFilteredJobLevelGroups(jobLevelGroupsData);
      const jobFamiliesData = Object.values(jobFamilies?.data?.jobFamilies || {});
      setFilteredJobFamilies(jobFamiliesData);
      setLoading(false);
    } catch {
      setLoading(false);
      addToast({
        title: i18n._(t`Error while fetching data`),
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  const isAllSelected = paginatedJobData.every((job) => {
    return selectedItems.includes(job.id);
  });

  const onSelectAll = () => {
    const itemsToSelect = isAllSelected ? [] : paginatedJobData.map((job) => job.id);
    setSelectedItems(itemsToSelect);
  };

  const handleSearch = (item: string) => {
    setSearch(item);
  };

  const refreshData = async () => {
    setJobData([]);
    setPaginatedJobData([]);
    setSelectedItems([]);
    await refreshJobProfileData();
  };

  const onDeleteItem = async (id: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete?`),
      description: i18n._(t`Are you sure you want to delete the job? This cannot be undone.`),
    });
    if (isConfirmed) {
      try {
        setLoading(true);
        await deleteJobProfiles([id]);
        addToast({
          title: i18n._(t`Succesfully deleted`),
          type: TOAST_TYPES.INFO,
        });
      } catch (error) {
        addToast({
          title: i18n._(t`Oops... Something went wrong`),
          subtitle: i18n._(t`Try again later!`),
          type: TOAST_TYPES.ERROR,
        });
        setLoading(false);
      }
      await refreshData();
    }
  };

  const createMenuItems = (item: IJobProfile) => {
    return [
      {
        label: i18n._(t`Edit`),
        action: () => {
          routes.JOB_UPDATE.go(
            {},
            {
              roleId: item.id,
              isBackPath: true,
            },
          );
        },
        icon: ICONS.EDIT_PENCIL,
      },
      {
        label: i18n._(t`Delete`),
        action: () => onDeleteItem(item.id),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ];
  };

  const multiSelect = {
    checkedCount: selectedItems.length,
    onCheckAll: onSelectAll,
    onSelectItem: (job: TJobProfileExtended) => {
      if (selectedItems.includes(job.id)) {
        setSelectedItems((prevItems) => prevItems.filter((id) => id !== job.id));
      } else {
        setSelectedItems((prevItems) => [...prevItems, job.id]);
      }
    },
    isItemChecked: (job: TJobProfileExtended) => selectedItems.includes(job.id),
    isAllChecked: isAllSelected,
    onDelete: async () => {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete?`),
        description: i18n._(
          t`Are you sure you want to delete the selected job(s)? This cannot be undone.`,
        ),
      });
      if (isConfirmed) {
        try {
          setLoading(true);
          await deleteJobProfiles(selectedItems);
          addToast({
            title: i18n._(t`Successfully deleted ${selectedItems.length} jobs`),
            type: TOAST_TYPES.INFO,
          });
        } catch (error) {
          addToast({
            title: i18n._(t`Oops... Something went wrong`),
            subtitle: i18n._(t`Try again later!`),
            type: TOAST_TYPES.ERROR,
          });
          setLoading(false);
        }
        await refreshData();
      }
    },
  };

  useEffect(() => {
    const sortedData = getSorted(jobData, sortBy);
    const paginatedData = getPaginated(sortedData);

    // Set missing translation text in name after sort
    const locale = company?.primaryLang?.locale;
    paginatedData.forEach((jp) => {
      if (!jp.name[locale]) {
        jp.name[locale] = `"${i18n._(t`Missing translation`)}" - ${getMultiLangString(jp.name)}`;
      }
    });

    setPaginatedJobData(paginatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  useEffect(() => {
    setInitiated(true);
  }, []);

  useEffect(() => {
    if (filteredJobProfiles && jobLevelGroupsPopulated) {
      initiateJobProfileData();
    } else if (company.id) {
      refreshJobProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    jobLevelGroupsPopulated,
    filteredJobLevelGroups,
    filteredJobFamilies,
    filteredJobProfiles,
    company.id,
    careerPlans,
    users,
    pagination.limit,
    pagination.skip,
  ]);

  useEffect(() => {
    if (debouncedSearch.trim().length > 0) {
      refreshJobProfileData(debouncedSearch);
    } else if (initiated) {
      refreshJobProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const onRowClick = (item: TJobProfileExtended) => {
    routes.JOB_PROFILE.go(
      {},
      {
        roleId: item.id,
        isBackPath: true,
      },
    );
  };

  const JOB_COLUMNS = useMemo(() => createJobColumns(getMultiLangString), [getMultiLangString]);
  return (
    <Wrapper>
      <TableList
        isLoading={loading}
        data={paginatedJobData}
        columns={JOB_COLUMNS}
        onRowClick={onRowClick}
        filtersProps={{ isFiltered: true, filters: { search, setSearch: handleSearch } }}
        paginationProps={{
          pagination,
          changePagination,
          totalCount,
          paginationItemLabel: i18n._(t`Jobs`),
        }}
        sortProps={{ sortBy, setSortBy }}
        multiSelectProps={{
          isMultiSelectVisible: true,
          isSelectedCountVisible: true,
          multiSelect,
          isSelectAllVisible: true,
        }}
        menuProps={{ isMenuVisible: true, createMenuItems }}
        actionButton={{
          label: i18n._(t`Export`),
          onClick: exportJobProfiles,
          variant: ButtonVariant.SECONDARY,
          icon: ICONS.EXPORT,
        }}
      />
    </Wrapper>
  );
};

export default JobProfiles;
