import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { TH } from './columns';

import { COLORS } from '~/styles';

const Container = styled.div<{ height: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height}px;
  gap: 10px;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
`;

const Label = styled.div`
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.PLACEHOLDERS};
`;

const EmptyGoalsPlaceholderWrapper = styled.div`
  @media print {
    page-break-inside: avoid;
  }
`;

export const EmptyGoalsPlaceholder = () => {
  return (
    <EmptyGoalsPlaceholderWrapper>
      <TH>
        <Trans>NAME</Trans>
      </TH>
      <Container height={200}>
        <Label>
          <Trans>No goals planned yet</Trans>
        </Label>
      </Container>
    </EmptyGoalsPlaceholderWrapper>
  );
};
