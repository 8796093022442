/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Tooltip, { TOOLTIP_PLACEMENTS, TOOLTIP_SIZES } from '~/components/Tooltip';

import { Item, ItemWrapper } from '../design';

interface ItemBlockProps {
  title: string;
  icon: ICONS;
  isMenuOpened: boolean;
  setIsMenuOpened: Dispatch<SetStateAction<boolean>>;
  content: ReactNode;
  isContentVisibleByDefault?: boolean;
}

const ItemBlock = ({
  title,
  icon,
  content,
  isMenuOpened,
  setIsMenuOpened,
  isContentVisibleByDefault,
}: ItemBlockProps) => {
  const [isContentVisible, setIsContentVisible] = useState(!!isContentVisibleByDefault);

  const iconContent = (
    <div className="icon">
      <Icon icon={icon} size={ICON_SIZES.SMALL} />
    </div>
  );

  const itemContent = (
    <>
      <div className="left">
        {iconContent}
        <span className="title">{title}</span>
      </div>
      <div className="right">
        <Icon icon={ICONS.BACK} size={ICON_SIZES.SMALL} />
      </div>
    </>
  );

  const onClickItem = () => {
    setIsContentVisible((prevState) => !prevState);
    setIsMenuOpened(true);
  };

  return (
    <ItemWrapper>
      <Item
        key={title}
        isMenuOpened={isMenuOpened}
        isContentVisible={isContentVisible}
        onClick={onClickItem}
      >
        {isMenuOpened ? (
          itemContent
        ) : (
          <Tooltip
            placement={TOOLTIP_PLACEMENTS.RIGHT}
            tooltip={title}
            size={TOOLTIP_SIZES.DEFAULT_TL}
            arrow={false}
          >
            {iconContent}
          </Tooltip>
        )}
      </Item>
      {!!isMenuOpened && !!isContentVisible && content}
    </ItemWrapper>
  );
};

export { ItemBlock };
