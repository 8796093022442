import React, { useState } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  REVIEW_RATING_STATUS,
  TASK_STATUS,
  TASK_TYPE,
} from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import type { IPopulatedUserReview } from '~/pages/ReviewGiveFeedback/types';

import {
  Actions,
  BigButton,
  BigButtonSubtitle,
  BigButtonTitle,
  Card,
  CompletedImage,
  OutroContainer,
  TextWrapper,
  Title,
} from './ReviewOutro.design';

import CompletedStatePlaceholder from '~/assets/3.0/placeholders/completed-state-round.png';

import type { ITask, IUser } from '@learned/types';

function ReviewOutro({
  isSubmitting,
  onPublish,
  onSave,
  onSaveCalibrate,
  taskType: taskType,
  taskStatus,
  userReview,
  isExternalPeer,
  userFromObject,
}: {
  isSubmitting: boolean;
  onPublish?: () => void;
  onSave?: () => void;
  onSaveCalibrate?: () => void;
  isExternalPeer?: boolean;
  taskType: TASK_TYPE;
  taskStatus: ITask['status'];
  userReview: IPopulatedUserReview;
  userFromObject?: IUser;
}) {
  const { i18n } = useLingui();
  const [status, setStatus] = useState(REVIEW_RATING_STATUS.PUBLISHED);
  const isCalibrateEnabled =
    taskType === TASK_TYPE.REVIEW_COACH_EVALUATE && userReview.settings.isCalibrate;

  return (
    <OutroContainer marginTop={isExternalPeer ? 180 : 71}>
      <Title marginBottom={isExternalPeer ? 10 : 34}>
        {(taskType === TASK_TYPE.REVIEW_SELF_EVALUATE ||
          taskType === TASK_TYPE.REVIEW_PEER_EVALUATE) && (
          <Trans>You have reached the end of the review!</Trans>
        )}
        {taskType === TASK_TYPE.REVIEW_COACH_EVALUATE && (
          <Trans>You have reached the end of the coach review!</Trans>
        )}
      </Title>
      {isExternalPeer ? (
        <TextWrapper>
          <span>
            <Trans>
              {`Thank you for taking the time to provide ${userFromObject?.firstName} ${userFromObject?.lastName} with input for the upcoming review. Press ‘Share’ to share your answers with ${userFromObject?.firstName} ${userFromObject?.lastName}.`}
            </Trans>
          </span>
          <CompletedImage>
            <img alt={i18n._(t`Review completed`)} src={CompletedStatePlaceholder} />
            <Button
              onClick={onPublish}
              isLoading={isSubmitting}
              variant={ButtonVariant.PRIMARY}
              label={<Trans>Share</Trans>}
            />
          </CompletedImage>
        </TextWrapper>
      ) : (
        <Card>
          <BigButton
            isSelected={status === REVIEW_RATING_STATUS.PUBLISHED}
            onClick={() => setStatus(REVIEW_RATING_STATUS.PUBLISHED)}
          >
            <BigButtonTitle>
              <Icon size={ICON_SIZES.LARGE} icon={ICONS.SHOW} />
              <Trans>Share input</Trans>
            </BigButtonTitle>
            <BigButtonSubtitle>
              <Trans>Everyone with access to this review can view your input.</Trans>
            </BigButtonSubtitle>
          </BigButton>
          {isCalibrateEnabled && (
            <BigButton
              isSelected={status === REVIEW_RATING_STATUS.CALIBRATE}
              onClick={() => setStatus(REVIEW_RATING_STATUS.CALIBRATE)}
            >
              <BigButtonTitle>
                <Icon size={ICON_SIZES.LARGE} icon={ICONS.SHOW_HALF} />
                <Trans>Calibrate input</Trans>
              </BigButtonTitle>
              <BigButtonSubtitle>
                <Trans>Only coaches who provide input for this review can view your input.</Trans>
              </BigButtonSubtitle>
            </BigButton>
          )}
          <BigButton
            isSelected={status === REVIEW_RATING_STATUS.TODO}
            onClick={() => setStatus(REVIEW_RATING_STATUS.TODO)}
          >
            <BigButtonTitle>
              <Icon size={ICON_SIZES.LARGE} icon={ICONS.HIDE} />
              <Trans>Do not share</Trans>
            </BigButtonTitle>
            <BigButtonSubtitle>
              <Trans>Save your input as draft. You can share your answers at a later stage.</Trans>
            </BigButtonSubtitle>
          </BigButton>
          <Actions>
            {status === REVIEW_RATING_STATUS.PUBLISHED && (
              <Button
                onClick={onPublish}
                isLoading={isSubmitting}
                variant={ButtonVariant.PRIMARY}
                label={<Trans>Complete</Trans>}
              />
            )}
            {status === REVIEW_RATING_STATUS.TODO && (
              <Button
                isLoading={isSubmitting}
                onClick={async () => {
                  if (
                    [TASK_STATUS.COMPLETED, TASK_STATUS.CALIBRATE].includes(taskStatus) &&
                    (await confirm({
                      title: i18n._(t`Reset to draft?`),
                      description: i18n._(
                        t`Are you sure you want to reset your input to draft? Your input will no longer be visible for others.`,
                      ),
                      type: CONFIRMATION_MODAL_TYPE.WARNING,
                    }))
                  ) {
                    onSave?.();
                  } else if (![TASK_STATUS.COMPLETED, TASK_STATUS.CALIBRATE].includes(taskStatus)) {
                    onSave?.();
                  }
                }}
                variant={ButtonVariant.SECONDARY}
                label={<Trans>Save as draft</Trans>}
              />
            )}
            {status === REVIEW_RATING_STATUS.CALIBRATE && (
              <Button
                isLoading={isSubmitting}
                onClick={async () => {
                  if (
                    taskStatus === TASK_STATUS.COMPLETED &&
                    (await confirm({
                      title: i18n._(t`Reset to calibrate?`),
                      description: i18n._(
                        t`Are you sure you want to reset your input to calibrate? Your input will no longer be visible for the employee.`,
                      ),
                      type: CONFIRMATION_MODAL_TYPE.WARNING,
                    }))
                  ) {
                    onSaveCalibrate?.();
                  } else if (taskStatus !== TASK_STATUS.COMPLETED) {
                    onSaveCalibrate?.();
                  }
                }}
                variant={ButtonVariant.SECONDARY}
                label={<Trans>Start calibration</Trans>}
              />
            )}
          </Actions>
        </Card>
      )}
    </OutroContainer>
  );
}

export { ReviewOutro };
