import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Separator = styled.div`
  height: 1px;
  border-bottom: 1px solid ${COLORS.BORDERS};
  padding-top: 24px;
`;

export const EmployeeOverviewModalTitleWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  flex-direction: column;

  span.title-text {
    line-height: 1;
    letter-spacing: -0.29px;
    font-size: 26px;
    align-self: flex-start;
  }

  button {
    max-width: 150px;
  }
`;

export const ReviewStatusWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  margin-top: -20px;

  div {
    font-size: 14px;
    line-height: 1.86;
    letter-spacing: normal;
    color: ${COLORS.ICONS_PRIMARY};
  }

  div:nth-child(2) {
    background-color: transparent;
  }

  label {
    margin-left: 170px;
  }
`;

export const ListRow = styled.div<{ $isHovering?: boolean }>`
  display: flex;
  min-height: 32px;
  justify-content: space-between;
  margin-top: 5px;
  padding: 0 0 0 23px;
  border-radius: 6px;
  ${(props) =>
    props.$isHovering &&
    css`
      background-color: ${COLORS.HOVER};
    `}
`;

const RIGHT_BLOCK_WIDTH = '150px';
const MARGIN_LEFT = '90px';
export const RowRightBlock = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(100% - ${RIGHT_BLOCK_WIDTH} - ${MARGIN_LEFT});
`;

export const RowLeftBlock = styled.div`
  display: flex;
  align-items: center;
  width: ${RIGHT_BLOCK_WIDTH};
  margin-left: ${MARGIN_LEFT};
`;

export const EvaluatorLabel = styled.div`
  margin-left: 5px;
  font-size: 12px;
  color: ${COLORS.PLACEHOLDERS};
`;

export const AvatarWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 12px;
`;
