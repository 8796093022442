import React, { useEffect, useState } from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import { ICareerPlan, ILikePopulated, IMultiLangString } from '@learned/types';
import isEmpty from 'lodash/isEmpty';

import { JobsModal } from '~/pages/UserDashboard/modals/JobsModal';
import { JobProfileBasic } from '~/pages/UserDashboard/modals/JobsModal/types';

import { JOB_STATUS_LABELS } from '~/constants/jobStatusProvider';
import routes from '~/constants/routes';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getCareerPlansFull } from '~/services/careerPlans';

import { SIDEBAR_ITEM_TYPES } from '../../constants';
import { SidebarRow } from '../SidebarRow';

// Function orders first taking into consideration if it is primary and then the status
const sortJobsByStatus = (jobs: JobProfileBasic[]): JobProfileBasic[] => {
  const order = {
    [JOB_STATUS_LABELS.ACTIVE]: 1,
    [JOB_STATUS_LABELS.AMBITION]: 2,
    [JOB_STATUS_LABELS.PREVIOUS]: 3,
  };

  return jobs.sort((a, b) => {
    const aPrimary = a.primary ? 1 : 0;
    const bPrimary = b.primary ? 1 : 0;

    if (aPrimary !== bPrimary) {
      return bPrimary - aPrimary;
    }

    return order[a.status] - order[b.status];
  });
};

const CareerCard = () => {
  const [loading, setLoading] = useState(false);

  const [jobs, setJobs] = useState<JobProfileBasic[]>([]);

  const [showJobsModal, setShowJobsModal] = useState(false);

  const getMultiLangString = useMultiLangString();

  useEffect(() => {
    setLoading(true);
    setJobs([]);
    const fetchData = async () => {
      const {
        data = {
          careerPlans: {
            careerPaths: [],
            recommendedPaths: [],
            verticalPaths: [],
          },
          likes: {},
        },
      }: {
        data: {
          careerPlans: {
            careerPaths: ICareerPlan[];
            recommendedPaths: ICareerPlan[];
            verticalPaths: ICareerPlan[];
          };
          likes: Record<string, ILikePopulated>;
        };
      } = await getCareerPlansFull();

      const newJobs: JobProfileBasic[] = [];
      data.careerPlans.careerPaths.map((item) => {
        if (item.status === CAREER_PLAN_STATUSES.CURRENT) {
          newJobs.push({
            id: item.jobProfile,
            name: item.name as unknown as IMultiLangString,
            status: JOB_STATUS_LABELS.ACTIVE,
            primary: item.primary,
          });
        }
        if (item.status === CAREER_PLAN_STATUSES.INACTIVE) {
          newJobs.push({
            id: item.jobProfile,
            name: item.name as unknown as IMultiLangString,
            status: JOB_STATUS_LABELS.PREVIOUS,
            primary: false,
          });
        }
      });

      Object.values(data.likes).map((item) => {
        const jobObj = newJobs.find((job) => job.id === item.jobProfileId);
        if (isEmpty(jobObj)) {
          newJobs.push({
            id: item.jobProfileId,
            name: item.jobProfileName,
            status: JOB_STATUS_LABELS.AMBITION,
            primary: false,
          });
        }
      });

      setJobs(sortJobsByStatus(newJobs));

      setLoading(false);
    };

    fetchData();
  }, [getMultiLangString]);

  const goToProfile = (id: string) => {
    routes.JOB_PROFILE.go(
      {},
      {
        roleId: id,
        isBackPath: true,
      },
    );
  };
  const activeJobsCount = jobs.filter((job) => job.status === JOB_STATUS_LABELS.ACTIVE).length;

  return (
    <>
      <SidebarRow
        type={SIDEBAR_ITEM_TYPES.CAREER}
        onClick={() => {
          if (jobs.length === 1) {
            goToProfile(jobs[0].id);
          } else if (jobs.length > 1) {
            setShowJobsModal(true);
          }
        }}
        isShowPlaceholder={isEmpty(jobs)}
        name={!isEmpty(jobs) ? getMultiLangString(jobs[0].name) : ''}
        nameCount={activeJobsCount <= 1 ? null : activeJobsCount - 1}
        isLoading={loading}
      />
      {showJobsModal && (
        <JobsModal
          closeModal={() => setShowJobsModal(false)}
          jobs={jobs}
          onRowClick={(item) => {
            goToProfile(item.id);
          }}
        />
      )}
    </>
  );
};

export default React.memo(CareerCard);
