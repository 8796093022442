import React, { useMemo } from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import { Trans } from '@lingui/macro';
import { Controller, useForm } from 'react-hook-form';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { FocusAreaAnswers } from '~/pages/ReviewGiveFeedback/components/Questions/Components/FocusAreaAnswers';
import { IAnswerType } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/QuestionEditModal';

import { CommentSection, NAOption } from './Components';
import { FocusAreaComments } from './Components/FocusAreaComments';
import { FocusAreaRating } from './Components/FocusAreaRating';
import { FocusAreaRatings } from './Components/FocusAreaRating.design';
import {
  CareerHeader,
  FocusAreaHeader,
  IconBackground,
  IconContainer,
  Scale,
  TextLabel,
  QuestionHeader,
  QuestionWrapper,
  ContentWrapper,
  Answers,
  AllAnswersPerFocusArea,
} from './design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

import type { IQuestionCustomSkillData, IQuestionSkillCategoryData } from '../../types';

interface IQuestionViewSkillProps {
  focusAreaQuestions:
    | IQuestionCustomSkillData['subQuestions']
    | IQuestionSkillCategoryData['subQuestions'];
  languageState: ILanguageStateReturn;
  onChange?: (data: IAnswerType | IAnswerType[]) => void;
  defaultValues: ISkillQuestionForm;
  canAnswer: boolean;
  hasError?: boolean;
  hasCommentError?: boolean;
  showOtherRatings?: boolean;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  isDashboard?: boolean;
}

export type ISkillQuestionForm = {
  answers: { value: number | undefined }[];
  isNotApplicable?: boolean;
  comment?: string;
};

const SkillQuestion = ({
  focusAreaQuestions,
  defaultValues,
  onChange,
  canAnswer,
  hasError,
  hasCommentError,
  showOtherRatings,
  useMultiLangString,
  isDashboard,
}: IQuestionViewSkillProps) => {
  const form = useForm<ISkillQuestionForm>({ defaultValues });
  const { control, watch } = form;
  const notApplicableWatch = watch('isNotApplicable');
  const getMultiLangString = useMultiLangString();
  const relevantQuestion = focusAreaQuestions.at(0)?.question;
  const options = relevantQuestion?.settings.options;

  const jobNames = useMemo(() => {
    if (!(relevantQuestion && relevantQuestion.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY)) {
      return '';
    }
    const jobs = [
      getMultiLangString(relevantQuestion.settings.jobProfileName),
      ...(relevantQuestion.settings.duplicateQuestions ?? []).map((q) =>
        getMultiLangString(q.question.settings.jobProfileName),
      ),
    ];
    if (jobs.length === 1) {
      return jobs[0];
    } else {
      return jobs.join(', ');
    }
  }, [relevantQuestion, getMultiLangString]);

  return (
    <QuestionWrapper $isDashboard={isDashboard}>
      {canAnswer && (
        <>
          <ContentWrapper className={notApplicableWatch ? 'disabled' : ''}>
            <QuestionHeader>
              <TextLabel>{relevantQuestion && getMultiLangString(relevantQuestion.name)}</TextLabel>
              {!relevantQuestion?.settings?.isAnswerObligated && (
                <Controller
                  name="isNotApplicable"
                  control={control}
                  render={({ field }) => (
                    <NAOption
                      isChecked={field.value}
                      onChange={(value: boolean) => {
                        field.onChange(value);
                        focusAreaQuestions.forEach((fa) => {
                          onChange?.({
                            questionId: fa.question.id,
                            isNotApplicable: value,
                            answer: value ? null : -1, // when NA enabled -> we set value to null, when disabled -> we reset answer to -1
                            oldAnswer: value ? null : -1, // when NA enabled -> we set value to null, when disabled -> we reset answer to -1
                          });
                          if (fa.question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
                            fa.question.settings.duplicateQuestions?.forEach((dupe) =>
                              onChange?.({
                                questionId: dupe.question.id,
                                isNotApplicable: value,
                                answer: value ? null : -1, // when NA enabled -> we set value to null, when disabled -> we reset answer to -1
                                oldAnswer: value ? null : -1, // when NA enabled -> we set value to null, when disabled -> we reset answer to -1
                              }),
                            );
                          }
                        });
                      }}
                    />
                  )}
                />
              )}
            </QuestionHeader>

            {jobNames && (
              <CareerHeader>
                <IconContainer>
                  <IconBackground />
                  <Icon size={ICON_SIZES.SMALL} icon={ICONS.CAREER} color={COLORS.COMPANY} />
                </IconContainer>
                <div>{jobNames}</div>
              </CareerHeader>
            )}

            <FocusAreaRatings>
              {options && (
                <FocusAreaHeader>
                  <div>
                    <Trans>Expected behavior or result</Trans>
                  </div>
                  <Scale>
                    <ToolTip
                      maxWidth="300px"
                      placement={TOOLTIP_PLACEMENTS.BOTTOM}
                      tooltip={`1 - ${getMultiLangString(options[0].label)}`}
                    >
                      <div>1 - {getMultiLangString(options[0].label)}</div>
                    </ToolTip>
                    <ToolTip
                      maxWidth="300px"
                      placement={TOOLTIP_PLACEMENTS.BOTTOM}
                      tooltip={`${options.length} - ${getMultiLangString(
                        options.at(options.length - 1)!.label,
                      )}`}
                    >
                      <div>
                        {options.length} -{' '}
                        {getMultiLangString(options.at(options.length - 1)!.label)}
                      </div>
                    </ToolTip>
                  </Scale>
                </FocusAreaHeader>
              )}
              {focusAreaQuestions.map((fa, index) => {
                return (
                  <FocusAreaRating
                    useMultiLangString={useMultiLangString}
                    hasError={hasError}
                    index={index}
                    key={fa.question.id}
                    focusAreaQuestion={fa}
                    form={form}
                    onChange={onChange}
                    disabled={notApplicableWatch}
                  />
                );
              })}
            </FocusAreaRatings>
          </ContentWrapper>
          {relevantQuestion?.settings?.isCommentsAllowed && (
            <CommentSection
              hasError={hasCommentError}
              formMethods={form}
              isRequired={!notApplicableWatch && relevantQuestion?.settings?.isCommentsObligated}
              onChange={(comment) => {
                const questions: {
                  questionId: string;
                  answer?: string | number | null;
                  comment?: string;
                  isNotApplicable?: boolean;
                }[] = [];
                focusAreaQuestions.forEach((fa) => {
                  questions.push({ questionId: fa.question.id, comment });
                  if (fa.question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
                    fa.question.settings.duplicateQuestions?.forEach((dupe) =>
                      onChange?.({ questionId: dupe.question.id, comment }),
                    );
                  }
                });
                onChange?.(questions);
              }}
            />
          )}
        </>
      )}
      {showOtherRatings && (
        <>
          <AllAnswersPerFocusArea>
            <Trans>All answers per focus area</Trans>
            <Answers>
              {focusAreaQuestions.map((fa) => {
                return (
                  <FocusAreaAnswers
                    useMultiLangString={useMultiLangString}
                    key={fa.question.settings.focusArea.id}
                    focusAreaQuestion={fa}
                  />
                );
              })}
            </Answers>
          </AllAnswersPerFocusArea>

          <FocusAreaComments
            useMultiLangString={useMultiLangString}
            focusAreaQuestion={focusAreaQuestions.at(0)}
          />
        </>
      )}
    </QuestionWrapper>
  );
};

export { SkillQuestion };
