import React, { useMemo } from 'react';
import type { MouseEventHandler } from 'react';

import { REVIEW_QUESTION_EVALUATORS } from '@learned/constants';
import { ILanguageValue, IReviewTemplate, IReviewTheme } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { IconNames, IconOld } from '~/components/IconOld';
import Tooltip from '~/components/Tooltip';
import { ThemeContainer } from '~/pages/ReviewThemeSetup/components/QuestionPreviewModal/design';

import {
  Header,
  Main,
  Separator,
  Title,
  Wrapper,
  Footer,
  FooterIconContainer,
  ErrorMessage,
  SubTitle,
  TextSection,
} from './styling';

import { QUESTION_TYPES } from '~/constants/questionsTypes';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type { IMultiLangString } from '@learned/types/src/multiLangString';

interface IQuestionProps {
  name: ILanguageValue[] | IMultiLangString;
  type: QUESTION_TYPES;
  index: number;
  onClickTitle?: MouseEventHandler<HTMLDivElement>;
  onClickCard?: MouseEventHandler<HTMLDivElement>;
  languageState: ILanguageStateReturn;
  isActive?: boolean;
  className?: string;
  themeName?: IMultiLangString;
  children?: React.ReactNode;
  isPreselectedLang?: boolean;
  hideIndex?: boolean;
  evaluators?: REVIEW_QUESTION_EVALUATORS[];
  error?: string;
  templates?: IReviewTemplate[];
  theme?: Partial<IReviewTheme>;
  skillCategory?: string;
  showTemplates?: boolean;
  themeIcon?: IconNames;
  themeIconColor?: string;
}

const PreviewQuestionCard = ({
  name,
  type,
  index,
  onClickTitle,
  onClickCard,
  languageState,
  isActive,
  className,
  themeName,
  children,
  isPreselectedLang = false,
  hideIndex = false,
  evaluators = [],
  error,
  templates,
  theme,
  skillCategory,
  showTemplates = true,
  themeIcon,
  themeIconColor,
}: IQuestionProps) => {
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();
  const ANSWER_TYPES = {
    [QUESTION_TYPES.TEXT]: {
      key: QUESTION_TYPES.TEXT,
      icon: <Icon icon={ICONS.TEXT_ANSWER} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.SINGLE]: {
      key: QUESTION_TYPES.SINGLE,
      icon: <Icon icon={ICONS.SINGLE_SELECT} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.MULTIPLE]: {
      key: QUESTION_TYPES.MULTIPLE,
      icon: <Icon icon={ICONS.MULTI_SELECT} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.TRAFFIC]: {
      key: QUESTION_TYPES.TRAFFIC,
      icon: <Icon icon={ICONS.TRAFFIC} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.STARS]: {
      key: QUESTION_TYPES.STARS,
      icon: <Icon icon={ICONS.STAR} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.SMILEYS]: {
      key: QUESTION_TYPES.SMILEYS,
      icon: <Icon icon={ICONS.SMILEY} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.RATING]: {
      key: QUESTION_TYPES.RATING,
      icon: <Icon icon={ICONS.RATING} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.CUSTOM_SKILL]: {
      key: QUESTION_TYPES.CUSTOM_SKILL,
      icon: <Icon icon={ICONS.CUSTOM_SKILL} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.SKILL_CATEGORY]: {
      key: QUESTION_TYPES.SKILL_CATEGORY,
      icon: <Icon icon={ICONS.SKILL} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.GOAL_PLAN]: {
      key: QUESTION_TYPES.GOAL_PLAN,
      icon: <Icon icon={ICONS.PLAN_GOALS} size={ICON_SIZES.LARGE} />,
    },
  };

  const EVALUATORS_TYPES = {
    [REVIEW_QUESTION_EVALUATORS.COACH]: {
      key: REVIEW_QUESTION_EVALUATORS.COACH,
      icon: <Icon icon={ICONS.COACH} size={ICON_SIZES.LARGE} />,
      tooltip: <Trans>Coach review</Trans>,
    },
    [REVIEW_QUESTION_EVALUATORS.PEER]: {
      key: REVIEW_QUESTION_EVALUATORS.PEER,
      icon: <Icon icon={ICONS.PEER} size={ICON_SIZES.LARGE} />,
      tooltip: <Trans>Peer review</Trans>,
    },
    [REVIEW_QUESTION_EVALUATORS.EMPLOYEE]: {
      key: REVIEW_QUESTION_EVALUATORS.EMPLOYEE,
      icon: <Icon icon={ICONS.EMPLOYEE} size={ICON_SIZES.LARGE} />,
      tooltip: <Trans>Self review</Trans>,
    },
  };

  const { companyPrimaryLanguage, languages } = languageState;

  const locale = isPreselectedLang ? languages[0].locale : companyPrimaryLanguage.locale;

  const getTranslatedThemeName = (name: IMultiLangString) => {
    return name[locale] || <Trans>Empty</Trans>;
  };

  const questionName = useMemo(() => {
    return Array.isArray(name)
      ? name.find((t) => locale === t.locale)?.value
      : name[locale] || <Trans>Empty</Trans>;
  }, [locale, name]);

  const translatedThemeName =
    theme || themeName
      ? isPreselectedLang
        ? getTranslatedThemeName((theme ? theme.name : themeName) as IMultiLangString)
        : getMultiLangString((theme ? theme.name : themeName) as IMultiLangString)
      : null;

  const getTemplateText = () => {
    if (templates && templates.length) {
      return i18n._(t`Used in ${getMultiLangString(templates[0].name)}`);
    }
    return i18n._(t`Has not yet been added to a review template.`);
  };

  const getToolTip = () => {
    let tooltip = '';
    templates?.forEach((template) => {
      tooltip += `${getMultiLangString(template.name)}\n`;
    });
    return tooltip;
  };

  return (
    <Wrapper onClick={onClickCard} className={className} isActive={isActive} error={!!error}>
      {error && (
        <ErrorMessage>
          <Icon icon={ICONS.WARNING} size={ICON_SIZES.MEDIUM} />
          <span>
            <Trans>{error}</Trans>
          </span>
        </ErrorMessage>
      )}
      <Header>
        {evaluators.length > 0 && (
          <Footer>
            {evaluators.map((evaluator, idx) => (
              <Tooltip tooltip={EVALUATORS_TYPES[evaluator].tooltip} key={idx}>
                <FooterIconContainer key={idx}>
                  <span className="footer-icon">{EVALUATORS_TYPES[evaluator].icon}</span>
                </FooterIconContainer>
              </Tooltip>
            ))}
          </Footer>
        )}
        {(theme || themeName) && (
          <>
            <Separator>|</Separator>
            {!!(theme || (themeIconColor && themeIcon)) && (
              <ThemeContainer
                // @ts-ignore
                color={
                  theme?.iconColor || themeIconColor
                    ? ((theme?.iconColor || themeIconColor)?.split('-') as string[])
                    : ['#f7f9ff', '#ebf1fe']
                }
              >
                <IconOld
                  className="icon"
                  name={(theme?.icon || themeIcon) as IconNames}
                  color={COLORS.MIDDLE_GRAY}
                  width={14}
                  height={14}
                />
              </ThemeContainer>
            )}
            <span>{translatedThemeName}</span>
            <Separator>|</Separator>
          </>
        )}
        <Footer>
          {skillCategory && <span>{skillCategory}</span>}
          {/* @ts-ignore */}
          <span className="icon">{ANSWER_TYPES[type].icon}</span>
        </Footer>
      </Header>
      <Main withBottom={!!children}>
        {!hideIndex && <div className="index">{++index}</div>}
        <TextSection>
          <Title onClick={onClickTitle}>{questionName}</Title>
          {showTemplates && (
            <Tooltip tooltip={templates && templates.length && getToolTip()}>
              <SubTitle onClick={onClickTitle}>
                <span>{getTemplateText()}</span>
                <span>{templates && templates.length > 1 && ` +${templates.length - 1}`}</span>
              </SubTitle>
            </Tooltip>
          )}
        </TextSection>
      </Main>
      {children}
    </Wrapper>
  );
};

export { PreviewQuestionCard };
