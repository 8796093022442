import { GOAL_STATUSES_NEW } from '@learned/constants';

import { ICONS } from '~/components/Icon';

import type { ICareerPlan, IGoal, IJobProfile, WithReplace } from '@learned/types';

type GoalStatus = Exclude<GOAL_STATUSES_NEW, GOAL_STATUSES_NEW.DRAFT>;

export type IGoalsPreview = {
  [key in GoalStatus]?: IGoal[];
};

export type IGoalsSectionDashboard = {
  key: GoalStatus;
  title: string;
  icon: ICONS;
  color: string;
  isCollapsible?: boolean;
  isOpen?: boolean;
  items?: IGoal[];
};

export enum CareerPlansSection {
  ACTIVE = 'active',
  AMBITION = 'ambition',
  PREVIOUS = 'previous',
}

export type ICareerPlanPopulated = WithReplace<ICareerPlan, { jobProfile: IJobProfile }>;

export type ICareerPlansPreview = {
  [key in CareerPlansSection]?: ICareerPlanPopulated[];
};

export type ICareerPlansSectionDashboard = {
  key: CareerPlansSection;
  title: string;
  icon: ICONS;
  color: string;
  isCollapsible?: boolean;
  isOpen?: boolean;
  items?: ICareerPlanPopulated[];
  placeholder: string;
};
