import styled, { css } from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

const RIGHT_BLOCK_WIDTH = '120px';
const MARGIN_LEFT = '20px';

export const Wrapper = styled.div<{ isPDFView?: boolean }>`
  ${(props) => !props.isPDFView && 'width: 750px;'}
  background-color: ${COLORS.WHITE};
  padding: 24px 32px 26px;
  margin-bottom: 46px;
  border-radius: 6px;
  box-sizing: border-box;

  @media print {
    page-break-inside: avoid;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  @media print {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: 500;
  & .description {
    color: ${COLORS.SUBTEXT};
    font-size: 12px;
    font-weight: normal;
    margin-left: 10px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.GRAY_LIGHT};
  color: ${COLORS.WHITE};
  width: 24px;
  height: 24px;
  border-radius: 100px;
  margin-right: 9px;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.08);
`;

export const ListRow = styled.div`
  display: flex;
  min-height: 32px;
  justify-content: space-between;
  padding: 0;
  border-radius: 6px;
`;

export const RowRightBlock = styled.div`
  display: flex;
  align-items: center;

  max-width: calc(100% - ${RIGHT_BLOCK_WIDTH} - ${MARGIN_LEFT});

  & .icon {
    width: 32px;
    color: ${COLORS.BORDERS};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RowLeftBlock = styled.div`
  display: flex;
  align-items: center;
  width: ${RIGHT_BLOCK_WIDTH};
  margin-left: ${MARGIN_LEFT};
`;

export const EvaluatorLabel = styled.div`
  margin-left: 10px;
  font-size: 12px;
  color: ${COLORS.PLACEHOLDERS};
`;

export const Content = styled.div`
  margin-top: 16px;
  margin-left: 32px;
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  padding-bottom: 10px;

  .title {
    font-weight: 600;
  }
  .comment {
    font-weight: normal;
    font-size: 14px;
  }
  .date {
    font-size: 14px;
  }

  @media print {
    page-break-inside: auto;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  .section {
    width: 100%;
  }

  .signature {
    font-family: Merriweather;
    font-size: 20px;
    font-weight: normal;
    font-style: italic;
  }

  @media print {
    page-break-inside: avoid;
  }
`;

export const Settings = styled.div`
  margin-top: 32px;
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .section {
    display: flex;
    align-items: center;
    gap: 188px;
    padding: 13px 18px 15px;
    margin-bottom: 4px;
    background-color: ${COLORS.BG_LIST};
    border-radius: 6px;

    .content {
      font-size: 14px;
      color: ${COLORS.TEXT_MAIN};
    }

    .status {
      width: 45px;
      font-size: 12px;
      font-weight: 600;
      margin-right: 100px;

      .true {
        color: ${COLORS.TABLE_SUCCESS};
      }
      .false {
        color: ${COLORS.ACCENT_ERROR};
      }
    }
  }
`;

export const Text = styled.div`
  font-size: 12px;
  letter-spacing: -0.13px;
  color: ${COLORS.PLACEHOLDERS};
`;

export const Divider = styled.div`
  width: 100%;
  margin: 14px 0;
  border-top: 1px solid ${COLORS.BORDERS};
`;

export const Reset = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(266deg, rgba(255, 138, 138, 0.1), rgba(255, 82, 82, 0.2));
  font-size: 12px;
  padding: 16px 32px;
  border-radius: 6px;
  max-height: 48px;
  box-sizing: border-box;

  .title {
    color: ${COLORS.TEXT_MAIN};
    width: 100%;
  }

  .divider {
    border-left: 1px solid ${COLORS.TAB_WRAPPER_GRAY};
    width: 1px;
    height: 19px;
    margin-right: 32px;
  }

  .resetAll {
    color: ${COLORS.ACCENT_ERROR};
  }
`;

export const StyledIcon = styled(Icon)<{
  rotate?: number;
  color: string;
}>`
  box-sizing: border-box;
  margin-right: 5px;
  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(${rotate}deg);
    `}
`;
