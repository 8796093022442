import React, { useMemo, useState } from 'react';

import { REVIEW_QUESTION_EVALUATORS, REVIEW_RATING_TYPE } from '@learned/constants';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Tooltip from '~/components/Tooltip';
import { getGradient } from '~/pages/ReviewGiveFeedback/utils';
import {
  type IUserReviewQuestionSkillCategoryGrouped,
  type IUserReviewQuestionCustomSkillGrouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import { AverageRating } from './AverageRating';
import { Comments, FocusAreaItem, RatingItem, SkillItem, TableRow } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

import { Comment } from '../components/Comment';

import type { ISkill, IUser, IUserReview, IReviewRating } from '@learned/types';

function SkillRow({
  skill,
  onEdit,
  userReview,
  availableEvaluators,
  isPDFView = false,
}: {
  availableEvaluators?: {
    employee: boolean;
    coach: boolean;
    peer: boolean;
  };
  userReview: IUserReview;
  skill:
    | IUserReviewQuestionSkillCategoryGrouped['skills'][0]
    | IUserReviewQuestionCustomSkillGrouped['skills'][0];
  onEdit: (ratings: IReviewRating[]) => void;
  isPDFView?: boolean;
}) {
  const getMultiLangString = useMultiLangString();
  const currentUser = useSelector(getUser);

  const [isOpen, setIsOpen] = useState(false);
  const allRatings = useMemo(() => {
    return skill.questions.flatMap((question) => question.reviewRatings ?? []);
  }, [skill]);
  const total = skill.questions?.at(0)?.settings.options.length ?? 0;
  const totalComments = (skill.questions?.at(0)?.reviewRatings ?? [])
    .map((rating) => rating.comment)
    .filter(Boolean).length;
  const settings = skill.questions?.at(0)?.settings;

  /**
   * TODO: Disable the Edit button in User Review Dashboard
   */
  const isEditable = (_userReview: IUserReview, _currentUser: IUser, _type: REVIEW_RATING_TYPE) => {
    return false;
    // if (
    //   [
    //     USER_REVIEW_STATUS.PUBLISHED,
    //     USER_REVIEW_STATUS.SIGNING,
    //     USER_REVIEW_STATUS.ARCHIVED,
    //   ].includes(userReview.status)
    // ) {
    //   return false;
    // }

    // if (userReview.settings.isDigitalSign && userReview.status === USER_REVIEW_STATUS.COMPLETED) {
    //   return false;
    // }

    // if (type === REVIEW_RATING_TYPE.SELF) {
    //   const selfRating = allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.SELF);

    //   return (
    //     selfRating.length === skill.questions.length && userReview.createdFor === currentUser.id
    //   );
    // } else if (type === REVIEW_RATING_TYPE.COACH) {
    //   const coachRatings = allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.COACH);

    //   return (
    //     coachRatings.length === skill.questions.length &&
    //     userReview.coaches.includes(currentUser.id)
    //   );
    // } else if (type === REVIEW_RATING_TYPE.PEER) {
    //   const peerRatings = allRatings.filter(
    //     (rating) =>
    //       rating.type === REVIEW_RATING_TYPE.PEER || rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
    //   );
    //   return (
    //     peerRatings.length === skill.questions.length &&
    //     userReview.peers.filter((peer) => peer.value === currentUser.id).length > 0
    //   );
    // }
  };

  return (
    <>
      <TableRow>
        <SkillItem isOpen={isOpen} onClick={() => setIsOpen((prev) => !prev)}>
          <Icon
            transform={isOpen || isPDFView ? 'rotate(90)' : 'rotate(0)'}
            size={ICON_SIZES.MEDIUM}
            icon={ICONS.NEXT}
            color={isOpen ? COLORS.COMPANY : COLORS.INACTIVE}
          />
          <Tooltip
            tooltip={getMultiLangString(
              (skill.questions.at(0)?.settings?.skill as ISkill)?.name ?? '',
            )}
          >
            <span className="skillName">
              {getMultiLangString((skill.questions.at(0)?.settings?.skill as ISkill)?.name ?? '')}
            </span>
          </Tooltip>
          {totalComments > 0 && (
            <span className="commentCount">
              <Icon size={ICON_SIZES.MEDIUM} icon={ICONS.COMMENT} /> {totalComments}
            </span>
          )}
        </SkillItem>
        {availableEvaluators?.[REVIEW_QUESTION_EVALUATORS.EMPLOYEE] && (
          <AverageRating
            total={total}
            ratings={allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.SELF)}
            onEdit={
              isEditable(userReview, currentUser, REVIEW_RATING_TYPE.SELF)
                ? () =>
                    onEdit(allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.SELF))
                : undefined
            }
          />
        )}
        {availableEvaluators?.[REVIEW_QUESTION_EVALUATORS.COACH] && (
          <AverageRating
            total={total}
            ratings={allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.COACH)}
            onEdit={
              isEditable(userReview, currentUser, REVIEW_RATING_TYPE.COACH)
                ? () =>
                    onEdit(allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.COACH))
                : undefined
            }
          />
        )}
        {availableEvaluators?.[REVIEW_QUESTION_EVALUATORS.PEER] && (
          <AverageRating
            total={total}
            ratings={allRatings.filter(
              (rating) =>
                rating.type === REVIEW_RATING_TYPE.PEER ||
                rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
            )}
            onEdit={
              isEditable(userReview, currentUser, REVIEW_RATING_TYPE.PEER)
                ? () =>
                    onEdit(
                      allRatings.filter(
                        (rating) =>
                          rating.type === REVIEW_RATING_TYPE.PEER ||
                          rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
                      ),
                    )
                : undefined
            }
          />
        )}
      </TableRow>
      {(isOpen || isPDFView) && (
        <>
          {skill.questions.map((question) => {
            const reviewRatings = (question.reviewRatings ?? []).filter(
              (rating) => rating.answer !== undefined,
            );
            const selfRating = question.reviewRatings?.find(
              (rating) => rating.type === REVIEW_RATING_TYPE.SELF,
            )?.answer;
            return (
              <TableRow key={question.id}>
                <FocusAreaItem>
                  {getMultiLangString(question.settings.focusArea.name)}
                </FocusAreaItem>
                {availableEvaluators?.[REVIEW_QUESTION_EVALUATORS.EMPLOYEE] && (
                  <RatingItem
                    gradient={
                      selfRating && Number(selfRating) >= 0
                        ? getGradient(selfRating as number, total)
                        : undefined
                    }
                  >
                    {selfRating ? selfRating : selfRating === null ? <Trans>N/A</Trans> : ' '}
                  </RatingItem>
                )}
                {availableEvaluators?.[REVIEW_QUESTION_EVALUATORS.COACH] && (
                  <AverageRating
                    total={total}
                    ratings={reviewRatings.filter(
                      (rating) => rating.type === REVIEW_RATING_TYPE.COACH,
                    )}
                  />
                )}
                {availableEvaluators?.[REVIEW_QUESTION_EVALUATORS.PEER] && (
                  <AverageRating
                    total={total}
                    ratings={reviewRatings.filter(
                      (rating) =>
                        rating.type === REVIEW_RATING_TYPE.PEER ||
                        rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
                    )}
                  />
                )}
              </TableRow>
            );
          })}
          <TableRow>
            <Comments>
              {(skill.questions.at(0)?.reviewRatings ?? []).map((rating) => {
                /**
                 * TODO: Disable the Edit button in User Review Dashboard
                 */
                // const showEditButton =
                //   rating.createdBy.id === currentUser.id &&
                //   ![
                //     USER_REVIEW_STATUS.PUBLISHED,
                //     USER_REVIEW_STATUS.SIGNING,
                //     USER_REVIEW_STATUS.ARCHIVED,
                //   ].includes(userReview.status);

                const value = Number(rating.answer);
                const valueIndex = value - 1;
                const valueMax = settings?.options.length as unknown as number;
                const valueLabel = getMultiLangString(
                  (settings?.options[valueIndex]?.label as unknown as string) || '',
                );
                return (
                  rating.comment && (
                    <Comment
                      key={rating.id}
                      rating={rating}
                      value={Number(rating.answer)}
                      valueMax={valueMax}
                      valueLabel={valueLabel}
                      showEditButton={false}
                      onEdit={() => onEdit(allRatings.filter(({ task }) => task === rating.task))}
                    />
                  )
                );
              })}
            </Comments>
          </TableRow>
        </>
      )}
    </>
  );
}

export { SkillRow };
