import styled from 'styled-components';

import { SearchField } from '~/components/Text';
import ActionsContainer from '~/components/UI/ActionsContainer';
import Table, { TableCol, TableRow } from '~/components/UI/Table';

import { COLOR_PALETTE } from '~/styles';

export const ActionsContainerWrapper = styled(ActionsContainer)`
  height: 48px;
  align-items: center;
  border: unset;
`;

export const Wrapper = styled.div`
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_MIDDLE};
`;

export const StyledSearch = styled(SearchField)`
  height: 32px;
  width: 248px;
  & > input {
    border-radius: 6px;
    font-size: 14px;
    height: 32px;
  }
`;

export const StyledTable = styled(Table)`
  background-color: white;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
  max-height: 75vh;

  table:first-of-type {
    position: sticky;
    top: 0;
    align-self: flex-start;
    background-color: white;
    z-index: 100;
  }
`;

export const StyledRow = styled(TableRow)`
  padding: 0 16px;
`;

export const CompanyName = styled(TableCol)`
  font-size: 16px;
  font-weight: 600;
  color: ${COLOR_PALETTE.BLACK};
  cursor: pointer;
  word-break: break-word;
  padding-left: 16px !important;
  background: white;
`;

export const CompanyText = styled(TableCol)`
  font-size: 14px;
  cursor: pointer;
  color: ${COLOR_PALETTE.DARK_GRAY};
  word-break: break-word;
`;

export const ContextMenuWrapper = styled(TableCol)`
  background: white;
`;

export const ContextMenuIcon = styled.div`
  width: min-content;
`;

export const CompanyEnabled = styled(TableCol)<{ $enabled: boolean }>`
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #${(props) => (props.$enabled ? '29ccab' : 'e93c3c')};
`;

export const CompanyQuickStart = styled(TableCol)`
  display: flex;
  width: 140px;
  align-items: center;
`;

export const Progress = styled.div`
  font-size: 14px;

  color: ${COLOR_PALETTE.DARK_GRAY};
  line-height: 1.57;
  margin: 0 0 0 8px;
`;

export const FilterWrapper = styled.div`
  margin-left: 10px;
`;
