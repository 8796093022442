import React from 'react';

import { GOAL_PROGRESS_TYPES, SYMBOLS } from '@learned/constants';

import ProgressBarWide from '~/components/ProgressBarWide';

import { CurrentValueContainer, ProgressWrapper } from './design';

import { COLORS } from '~/styles';
import { getGoalProgressWithDecimals } from '~/utils/getGoalProgressWithDecimals';

import type { IGoal } from '@learned/types';

export const GoalProgress = ({ goal }: { goal: IGoal }) => {
  const isAvgActivity = goal.progressType === GOAL_PROGRESS_TYPES.AVG;
  const isAvgContributingGoals =
    goal.progressType === GOAL_PROGRESS_TYPES.AVG_OF_CONTRIBUTING_GOALS;
  const isNumbers = goal.progressType === GOAL_PROGRESS_TYPES.NUMBERS;
  const isPercentage = goal.progressType === GOAL_PROGRESS_TYPES.PERCENTAGE;
  const isCurrency = goal.progressType === GOAL_PROGRESS_TYPES.CURRENCY;
  const isBinary = goal.progressType === GOAL_PROGRESS_TYPES.IS_DONE;

  const formatProgressValue = (progress: number) => {
    return getGoalProgressWithDecimals(String(progress), goal.progressType as GOAL_PROGRESS_TYPES);
  };

  const progressValue = formatProgressValue(goal.progress || 0);
  const calculatedProgressValue = formatProgressValue(goal.calculatedProgress || 0);

  const currentProgress =
    isAvgActivity || isAvgContributingGoals
      ? `${calculatedProgressValue}%`
      : isBinary
      ? `${progressValue}%`
      : isNumbers
      ? `${progressValue}`
      : isPercentage
      ? `${progressValue}%`
      : isCurrency
      ? `${SYMBOLS[goal.progressDetails?.currency || 'dollar']}${progressValue}`
      : `${goal.progress}`;

  return (
    <>
      {
        <ProgressWrapper>
          {/* @ts-ignore */}
          <ProgressBarWide
            value={goal.calculatedProgress}
            height="5px"
            rounded={true}
            isActive={true}
            backgroundColor={COLORS.PROGRESSBAR_BACKGROUND}
          />
          <CurrentValueContainer>
            {currentProgress !== 'undefined' ? currentProgress.replace('.', ',') : 0}
          </CurrentValueContainer>
        </ProgressWrapper>
      }
    </>
  );
};
