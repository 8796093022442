import React, { useState, useRef, useEffect, useMemo } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import isExists from 'date-fns/isExists';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import CheckBox from '~/components/CheckBox';
import { DropdownTemp } from '~/components/Dropdown';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import { AvatarModal } from '~/components/Modals/AvatarModal';
import SelectDropDown from '~/components/SelectDropDown';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TextField, FieldTitle } from '~/components/Text';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { SYSTEM_LANG } from '~/constants/languages';
import { updateUserAvatar, deleteUserAvatar } from '~/services/users';
import { setAuthUser } from '~/store/auth/actions';
import { changeLang } from '~/store/locale/actions';
import { COLORS } from '~/styles';

const AvatarCol = styled.div`
  margin-left: 16px;
`;

const AvatarRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
`;

const AvatarSection = styled.div`
  grid-row: 0;
  display: flex;
  position: relative;

  input[type='file'] {
    justify-self: center;
    width: 80px;
    height: 79px;
    background-color: orange;
    z-index: 2;
    opacity: 0;
    position: absolute;
  }
`;

const AvatarUploadPlaceholder = styled.div`
  background-color: ${COLORS.INACTIVE};
  grid-row: 1;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: ${(props) => (props.error ? 'red' : COLORS.INACTIVE)};
  border-width: 1px;
  grid-column: 2;
  justify-self: center;
  background-image: ${(props) =>
    props.backgroundImageUrl ? `url(${props.backgroundImageUrl})` : ''};
  background-size: cover;
  background-position: center;

  :hover {
    background-color: ${COLORS.BG_PAGE};
  }
`;

const InputSection = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.layout};
  column-gap: 16px;
  row-gap: 8px;
  margin-bottom: 24px;
`;

const InputWrapper = styled.div``;

const LargeFieldTitle = styled(FieldTitle)`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${(props) => (props.skipFirstTimeSetup === true ? COLORS.SUBTEXT : COLORS.TEXT_HOVER)};
  margin-bottom: 0px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const OptionalFieldLabel = styled.span`
  flex: auto;
`;

const OptionalText = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: -0.13px;
  color: ${COLORS.TEXT_MAIN};
`;

const InputField = styled(TextField)`
  width: 100%;
  font-size: 14px;
  border: 1px solid ${(props) => (props.error ? COLORS.ACCENT_ERROR : COLORS.BORDERS)};
  margin-right: ${(props) => props.$marginRight};
  height: 38px;

  :disabled {
    color: ${COLORS.TEXT_HOVER};
    padding-left: 0px;
    border: none;
  }
`;

export const Error = styled.span`
  color: ${COLORS.ACCENT_ERROR};
  font-size: 12px;
`;

const DateField = styled(TextField)`
  width: 80px;
  font-size: 14px;
  border: 1px solid ${(props) => (props.error ? COLORS.ACCENT_ERROR : COLORS.BORDERS)};
  margin-right: ${(props) => props.$marginRight};
  height: 38px;

  :disabled {
    color: ${COLORS.TEXT_HOVER};
    padding-left: 0px;
    border: none;
  }
`;

const PageContent = styled.div`
  margin-top: 9px;
`;

const WelcomeHeading = styled.div`
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
  margin: 0px;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
  margin-bottom: 39px;
`;

const DropdownLabel = styled.span`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_HOVER};
  display: block;
  font-size: 14px;
`;

const DropdownSelect = styled(SelectDropDown)`
  width: 325px;
  margin: 3px 0 58px;
  border-radius: 6px;

  button {
    font-size: 14px;
    border: 1px solid ${COLORS.BORDERS};
  }
`;

const DropdownFlags = styled(SelectDropDown)`
  border-radius: 6px;

  button {
    padding: 3px 35px 3px 13px;
    border: 1px solid ${COLORS.BORDERS};
  }
`;

const Text = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.PLACEHOLDERS};
`;

const FooterOne = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${COLORS.BORDERS};
  padding-top: 20px;
`;

const FooterTwo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  border-top: 1px solid ${COLORS.BORDERS};
  padding-top: 20px;
  align-items: center;
`;

const BackButtonWrapper = styled.div`
  flex: auto;

  & .back {
    color: ${COLORS.SUBTEXT};
    margin-left: -26px;
  }
`;

const CompleteButtonWrapper = styled.div`
  display: flex;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
`;

const Header = styled.span`
  flex: auto;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

const UploadInstructions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
`;

const PrivacyStatement = styled.div``;

const CheckBoxItem = styled.div`
  border-radius: 6px;
  text-align: left;
  margin-top: 24px;
  height: 22px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  & > div {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: ${COLORS.SUBTEXT};

    & > div {
      background-color: ${(props) => (props.error ? COLORS.ACCENT_ERROR : COLORS.COMPANY)};
    }
  }

  a {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--company-color);
    text-decoration: none;
  }
`;

const GenderDropdown = styled(DropdownTemp)`
  width: 100%;

  & > div:first-child {
    ::before {
      background-color: transparent;
    }
    height: 38px;
    font-size: 14px;
    border: 1px solid ${(props) => (props.isClickable ? COLORS.BORDERS : 'transparent')};
    padding-left: ${(props) => (props.isClickable ? '9px' : '0px')};
  }
`;

const DobWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const MonthDropDown = styled(DropdownTemp)`
  width: 100%;
  flex: auto;

  & > div:first-child {
    ::before {
      background-color: transparent;
    }
    height: 38px;
    font-size: 14px;
    border: 1px solid ${(props) => (props.error ? COLORS.ACCENT_ERROR : COLORS.BORDERS)};
    border: 1px solid
      ${(props) =>
        props.isClickable ? (props.error ? COLORS.ACCENT_ERROR : COLORS.BORDERS) : 'transparent'};
    padding-left: ${(props) => (props.isClickable ? '9px' : '0px')};
  }
`;

const DateOfBirthBlock = styled.div`
  font-size: 14px;
  padding: 9px 9px 9px 0px;
  color: ${COLORS.TEXT_HOVER};

  span {
    color: ${COLORS.PLACEHOLDERS};
  }
`;

function InfoPageNew({
  user,
  userDataState,
  profileSetupDataState,
  validation,
  loadingState,
  isShowError,
  submitTo,
  agreement,
  onAgreement,
  skipFirstTimeSetup,
  layOut,
  isEditable,
}) {
  const [userData, setUserData] = userDataState;
  const [profileSetupData, setProfileSetupData] = profileSetupDataState;
  const [loading, setLoading] = loadingState;
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const [selectedLang, setSelectedLang] = useState(userData.locale);
  const [isVisible, setIsVisible] = useState(user.isOwner || skipFirstTimeSetup);
  const uploadFileRef = useRef(null);
  const [gender, setGender] = useState();
  const [month, setMonth] = useState();
  const [monthIndex, setMonthIndex] = useState(null);
  const [genderIndex, setGenderIndex] = useState(null);
  const [date, setDate] = useState('');
  const [year, setYear] = useState('');
  const [visibleAvatarModal, setVisibleAvatarModal] = useState(false);

  const genderList = useMemo(
    () => [
      {
        key: 'man',
        name: i18n._(t`Man`),
      },
      {
        key: 'woman',
        name: i18n._(t`Woman`),
      },
      {
        key: 'other',
        name: i18n._(t`Other`),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n, selectedLang],
  );

  const months = useMemo(
    () => [
      {
        key: 1,
        name: i18n._(t`January`),
      },
      {
        key: 2,
        name: i18n._(t`February`),
      },
      {
        key: 3,
        name: i18n._(t`March`),
      },
      {
        key: 4,
        name: i18n._(t`April`),
      },
      {
        key: 5,
        name: i18n._(t`May`),
      },
      {
        key: 6,
        name: i18n._(t`June`),
      },
      {
        key: 7,
        name: i18n._(t`July`),
      },
      {
        key: 8,
        name: i18n._(t`August`),
      },
      {
        key: 9,
        name: i18n._(t`September`),
      },
      {
        key: 10,
        name: i18n._(t`October`),
      },
      {
        key: 11,
        name: i18n._(t`November`),
      },
      {
        key: 12,
        name: i18n._(t`December`),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n, selectedLang],
  );

  useEffect(() => {
    if (userData.gender) {
      setGender(genderList.find((g) => g.key === userData.gender));
    }
  }, [genderList, userData.gender]);

  useEffect(() => {
    if (userData.dateOfBirth) {
      const splittedDate = userData.dateOfBirth.split('-');
      setYear(splittedDate[0]);
      setDate(splittedDate[2]);
      setMonth(months.find((m) => m.key === Number(splittedDate[1])));
    }
  }, [months, userData.dateOfBirth]);

  useEffect(() => {
    if (monthIndex !== null) {
      setMonth(months.find((_, i) => i === monthIndex));
    }
    if (genderIndex !== null) {
      setGender(genderList.find((_, i) => i === genderIndex));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLang, genderList, months]);

  useEffect(() => {
    if (
      date &&
      month &&
      year &&
      year.length === 4 &&
      isExists(Number(year), month.key - 1, Number(date))
    ) {
      const modifiedMonth = month.key.toString().padStart(2, '0');
      const modifiedDate = date.toString().padStart(2, '0');
      const modifiedDateOfBirth = `${year}-${modifiedMonth}-${modifiedDate}`;
      setUserData((userData) => ({ ...userData, dateOfBirth: modifiedDateOfBirth }));
    } else {
      setUserData((userData) => ({ ...userData, dateOfBirth: undefined }));
    }
  }, [date, month, year, setUserData]);

  const onUploadAttachChange = async (e) => {
    let file = e.target.files[0];
    const maxAllowedSize = 2 * 1024 * 1024; // 2MB

    // If no file is selected, return
    if (!file) {
      return;
    }

    // If the file is bigger than 2MB, show an error
    if (file.size > maxAllowedSize) {
      addToast({
        title: <Trans>The file is too big.</Trans>,
        subtitle: <Trans>The file is bigger than 2MB.</Trans>,
        type: TOAST_TYPES.ERROR,
      });

      // Reset the file input
      uploadFileRef.current.value = null;

      return;
    }

    // If the file is not a png or jpeg, show an error
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      addToast({
        title: <Trans>Invalid type</Trans>,
        subtitle: <Trans>The file type should be png or jpeg</Trans>,
        type: TOAST_TYPES.ERROR,
      });

      // Reset the file input
      uploadFileRef.current.value = null;

      return;
    }

    setLoading(true);
    const updatedUser = await updateUserAvatar(file);
    await dispatch(setAuthUser(updatedUser));
    setLoading(false);
    setProfileSetupData({ ...profileSetupData, avatarUploaded: true });

    // Reset the file input
    uploadFileRef.current.value = null;
  };

  const onSubmitEditedImage = async (file) => {
    if (file) {
      setLoading(true);
      setVisibleAvatarModal(!visibleAvatarModal);

      const updatedUser = await updateUserAvatar(file);
      await dispatch(setAuthUser(updatedUser));

      setLoading(false);
      setProfileSetupData({ ...profileSetupData, avatarUploaded: true });
    }
  };

  const onDeleteAvatar = async () => {
    setLoading(true);
    const updatedUser = await deleteUserAvatar(user.id);
    await dispatch(setAuthUser(updatedUser));
    setVisibleAvatarModal(!visibleAvatarModal);
    setLoading(false);
  };

  const onCloseAvatarModal = () => {
    setVisibleAvatarModal(false);
  };

  const languages = [
    {
      key: SYSTEM_LANG.en.key,
      title: `${getUnicodeFlagIcon(SYSTEM_LANG.en.locale.split('_')[1])} ${i18n._(
        t`${SYSTEM_LANG.en.language}`,
      )}`,
    },
    {
      key: SYSTEM_LANG.nl.key,
      title: `${getUnicodeFlagIcon(SYSTEM_LANG.nl.locale.split('_')[1])} ${i18n._(
        t`${SYSTEM_LANG.nl.language}`,
      )}`,
    },
  ];

  const flags = [
    { key: SYSTEM_LANG.en.key, title: getUnicodeFlagIcon(SYSTEM_LANG.en.locale.split('_')[1]) },
    { key: SYSTEM_LANG.nl.key, title: getUnicodeFlagIcon(SYSTEM_LANG.nl.locale.split('_')[1]) },
  ];

  const handleLanguage = (newLang) => {
    setSelectedLang(newLang);
    dispatch(changeLang(newLang));
  };

  const handleNavigate = () => {
    setIsVisible(!isVisible);
  };

  const onClickUpload = () => {
    if (uploadFileRef.current) {
      uploadFileRef.current.click();
    }
  };

  const handleGenderChange = (selectedGender) => {
    selectedGender.key === null ? setGender() : setGender(selectedGender);

    setUserData((userData) => ({
      ...userData,
      gender: selectedGender.key,
    }));
  };

  const onClickEdit = () => {
    setVisibleAvatarModal(!visibleAvatarModal);
  };

  const hasUserAvatar = (avatarUrl) => avatarUrl && !avatarUrl.includes('https://s.gravatar.com');

  let formattedDateOfBirth;
  if (userData.dateOfBirth) {
    const [year, month, date] = userData.dateOfBirth.split('-');
    formattedDateOfBirth = `${date}/${month}/${year}`;
  }
  return (
    <PageContent>
      {!isVisible ? (
        <>
          <WelcomeHeading>
            <Trans>Welcome!</Trans>
          </WelcomeHeading>
          <SubTitle>
            <Trans>Lets set-up your profile</Trans>
          </SubTitle>
          <DropdownLabel>
            <Trans>Profile language</Trans>
          </DropdownLabel>
          <DropdownSelect
            options={languages}
            value={selectedLang}
            onChange={handleLanguage}
            keyName="key"
            renderLabel={(item) => <div>{item.title}</div>}
            disabled={false}
          />
          <FooterOne>
            <Button
              variant={ButtonVariant.NAVIGATION_PRIMARY}
              label={i18n._(t`Next`)}
              size={ButtonSize.MEDIUM}
              onClick={handleNavigate}
            />
          </FooterOne>
        </>
      ) : (
        <>
          {!skipFirstTimeSetup && (
            <HeaderText>
              <Header>
                <Trans>Create your profile</Trans>
              </Header>
              <DropdownFlags
                options={flags}
                value={selectedLang}
                onChange={handleLanguage}
                keyName="key"
                renderLabel={(item) => <div>{item.title}</div>}
                disabled={false}
              />
            </HeaderText>
          )}
          <ShowSpinnerIfLoading loading={loading}>
            <AvatarRow>
              <AvatarSection>
                <AvatarUploadPlaceholder
                  error={isShowError && !validation.validAvatar && !validation.valid}
                  backgroundImageUrl={user && user.avatarUrl}
                />
                <input
                  type="file"
                  id="uploadFile"
                  name="attach"
                  accept="image/png, image/jpeg"
                  ref={uploadFileRef}
                  onChange={(e) => onUploadAttachChange(e)}
                />
              </AvatarSection>
              <AvatarCol>
                <LargeFieldTitle>
                  <Trans>Profile picture</Trans>
                </LargeFieldTitle>
                <UploadInstructions>
                  {!hasUserAvatar(user.avatarUrl) ? (
                    <Button
                      variant={ButtonVariant.SECONDARY}
                      label={i18n._(t`Upload`)}
                      size={ButtonSize.MEDIUM}
                      onClick={onClickUpload}
                    />
                  ) : (
                    <Button
                      variant={ButtonVariant.SECONDARY}
                      label={i18n._(t`Edit`)}
                      size={ButtonSize.MEDIUM}
                      onClick={onClickEdit}
                    />
                  )}
                  <Text>
                    <Trans>jpg or png file, max 2mb</Trans>
                  </Text>
                </UploadInstructions>
              </AvatarCol>
            </AvatarRow>
          </ShowSpinnerIfLoading>
          <InputSection layout={layOut}>
            <InputWrapper>
              <LargeFieldTitle skipFirstTimeSetup={skipFirstTimeSetup}>
                <Trans>First name</Trans>
              </LargeFieldTitle>
              <InputField
                error={isShowError && !validation.firstName.isValid}
                value={userData.firstName}
                onChange={(e) =>
                  setUserData((userData) => ({ ...userData, firstName: e.target.value }))
                }
                $marginRight="35px"
                disabled={!isEditable}
                placeholder={i18n._(t`Emma`)}
              />
              {isShowError && validation.firstName.message && (
                <Error>{i18n._(validation.firstName.message)}</Error>
              )}
            </InputWrapper>

            <InputWrapper>
              <LargeFieldTitle skipFirstTimeSetup={skipFirstTimeSetup}>
                <Trans>Last name</Trans>
              </LargeFieldTitle>
              <InputField
                error={isShowError && !validation.lastName.isValid}
                value={userData.lastName}
                onChange={(e) =>
                  setUserData((userData) => ({ ...userData, lastName: e.target.value }))
                }
                disabled={!isEditable}
                placeholder={i18n._(t`Johnson`)}
              />
              {isShowError && validation.lastName.message && (
                <Error>{i18n._(validation.lastName.message)}</Error>
              )}
            </InputWrapper>
          </InputSection>
          <InputSection layout={layOut}>
            <InputWrapper>
              <LargeFieldTitle skipFirstTimeSetup={skipFirstTimeSetup}>
                <OptionalFieldLabel>
                  <Trans>Gender</Trans>
                </OptionalFieldLabel>
                {isEditable ? (
                  <OptionalText>
                    <Trans>(optional)</Trans>
                  </OptionalText>
                ) : (
                  <></>
                )}
              </LargeFieldTitle>
              <GenderDropdown
                items={genderList}
                placeholder={i18n._(t`Select gender`)}
                onChange={(item) => {
                  handleGenderChange(item ?? { key: null, name: null });
                  setGenderIndex(item ? genderList.indexOf(item) : null);
                }}
                selectedItem={gender}
                stringifyItem={(item) => item.name}
                hashItem={(item) => item.key}
                skipSort
                isSingleSelect
                isClickable={isEditable}
                isClearOption
              />
            </InputWrapper>

            <InputWrapper>
              <LargeFieldTitle skipFirstTimeSetup={skipFirstTimeSetup}>
                <Trans>Date of birth</Trans>
              </LargeFieldTitle>
              <DobWrapper>
                {isEditable ? (
                  <>
                    <MonthDropDown
                      error={isShowError && !validation.dateOfBirth.isValid}
                      items={months}
                      placeholder={i18n._(t`Month`)}
                      onChange={(item) => {
                        setMonth(item);
                        setMonthIndex(item ? months.indexOf(item) : null);
                      }}
                      selectedItem={month}
                      stringifyItem={(item) => item.name}
                      hashItem={(item) => item.key}
                      skipSort
                      isSingleSelect
                      isClickable={isEditable}
                    />
                    <DateField
                      error={isShowError && !validation.dateOfBirth.isValid}
                      type="number"
                      placeholder="DD"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      disabled={!isEditable}
                      min="1"
                    />
                    <DateField
                      error={isShowError && !validation.dateOfBirth.isValid}
                      type="number"
                      placeholder="YYYY"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      disabled={!isEditable}
                      min="1"
                    />
                  </>
                ) : (
                  <DateOfBirthBlock>
                    {formattedDateOfBirth === undefined ? (
                      <span>{i18n._(t`Date of birth`)}</span>
                    ) : (
                      formattedDateOfBirth
                    )}
                  </DateOfBirthBlock>
                )}
              </DobWrapper>
              <>
                {isShowError && !validation.dateOfBirth.isValid && (
                  <Error>{i18n._(validation.dateOfBirth.message)}</Error>
                )}
              </>
            </InputWrapper>
          </InputSection>

          {skipFirstTimeSetup && (
            <InputSection layout={layOut}>
              <InputWrapper>
                <LargeFieldTitle skipFirstTimeSetup={skipFirstTimeSetup}>
                  <Trans>E-mail</Trans>
                </LargeFieldTitle>
                <InputField value={userData.email} $marginRight="35px" disabled />
              </InputWrapper>
              <InputWrapper>
                <LargeFieldTitle skipFirstTimeSetup={skipFirstTimeSetup}>
                  <Trans>Phone</Trans>
                </LargeFieldTitle>
                <InputField
                  value={userData.phone}
                  error={isShowError && !validation.phone.isValid}
                  $marginRight="35px"
                  disabled={!isEditable}
                  onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                />
                {isShowError && validation.phone.message && (
                  <Error>{i18n._(validation.phone.message)}</Error>
                )}
              </InputWrapper>
            </InputSection>
          )}

          {!skipFirstTimeSetup && user.isOwner && (
            <CheckBoxItem error={isShowError && !agreement}>
              <CheckBox
                checked={agreement}
                size={24}
                onChange={(_e, value) => onAgreement(value)}
              />
              <PrivacyStatement>
                <Trans>
                  I accept the{' '}
                  <a
                    href="https://www.learned.io/nl/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Statement
                  </a>
                </Trans>
              </PrivacyStatement>
            </CheckBoxItem>
          )}
          {!skipFirstTimeSetup && (
            <FooterTwo>
              <BackButtonWrapper>
                {user.isOwner ? (
                  <></>
                ) : (
                  <Button
                    className="back"
                    label={i18n._(t`Back`)}
                    type="button"
                    variant={ButtonVariant.TEXT_PRIMARY}
                    icon={ICONS.BACK}
                    size={ButtonSize.MEDIUM}
                    iconSize={ICON_SIZES.SMALL}
                    onClick={handleNavigate}
                  />
                )}
              </BackButtonWrapper>
              <CompleteButtonWrapper>
                <Button
                  variant={ButtonVariant.NAVIGATION_PRIMARY}
                  label={i18n._(t`Complete`)}
                  size={ButtonSize.MEDIUM}
                  onClick={submitTo}
                />
              </CompleteButtonWrapper>
            </FooterTwo>
          )}
          {visibleAvatarModal && (
            <AvatarModal
              url={user.avatarUrl}
              onSave={onSubmitEditedImage}
              onDelete={onDeleteAvatar}
              onClose={onCloseAvatarModal}
            />
          )}
        </>
      )}
    </PageContent>
  );
}

export default InfoPageNew;
