import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import { ProfileSettingsBlock } from '~/components/ProfileSettingsComponents/MainDashboard/ProfileSettingsBlock';

import { ContentWrap, Title, Description, TextCol, LangRow } from './design';
import { LangOption } from './LangOption';
import { LangSelect } from './LangSelect';
import { SystemLangOptionComponent } from './SystemLangOptionComponent';
import { SystemLangSelectComponent } from './SystemLangSelectComponent';

import { SYSTEM_LANG } from '~/constants/languages';
import { getUser } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import getLang from '~/selectors/getLang';
import { updateUserProfile } from '~/services/users';
import { changePreferredLang } from '~/store/auth/actions';
import { changeLang } from '~/store/locale/actions';

import { SectionHeader } from '../SectionHeader';

import type { IConnection, ILanguage } from '@learned/types';

const StyledDropdown = styled(Dropdown)`
  width: 140px;
`;

export interface ILangItem {
  key: string;
  language: string;
  country: string;
  locale: string;
}

const NONE_LANG = {
  key: 'none',
  language: 'None',
};

const LanguagesBlock = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const lang = useSelector(getLang);
  const company = useSelector(getCurrentCompany);
  const connection = user.connections.find((c: IConnection) => c.company === company.id);
  const [prefLang, setPrefLang] = useState(connection?.preferredLang?.locale || 'none');

  const handleLocaleChange = async (lang: string) => {
    await updateUserProfile({ locale: lang });
    dispatch(changeLang(lang));
  };

  const handlePreferredLanguageChange = async (lang: string) => {
    setPrefLang(lang);
    const language = company.languages?.find((l: ILanguage) => l.locale === lang) || 'none';
    const connection = user.connections.find((c: IConnection) => c.company === company.id);
    connection.preferredLang = language;
    dispatch(changePreferredLang(connection));
  };

  const systemLangOptions = [SYSTEM_LANG.en, SYSTEM_LANG.nl].map((i) => i.key);
  const prefLangOptions = [...(company?.languages || []).map((l: ILanguage) => l.locale), 'none'];

  const PrefLangOptionComponent = ({
    item,
    isSelected,
    handleNewItemSelected,
  }: {
    item: string;
    isSelected: boolean;
    handleNewItemSelected: (item: ILangItem) => void;
  }) => {
    const langToRender = [...(company?.languages || []), NONE_LANG].find(
      (i) => i.key === item || i.locale === item,
    );
    return (
      <LangOption
        // @ts-ignore
        langToRender={langToRender}
        isSelected={isSelected}
        // @ts-ignore
        onClick={() => handleNewItemSelected(langToRender.key || langToRender.locale)}
      />
    );
  };

  const PrefLangSelectComponent = ({
    onClick,
    selectedItem,
    clicked,
  }: {
    clicked: boolean;
    onClick: () => void;
    selectedItem?: string;
  }) => {
    const langToRender = [...(company?.languages || []), NONE_LANG].find(
      (i) => i.key === selectedItem || i.locale === selectedItem,
    );
    return (
      <LangSelect
        onClick={onClick}
        // @ts-ignore
        $clicked={clicked}
        langToRender={langToRender as ILangItem}
      />
    );
  };

  const Content = (
    <ContentWrap>
      <LangRow>
        <TextCol>
          <Title>
            <Trans>System language</Trans>
          </Title>
          <Description>
            <Trans>The language of the Learned-platform</Trans>
          </Description>
        </TextCol>
        {/* @ts-ignore */}
        <StyledDropdown
          isSingleSelect
          // @ts-ignore
          selectedItem={lang}
          // @ts-ignore
          items={systemLangOptions}
          isSearchable={false}
          // @ts-ignore
          onChange={handleLocaleChange}
          // @ts-ignore
          CustomOptionComponent={SystemLangOptionComponent}
          // @ts-ignore
          CustomDropdownComponent={SystemLangSelectComponent}
        />
      </LangRow>
      <LangRow>
        <TextCol>
          <Title>
            <Trans>Preffered content language</Trans>
          </Title>
          <Description>
            <Trans>
              If multiple translations exist of reviews, surveys or learning activities we will
              first show the item in your prefered language
            </Trans>
          </Description>
        </TextCol>
        {/* @ts-ignore */}
        <StyledDropdown
          isSingleSelect
          // @ts-ignore
          selectedItem={prefLang}
          // @ts-ignore
          items={prefLangOptions}
          isSearchable={false}
          // @ts-ignore
          onChange={handlePreferredLanguageChange}
          // @ts-ignore
          CustomOptionComponent={PrefLangOptionComponent}
          // @ts-ignore
          CustomDropdownComponent={PrefLangSelectComponent}
        />
      </LangRow>
    </ContentWrap>
  );

  return <ProfileSettingsBlock header={<SectionHeader title="Languages" />} content={Content} />;
};

export { LanguagesBlock };
