import { ROLES } from '@learned/constants';
import FileSaver from 'file-saver';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getCompanySuperAdmin(id) {
  return cloudRequest(serverRoutes.companies.getCompanySuperAdmin, { id });
}

export function getCompaniesSuperAdmin() {
  return cloudRequest(serverRoutes.companies.getCompaniesSuperAdmin, {});
}

export function downloadCompaniesCSVSuperAdmin() {
  return cloudRequest(serverRoutes.companies.downloadCompaniesCSVSuperAdmin, {}).then(
    (response) => {
      FileSaver.saveAs(response, 'companies.csv');
    },
  );
}

export function downloadCompanyModulesCSVSuperAdmin() {
  return cloudRequest(serverRoutes.companies.downloadCompanyModulesCSVSuperAdmin, {}).then(
    (response) => {
      FileSaver.saveAs(response, 'companyModules.csv');
    },
  );
}

export function setCompanyEnabledSuperAdmin(companyId, enabled) {
  return cloudRequest(
    serverRoutes.companies.setCompanyEnabledSuperAdmin,
    { companyId },
    { enabled },
  );
}

export function deleteCompanyAllDataSuperAdmin(companyId) {
  return cloudRequest(serverRoutes.superAdmin.deleteCompany(companyId));
}

export function getCompany(id, populate) {
  return cloudRequest(serverRoutes.companies.getCompany, { id, populate });
}

export function getCompanies() {
  return cloudRequest(serverRoutes.companies.getCompanies, {});
}

export function getUserCompanies(filters = {}, options = {}) {
  const body = {
    filters: {
      search: filters.search,
    },
    options: {
      skip: options.skip,
      limit: options.limit,
      sortBy: options.sortBy,
    },
  };
  return cloudRequest(serverRoutes.companies.getUserCompanies, {}, body);
}

export function getCompanyActiveConnections() {
  return cloudRequest(serverRoutes.companies.getCompanyActiveConnections);
}

export function updateCompany(id, data) {
  return cloudRequest(serverRoutes.companies.updateCompany, { id }, data);
}

export function removeCompanyLogo(id) {
  return cloudRequest(serverRoutes.companies.removeCompanyLogo, { id });
}

export function updateCompanyLogo(id, logo) {
  return cloudRequest(
    serverRoutes.companies.updateCompanyLogo,
    { id },
    { logo },
    { sendAsFormData: true },
  );
}

export function updateCompanyLogoEmail(id, logoEmail) {
  return cloudRequest(
    serverRoutes.companies.updateCompanyLogoEmail(id),
    null,
    { logoEmail },
    { sendAsFormData: true },
  );
}

export function addCompanyLangs(companyId, languages) {
  return cloudRequest(serverRoutes.companies.addCompanyLanguages(companyId), {}, { languages });
}

export function removeCompanyLang(companyId, language) {
  return cloudRequest(serverRoutes.companies.removeCompanyLanguage(companyId), {}, { language });
}

export function updateCompanyPrimaryLang(companyId, language) {
  return cloudRequest(
    serverRoutes.companies.updateCompanyPrimaryLanguage(companyId),
    {},
    { language },
  );
}

export function createCompany(data) {
  return cloudRequest(serverRoutes.companies.createCompany, {}, data, { noLogin: true });
}

export function deleteCompanyAllData(companyId) {
  return cloudRequest(serverRoutes.companies.deleteCompanyAllData, {}, { company: companyId });
}

export function updateForceMFA(force_mfa) {
  return cloudRequest(serverRoutes.companies.updateForceMFA, {}, { force_mfa });
}

export function updateDefaultSSOMethod(defaultSSOMethod) {
  return cloudRequest(serverRoutes.companies.updateDefaultSSOMethod, {}, { defaultSSOMethod });
}

export function updateAddons(companyId, addons) {
  return cloudRequest(serverRoutes.companies.updateAddons(companyId), {}, { addons });
}

// only for outside pages
export function getCompanyOutsideOld(companyId) {
  return cloudRequest(serverRoutes.companies.getCompanyOutsideOld, { companyId });
}

// only for outside pages
export function getCompanyOutside(companyId) {
  return cloudRequest(
    serverRoutes.companies.getCompanyOutside(companyId),
    {},
    {},
    { noLogin: true },
  );
}

export function productsUpgrade({ products, companyId }) {
  return cloudRequest(serverRoutes.companies.productsUpgrade(companyId), {}, { products });
}

export function productsUpdate({ products, companyId }) {
  return cloudRequest(
    serverRoutes.companies.productsUpdate(companyId),
    {},
    { products },
    { forceRole: ROLES.ADMIN },
  );
}

export function updateSuperAdminProducts(companyId, products) {
  return cloudRequest(serverRoutes.companies.updateSuperAdminProducts(companyId), {}, { products });
}

export function importDemoContent(companyId) {
  return cloudRequest(serverRoutes.companies.superAdmin.importDemoContent(companyId));
}
