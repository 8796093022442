import React, { useEffect, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, SKILL_CATEGORIES_ID } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isNil } from 'lodash';
import { useForm } from 'react-hook-form';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import routes from '~/constants/routes';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import {
  deleteJobProfileCoverImage,
  deleteJobProfiles,
  updateJobProfile,
} from '~/services/jobProfiles';
import { getJobTemplate } from '~/services/jobTemplates';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { JobForm } from '../JobForm';
import { useJobProfile } from '../JobForm/hooks/useJobProfile';
import { useSkillCategories } from '../JobForm/hooks/useSkillCategories';
import { resolver } from '../JobForm/validations';
import {
  convertSkillsAPIDataToFormData,
  convertSkillsTemplateFormToAPIData,
  prepareVirtualJobLevelGroupId,
} from '../utils';

import type { IGeneralForm } from '../JobForm/types';
import type { IJobFamily, IJobTemplate, IMultiLangString } from '@learned/types';

const JobEdit = () => {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();

  const languageState = useLanguageState();

  const { skillCategories } = useSkillCategories();
  const { jobProfile, isLoading } = useJobProfile();
  const [jobTemplate, setJobTemplate] = useState<IJobTemplate>();

  const formMethods = useForm<IGeneralForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      name: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      description: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      recommendedJobs: [],
    },
  });
  const { setValue } = formMethods;

  useEffect(() => {
    const getTemplateWithSkills = async () => {
      // @ts-ignore
      const templateId = jobProfile?.jobTemplate?.id;
      if (templateId) {
        const result = await getJobTemplate(templateId, {
          options: { populate: ['skillTemplate'] },
        });

        setJobTemplate(result.data.jobTemplate as IJobTemplate);
      }
    };

    getTemplateWithSkills();
  }, [jobProfile?.jobTemplate]);

  useEffect(() => {
    let skills = {};

    skillCategories.forEach((category) => {
      const skillLevelsLength = category.skillLevels?.length || 0;

      const defaultFocusAreaLevel = [
        SKILL_CATEGORIES_ID.COMPETENCIES,
        SKILL_CATEGORIES_ID.KPIS,
      ].includes(category.type as SKILL_CATEGORIES_ID)
        ? 1
        : skillLevelsLength >= 3
        ? 3
        : skillLevelsLength;

      skills = {
        ...skills,
        [category.id]: {
          defaultFocusAreaLevel,
          isDefaultFocusAreaLevelEnabled: false,
          skills: [],
        },
      };
    });

    setValue('skills', skills);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillCategories]);

  useEffect(() => {
    if (jobTemplate) {
      setValue('name', turnMultiLangIntoArray(jobTemplate.name, languageState.companyLanguages));
      setValue(
        'description',
        turnMultiLangIntoArray(jobTemplate.description.default, languageState.companyLanguages),
      );
      setValue('coverImage', jobTemplate?.coverImage);
      setValue('jobFamily', (jobTemplate?.jobFamily as IJobFamily)?.id);
    }
    if (jobProfile) {
      setValue('id', jobProfile.id);
      setValue('name', turnMultiLangIntoArray(jobProfile.name, languageState.companyLanguages));

      if (jobProfile?.description) {
        setValue(
          'description',
          turnMultiLangIntoArray(jobProfile.description, languageState.companyLanguages),
        );
      }

      setValue('coverImage', jobProfile?.coverImage || '');

      if (jobProfile?.jobLevelGroup) {
        setValue('jobLevelGroup', {
          groupId: jobProfile?.jobLevelGroup.id,
          level: jobProfile?.jobLevelGroup.level,
          id: prepareVirtualJobLevelGroupId(jobProfile.jobLevelGroup),
        });
      }

      setValue('careerLevel', jobProfile?.careerLevel?.toString());
      setValue('educationLevel', jobProfile?.educationLevel?.toString());

      setValue(
        'recommendedJobs',
        (jobProfile?.recommendedJobs as unknown as { name: IMultiLangString; id: string }[])?.map(
          (item) => ({
            name: item.name,
            id: item.id,
          }),
        ),
      );

      setValue('jobFamily', jobProfile.jobFamily as string);

      setValue(
        'skills',
        // @ts-ignore
        convertSkillsAPIDataToFormData(jobProfile.skills, skillCategories),
      );
    }
  }, [jobProfile, jobTemplate, languageState.companyLanguages, setValue, skillCategories]);

  const jobTemplateName = formMethods
    .watch('name')
    .find(({ locale }) => locale === languageState.companyPrimaryLanguage.locale)?.value;

  const title = i18n._(t`Edit job: ${jobTemplateName}`);

  const onSubmit = async (data: IGeneralForm & { published?: boolean }) => {
    try {
      const predicate = ({ value, locale }: { value: string; locale: string }) =>
        value !== '' || locale === '_id';
      let jobLevelGroup;

      if (data.jobLevelGroup?.groupId && !isNil(data.jobLevelGroup?.level)) {
        jobLevelGroup = {
          id: data.jobLevelGroup?.groupId,
          level: data.jobLevelGroup?.level,
        };
      }

      const result = await updateJobProfile(jobProfile?.id, {
        name: convertLanguageValue(data.name.filter(predicate)),
        skills: convertSkillsTemplateFormToAPIData(data.skills),
        description: convertLanguageValue(data.description),
        jobFamily: data.jobFamily,
        careerLevel: data.careerLevel,
        educationLevel: data.educationLevel,
        coverImage: data.coverImage,
        published: data.published,
        recommendedJobs: data.recommendedJobs.map(({ id }) => id),
        jobLevelGroup,
      });
      if (result.code === 200 && result.data.jobProfile.published) {
        addToast({
          title: i18n._(t`Success`),
          subtitle: i18n._(t`Job profile published`),
          type: TOAST_TYPES.SUCCESS,
        });
      } else if (result.code === 200 && result.data.jobProfile.published === false) {
        addToast({
          title: i18n._(t`Success`),
          subtitle: i18n._(t`Job profile saved as draft`),
          type: TOAST_TYPES.SUCCESS,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      goBack();
    }
  };

  const onDeleteItem = async () => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete?`),
      description: i18n._(t`Are you sure you want to delete the job? This cannot be undone.`),
    });
    if (isConfirmed) {
      try {
        await deleteJobProfiles([jobProfile?.id]);
        addToast({
          title: i18n._(t`Successfully deleted`),
          type: TOAST_TYPES.INFO,
        });
        routes.JOB_PROFILES_ADMIN.go();
      } catch (error) {
        addToast({
          title: i18n._(t`Oops... Something went wrong`),
          subtitle: i18n._(t`Try again later!`),
          type: TOAST_TYPES.ERROR,
        });
      }
    }
  };

  const handleDeleteCoverImage = async () => {
    if (jobProfile) {
      await deleteJobProfileCoverImage(jobProfile.id);
    }
  };

  return (
    <JobForm
      title={title}
      onSubmit={onSubmit}
      formMethods={formMethods}
      languageState={languageState}
      jobTemplate={jobTemplate}
      skillCategories={skillCategories}
      isEdit={true}
      onDelete={onDeleteItem}
      onCoverImageDelete={handleDeleteCoverImage}
      published={jobProfile?.published}
      isLoading={isLoading}
    />
  );
};

export { JobEdit };
