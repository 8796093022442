import React, { useState } from 'react';

import {
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_RATING_STATUS,
  REVIEW_RATING_TYPE,
} from '@learned/constants';
import { IUser, IUserReviewQuestionRating } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { StyledRickTextView } from '~/components/SurveyQuestionComments/design';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import { COLORS } from '~/styles';

import { NotSharedLabel } from '../NotSharedLabel';
import {
  Answer,
  AnswerContainer,
  AnswerPlaceholder,
  AnswerWrapper,
  RatingWrapper,
  Row,
  UserAvatarContainer,
  UserAvatarName,
  UserContainer,
  Rating,
  Oval,
  Arrow,
  ExpandedWrapper,
  Label,
  Comment,
  IconWrapper,
} from '../RatingAnswers/design';

import type { IQuestionDefaultData } from '../../../../types';

interface IAvatarWrapperProps {
  id?: string;
  email?: string;
  oldAnswer?: string;
  reviewType: string;
}

const AvatarWrapper = ({ id, email, oldAnswer, reviewType }: IAvatarWrapperProps) => {
  return (
    <UserAvatarContainer $answered={!!(oldAnswer && Number(oldAnswer) !== -1)}>
      {id ? <UserAvatar userId={id} /> : <UserAvatar user={{ email: email! } as IUser} />}{' '}
      <UserAvatarName>
        (<Trans>{reviewType}</Trans>)
      </UserAvatarName>
    </UserAvatarContainer>
  );
};

function RatingAnswersV1({
  question,
  questionData,
  useMultiLangString,
  openFirstAnswerByDefault,
}: {
  question: IQuestionDefaultData;
  questionData: IUserReviewQuestionRating;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  openFirstAnswerByDefault?: boolean;
}) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const [answers, setAnswers] = useState(
    [
      ...(question.otherCoachRatings?.map((oldAnswer) => ({
        ...oldAnswer,
        isOpened: false,
        reviewType: REVIEW_QUESTION_EVALUATORS.COACH,
      })) ?? []),
      ...(question.otherSelfRating
        ? [{ ...question.otherSelfRating, reviewType: 'self review', isOpened: false }]
        : []),
      ...(question.otherPeerRatings?.map((oldAnswer) => ({
        ...oldAnswer,
        isOpened: false,
        reviewType: REVIEW_QUESTION_EVALUATORS.PEER,
      })) ?? []),
    ].map(
      openFirstAnswerByDefault
        ? (item, index) => (index === 0 ? { ...item, isOpened: true } : item)
        : (item) => item,
    ),
  );

  const options = questionData.settings?.options;

  const onClick = (questionId: number) => {
    setAnswers(
      answers.map((oldAnswer, index) => {
        if (questionId === index) {
          return {
            ...oldAnswer,
            isOpened: !oldAnswer.isOpened,
          };
        }
        return oldAnswer;
      }),
    );
  };

  const getRatingColor = (type: REVIEW_RATING_TYPE) => {
    switch (type) {
      case REVIEW_RATING_TYPE.SELF:
        return {
          dark: COLORS.REVIEW_SELF,
          light: COLORS.SELF_REVIEW_LIGHT,
          default: COLORS.SELF_REVIEW,
        };
      case REVIEW_RATING_TYPE.COACH:
        return {
          dark: COLORS.REVIEW_COACH,
          light: COLORS.COACH_REVIEW_LIGHT,
          default: COLORS.COACH_REVIEW,
        };
      case REVIEW_RATING_TYPE.PEER:
      case REVIEW_RATING_TYPE.PEER_EMAIL:
        return {
          dark: COLORS.PEER_REVIEW_DARK,
          light: COLORS.PEER_REVIEW_LIGHT,
          default: COLORS.PEER_REVIEW,
        };
    }
  };

  const calculateDistanceFromRightToCenter = (index: number, totalDivs: number, gap = 5) => {
    let actualDivWidth = 0;
    if (totalDivs === 2) {
      actualDivWidth = 60;
    } else if (totalDivs === 3) {
      actualDivWidth = 40;
    } else if (totalDivs === 4) {
      actualDivWidth = 28;
    } else {
      actualDivWidth = 20;
    }
    const remainingDivsWidth = (totalDivs - index - 1) * (actualDivWidth + gap);

    const distanceToCenter = remainingDivsWidth + actualDivWidth / 2;

    return distanceToCenter;
  };

  return (
    <>
      {answers?.map((rating, index) => {
        const ratingColor = getRatingColor(rating.type!);
        return (
          <Row key={index}>
            <AnswerContainer
              clickable
              onClick={() => onClick(index)}
              $answered={!!rating?.oldAnswer}
            >
              <UserContainer>
                <Icon
                  transform={rating.isOpened ? 'rotate(90)' : 'rotate(0)'}
                  size={ICON_SIZES.MEDIUM}
                  icon={ICONS.NEXT}
                  color={rating.isOpened ? COLORS.COMPANY : COLORS.INACTIVE}
                />
                <div>
                  <AvatarWrapper
                    key={index}
                    id={rating.createdBy?.id}
                    oldAnswer={rating?.oldAnswer as string}
                    email={rating.createdBy?.email}
                    reviewType={rating.reviewType}
                  />
                </div>
              </UserContainer>

              <ToolTip
                key={index}
                placement={TOOLTIP_PLACEMENTS.TOP}
                tooltip={i18n._(t`This input is not yet shared with the employee`)}
              >
                <IconWrapper $answered={!!rating?.oldAnswer}>
                  {rating.status === REVIEW_RATING_STATUS.CALIBRATE &&
                    question?.yourRating?.type === REVIEW_RATING_TYPE.COACH && <NotSharedLabel />}
                </IconWrapper>
              </ToolTip>
              <RatingWrapper
                $answered={!!rating?.oldAnswer}
                maxWidth={options.length > 4 ? options.length * 25 : 130}
              >
                {options.map((_, index) => (
                  <Rating
                    key={index}
                    selected={index + 1 === rating.oldAnswer}
                    default={ratingColor.default}
                    dark={ratingColor.dark}
                  >
                    {index + 1 === rating.oldAnswer && <Oval />}
                  </Rating>
                ))}
              </RatingWrapper>
            </AnswerContainer>
            {rating.isOpened && (
              <AnswerWrapper>
                {rating?.oldAnswer && Number(rating?.oldAnswer) >= 0 ? (
                  <ExpandedWrapper>
                    <Arrow
                      background={ratingColor.light}
                      marginRight={calculateDistanceFromRightToCenter(
                        Number(rating.oldAnswer) - 1,
                        options.length,
                      )}
                    />
                    <Answer background={ratingColor.light}>
                      <Label>
                        <Trans>
                          [{rating.oldAnswer}/{options.length}] -{' '}
                          {getMultiLangString(options[Number(rating.oldAnswer) - 1]?.label ?? '')}
                        </Trans>
                      </Label>
                    </Answer>
                    {rating.comment && (
                      <Comment>
                        <StyledRickTextView html={getMultiLangString(rating.comment || '')} />
                      </Comment>
                    )}
                  </ExpandedWrapper>
                ) : (
                  <AnswerPlaceholder>
                    {rating?.oldAnswer === null ? (
                      <Trans>Answered this question with N/A</Trans>
                    ) : (
                      <Trans>No oldAnswer provided yet</Trans>
                    )}
                  </AnswerPlaceholder>
                )}
              </AnswerWrapper>
            )}
          </Row>
        );
      })}
    </>
  );
}

export { RatingAnswersV1 };
