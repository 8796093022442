import React from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ImageWithAlt } from '~/components/ImageWithAlt';
import Modal from '~/components/Modal';
import RickTextView from '~/components/RickTextView';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import {
  ActionContainer,
  Container,
  Description,
  Footer,
  Header,
  Image,
  Title,
  TitleContainer,
} from './design';

import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getJobProfile } from '~/services/jobProfiles';
import { COLORS } from '~/styles';
import { convertMarkdownStarsToStrong } from '~/utils/markdown';

import type { IJobProfile } from '@learned/types';

export const JobProfileViewModal = ({
  jobProfileId,
  onClose,
}: {
  jobProfileId: string;
  onClose: () => void;
}) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const [jobProfile, isLoading] = useAsyncFetch<IJobProfile | undefined>(
    {
      fetch: async () => {
        const response = await getJobProfile(jobProfileId);
        return response.data?.[API_RETURN_FIELDS.JOB_PROFILE];
      },
      initialState: undefined,
    },
    [jobProfileId],
  );

  return (
    <Modal
      showDivider={false}
      centerModal
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      borderRadius={6}
      hideFooter
      isHideHeader
    >
      <ShowSpinnerIfLoading loading={!jobProfile || isLoading} height="557px">
        <Header>
          <TitleContainer>
            <Title>{getMultiLangString(jobProfile?.name || '')}</Title>
          </TitleContainer>
          <ActionContainer>
            <Button size={ButtonSize.MEDIUM} onClick={onClose} variant={ButtonVariant.CLOSE} />
          </ActionContainer>
        </Header>

        <Container>
          {jobProfile?.coverImage && (
            <Image>
              <ImageWithAlt
                src={jobProfile.coverImage}
                alt={getMultiLangString(jobProfile?.name).substring(0, 2)}
              />
            </Image>
          )}

          {jobProfile?.description && (
            <Description>
              {/* @ts-ignore */}
              <RickTextView
                html={convertMarkdownStarsToStrong(getMultiLangString(jobProfile.description))}
                color={COLORS.TEXT_MAIN}
              />
            </Description>
          )}
        </Container>

        <Footer>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
        </Footer>
      </ShowSpinnerIfLoading>
    </Modal>
  );
};
