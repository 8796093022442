import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Settings = styled.div<{ $showErrorBorder?: boolean }>`
  padding: 10px 0 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${({ $showErrorBorder }) =>
    $showErrorBorder
      ? css`
          border-top: ${COLORS.ACCENT_ERROR} 1px solid;
          border-bottom: ${COLORS.ACCENT_ERROR} 1px solid;
        `
      : ''}}
`;

export const Button = styled.button<{ hoverBackgroundColor?: string; hoverTextColor?: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--company-color);
  cursor: pointer;
  &:hover:enabled {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor || COLORS.BG_PAGE};
    ${({ hoverTextColor }) =>
      hoverTextColor &&
      css`
        color: ${hoverTextColor};
      `};
  }
  padding: 7px 10px 7px 13.1px;
  margin-bottom: -8px;
  border-radius: 10rem;
`;
/* padding: ${MAX_PADDING}; */
/* background-color: ${(props) => props.$hoverColor || COLORS.BG_PAGE}; */

export const Title = styled.div`
  color: ${COLORS.TEXT_HOVER};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.17;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.86;
  color: ${COLORS.INPUT_TITLE};
`;

export const SettingItem = styled.div<{ isActive?: boolean }>`
  display: flex;

  color: ${COLORS.PLACEHOLDER_LIGHT};
  font-size: 14px;
`;

export const GeneralSettings = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.86;
  color: ${COLORS.INPUT_TITLE};
`;

export const ErrorText = styled.div`
  height: 31px;
  font-size: 14px;
  color: ${COLORS.ACCENT_ERROR};
`;

export const ToggleWrapper = styled.div`
  width: fit-content;
`;

// Warning Section Container
export const WarningSection = styled.div`
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Warning Text
export const WarningText = styled.div`
  font-size: 14px;
  color: ${COLORS.ACCENT_ERROR}; /* Error color */
  padding-left: 8px;
`;

// More Information Link
export const MoreInfoLink = styled.a`
  margin-left: 8px;
  font-size: 14px;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
