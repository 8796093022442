import styled from 'styled-components';

import { COLORS } from '~/styles';

export const RatingsGridWrapper = styled.div`
  & tr:last-of-type {
    td:not(:last-of-type) {
      div {
        background-color: ${COLORS.BG_ELEMENTS};
      }
    }

    td:first-of-type {
      div {
        background-color: ${COLORS.BG_ELEMENTS};

        & button {
          opacity: 0;
          pointer-events: none;
          cursor: pointer;
        }
      }
    }
  }
`;

export const CellWrapper = styled.div<{ clickable: boolean; $margin?: string; $padding?: string }>`
  display: flex;
  align-items: center;
  height: 60px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  background: ${COLORS.BG_LIST};
  margin: ${({ $margin }) => $margin || 'auto'};
  padding: ${({ $padding }) => $padding || 'auto'};
`;

export const CellLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: ${COLORS.SUBTEXT};
    font-size: 12px;
  }
`;

export const CellContent = styled.div`
  height: auto;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const OneLineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CellViewWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.BG_LIST};
`;

export const PeerLabel = styled.span`
  color: ${COLORS.PEER_REVIEW_HEADER};
`;

export const CoachLabel = styled.span`
  color: ${COLORS.COACH_REVIEW};
`;

export const SelfLabel = styled.span`
  color: ${COLORS.SELF_REVIEW};
`;

export const CountLabel = styled.span`
  color: ${COLORS.PLACEHOLDERS};
  font-weight: 400;
`;
