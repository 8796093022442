import React, { useEffect } from 'react';

import { IUserReview } from '@learned/types';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Signatures } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Signatures';
import { IReviewDashboardUserForm } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/types';

import { getUsers } from '~/selectors/baseGetters';

const SignaturesPDFView = ({ userReview }: { userReview: IUserReview }) => {
  // lets create a blank form to pass to the signature method,
  const formMethods = useForm<IReviewDashboardUserForm>({
    mode: 'all',
    defaultValues: {
      guests: [],
      coaches: [],
      signatures: [],
    },
  });

  const users = useSelector(getUsers);
  const employee = users[userReview?.createdFor];

  useEffect(() => {
    // fill necessary values nessasary for signature section
    formMethods.setValue('employee', employee);
    formMethods.setValue('guests', userReview.guests);
    formMethods.setValue('signatures', userReview.signatures);
    formMethods.setValue('status', userReview.status);
  }, [employee, formMethods, userReview.guests, userReview.signatures, userReview.status]);

  return (
    <Signatures
      formMethods={formMethods as UseFormReturn<IReviewDashboardUserForm>}
      isAllowToSign={() => false}
      onSign={() => {}}
      onReset={() => {}}
      showResetAll={false}
      isPDFView
    />
  );
};

export default SignaturesPDFView;
