import React, { useState, useEffect } from 'react';

import _ from 'lodash';

import { MultiSelectDropdown } from '~/components/Dropdown/components/MultiSelectDropdown';
import type { DropdownMultiSelectProps } from '~/components/Dropdown/types';
import { TFacetOption } from '~/pages/Reports/types';

const DROPDOWN_ITEM_LIMIT = 7;

function FiltersMultiSelectDropdown({
  selectedItems,
  onChange,
  placeholder,
  items,
  reportId,
}: DropdownMultiSelectProps<TFacetOption> & {
  reportId: string | null;
}) {
  const [options, setOptions] = useState<TFacetOption[]>([]);
  const [search, setSearch] = useState('');

  const isSearchable = items?.length > 7;

  useEffect(() => {
    if (!search) {
      const filtered = _.chain(items).take(DROPDOWN_ITEM_LIMIT).value();
      setOptions(filtered);
    } else {
      const filtered = _.chain(items)
        .filter((item) => item.title.toLowerCase().includes(search.toLowerCase()))
        .take(DROPDOWN_ITEM_LIMIT)
        .value();

      setOptions(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, items, search]);

  return (
    <MultiSelectDropdown
      placeholder={placeholder}
      items={options}
      renderItem={(item) => item.title}
      isSearchable={isSearchable}
      selectedItems={selectedItems}
      onSearchChange={setSearch}
      onChange={onChange}
      hashItem={(item) => item.key}
      stringifyItem={(item) => item.title}
    />
  );
}

export { FiltersMultiSelectDropdown };
