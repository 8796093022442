import styled from 'styled-components';

import { COLORS } from '~/styles';

const TableWrapper = styled.table`
  thead {
    tr {
      height: 64px;
      th {
        font-weight: 600;
        color: ${COLORS.DROPDOWN_ICON};
        padding: 16px;
        text-align: left;
        font-size: 12px;
        text-transform: uppercase;
        padding-bottom: 16px;
      }
    }

    @media print {
      display: table-row-group; /* Prevent header repetition */
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background-color: ${COLORS.BG_LIST};
      }
      height: 64px;
      border: 1px solid black;
      td {
        padding: 0px 24px;
        font-size: 14px;
        color: ${COLORS.ICONS_PRIMARY};
        position: relative;
        text-align: left;
        margin: 8px 14px;
      }
    }
  }

  @media print {
    page-break-before: avoid;
  }
`;

export { TableWrapper };
