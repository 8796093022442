import React, { useState, useEffect, useMemo } from 'react';

import { CAREER_PLAN_STATUSES, JOB_PROFILE_STATUSES, RESPONSE_STATUSES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';
import filter from 'lodash/filter';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import CleanUpMembersModal from '~/components/CleanUpMembersModal';
import { confirm } from '~/components/ConfirmDialog';
import DropdownButton from '~/components/DropdownButton';
import FiltersHeading from '~/components/FiltersHeading';
import HeadingNavigation from '~/components/HeadingNavigation';
import IconMenu from '~/components/IconMenu';
import ExpandMoreIcon from '~/components/Icons/ExpandMore';
import InviteUsersModal from '~/components/InviteUsersModal';
import PaginationBar from '~/components/PaginationBar';
import SearchSelectButton from '~/components/SearchSelectButton';
import SelectModal from '~/components/SelectModal';
import SyncMembersModal from '~/components/SyncMembersModal';
import Tabs from '~/components/Tabs';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import ActionsContainer from '~/components/UI/ActionsContainer';
import AvatarCard from '~/components/UI/AvatarCard';
import Divider from '~/components/UI/Divider';
import TableCard, { TableRow, TableCol } from '~/components/UI/TableCard';
import TabsContainer from '~/components/UI/TabsContainer';
import BaseLayout from '~/layouts/BaseLayout';
import AmbitionsColumn from '~/pages/CoachMembersPage/components/AmbitionsColumn';
import { TABS_ENUM as PASSPORT_TABS_ENUM } from '~/pages/PassportPage';

import MemberOptions from './components/MembersOptions';

import {
  ROLES,
  CONNECTION_STATUSES,
  INVITE_STATUSES,
  INTEGRATIONS_CONN_ERROR_MSG,
} from '~/constants';
import FILTERS from '~/constants/filters';
import { INSTRUCTIONS } from '~/constants/instructions';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import {
  checkModuleCareer,
  getCompanyConnections,
  getUser,
  isModuleAmbitionsEnabled as checkModuleAmbitions,
} from '~/selectors/baseGetters';
import getAllUsers from '~/selectors/getAllUsers';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getCareerPlans } from '~/services/careerPlans';
import {
  getUsersFromIntegration,
  syncMembersFromCompanyIntegrations,
} from '~/services/integrations';
import { getCompanyIntegrationSettings } from '~/services/integrationSettings';
import * as inviteService from '~/services/invites';
import { getJobProfiles } from '~/services/jobProfiles';
import { getCompanyLikes } from '~/services/likes';
import { getTeams } from '~/services/teams';
import {
  updateAdminStatus as updateAdminStatusService,
  switchUserDisabled as switchUserDisabledService,
  changeOwnership as changeOwnershipService,
  disconnectUserFromIntegration as disconnectUserFromIntegrationService,
  connectUserToIntegration as connectUserToIntegrationService,
  resendWelcomeApiEmail as resendWelcomeApiEmailService,
  resetMFA,
  downloadMembersCSV,
} from '~/services/users';
import { updateCompanyConnection } from '~/store/companyConnections/actions';
import { finishGetTeams } from '~/store/teams/actions';
import * as userActions from '~/store/users/actions';
import { finishSetInactiveUser, finishRemoveInactiveUser } from '~/store/users/actions';
import { COLOR_PALETTE, COLOR_SET } from '~/styles';
import getCoachTeams from '~/utils/getCoachTeams';
import getInstructionUrl from '~/utils/getInstructionUrl';
import getModulesStatuses from '~/utils/getModulesStatuses';
import getUserFullName from '~/utils/getUserFullName';
import getUserPublicProfileURL from '~/utils/getUserPublicProfileURL';
import getUserTeams from '~/utils/getUserTeams';

const ColText = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  height: 22px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const Circle = styled.div`
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-top: 4px;
  cursor: pointer;
`;

const EmailCol = styled(TableCol)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LinkWrapper = styled.div`
  background-color: white;
  height: 100%;
  a {
    text-decoration: inherit;
  }
  a:hover {
    text-decoration: inherit;
  }
`;

const List = styled.ul`
  margin: 0;
  padding-left: 18px;
  word-break: break-word;

  & > li {
    color: ${COLOR_PALETTE.DARK_GRAY};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 4px;
  }

  & > li:last-child {
    margin-bottom: 0;
  }
`;

const TabsWrapper = styled(Tabs)`
  margin-bottom: 0;
  border: none;
`;

const StickyContainer = styled.div`
  height: 100%;
  background-color: ${COLOR_PALETTE.WHITE};
  ${(props) => {
    if (props.left) {
      return 'padding-left: 16px;';
    }
    if (props.right) {
      return 'padding-right: 16px;';
    }
  }};
`;

const rightsFilters = map(FILTERS.MEMBERS_RIGHTS.data);
const profileActivationFilters = map(FILTERS.PROFILE_ACTIVATION.data);

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const filterInvites = (invites, users) => {
  // Hide invites for active company users
  return filter(
    invites,
    (companyInvite) => !users.some((user) => user.id === companyInvite.toUser),
  );
};

const PAGE_SIZE = 50;

const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };

const TABS_ENUM = { active: 'active', invited: 'invited', inactive: 'inactive' };

const AdminMembersPage = () => {
  const dispatch = useDispatch();
  const { i18n } = useLingui();
  const { addToast } = useToasts();

  const [careerPlans, setCareerPlans] = useState({});
  const [checkedProfileActivationFilters, setCheckedProfileActivationFilters] = useState([]);
  const [checkedRightsFilters, setCheckedRightsFilters] = useState([]);
  const [currentTab, setCurrentTab] = useState(TABS_ENUM.active);
  const [integrationSettings, setIntegrationSettings] = useState([]);
  const [invites, setInvites] = useState({});
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isIntegrationError, setIsIntegrationError] = useState(false);
  const [isLoadingUsersData, setIsLoadingUsersData] = useState(true);
  const [isShowConnectToIntegrationsModal, setIsShowConnectToIntegrationsModal] = useState(false);
  const [isShowCleanUpMembersModal, setIsShowCleanUpMembersModal] = useState(false);
  const [isShowInviteModal, setIsShowInviteModal] = useState(false);
  const [isShowSyncMembersModal, setIsShowSyncMembersModal] = useState(false);
  const [isUsersFromIntegrationsLoading, setIsUsersFromIntegrationsLoading] = useState(false);
  const [items, setItems] = useState({ active: [], invited: [], inactive: [] });
  const [jobProfiles, setJobProfiles] = useState({});
  const [memberFilter, setMemberFilter] = useState(() => Boolean);
  const [modulesStatuses, setModulesStatuses] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [selectedMember, setSelectedMember] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [teams, setTeams] = useState(null);
  const [companyLikes, setCompanyLikes] = useState({});
  const [usersFromIntegrations, setUsersFromIntegrations] = useState([]);
  const isModuleAmbitionsEnabled = useSelector(checkModuleAmbitions);
  const isCareerEnabled = useSelector(checkModuleCareer);

  const companyConnections = useSelector(getCompanyConnections);
  const currentCompany = useSelector(getCurrentCompany);
  const currentUser = useSelector(getUser);
  const users = useSelector(getAllUsers);

  const getMultiLangString = useMultiLangString();

  const isAmbitionsEnabled = useMemo(
    () => isModuleAmbitionsEnabled && isCareerEnabled,
    [isCareerEnabled, isModuleAmbitionsEnabled],
  );

  useEffect(() => {
    Promise.all([
      getCareerPlans({}, { populate: ['jobProfile'] }),
      getJobProfiles(),
      inviteService.getInvites(INVITE_STATUSES.SENT.key),
      getTeams(),
      getCompanyIntegrationSettings(),
      getCompanyLikes(),
    ]).then(
      ([
        careerPlans,
        jobProfiles,
        invites,
        teams,
        integrationSettingsList,
        fetchedCompanyLikes,
      ]) => {
        dispatch(finishGetTeams(teams)); // Update teams in redux-store for InviteModal

        setCareerPlans(careerPlans);

        // Override correct multi lang strings to prevent having to get it on every usage
        Object.values(careerPlans).forEach((cp) => {
          if (cp.jobProfile?.name) {
            cp.jobProfile.name = getMultiLangString(cp.jobProfile.name);
          }
        });
        Object.values(jobProfiles).forEach((jp) => {
          jp.name = getMultiLangString(jp.name);
        });
        setJobProfiles(jobProfiles);
        setInvites(invites);
        setTeams(teams);
        setIntegrationSettings(Object.values(integrationSettingsList || {}));
        setModulesStatuses(getModulesStatuses(integrationSettingsList));
        setIsLoadingUsersData(false);
        setCompanyLikes(fetchedCompanyLikes);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingUsersData || isInitialLoad) {
      if (isInitialLoad) {
        setIsInitialLoad(false);
      }

      const usersInCompany = Object.values(users);

      const companyInvites = filterInvites(invites, usersInCompany).map((i) => ({
        ...i,
        status: CONNECTION_STATUSES.WAITING.key,
        isInvite: true,
      }));

      const items = usersInCompany
        .concat(companyInvites)
        .filter(memberFilter)
        .filter((u) => {
          if (
            checkedProfileActivationFilters.length === 0 ||
            checkedProfileActivationFilters.length === 2
          ) {
            return true;
          }
          return checkedProfileActivationFilters.includes(FILTERS.PROFILE_ACTIVATION.data.YES.id)
            ? u.completedFirstTimeSetup
            : !u.completedFirstTimeSetup;
        })
        .filter((u) => {
          if (checkedRightsFilters.length === 0) {
            return true;
          }

          let roles = [ROLES.USER];
          if (u.uid) {
            const conn = Object.values(companyConnections).find((conn) => conn.user === u.id) || {};
            roles = roles.concat(conn.roles);
          } else {
            roles = roles.concat(u.roles);
          }
          return intersection(roles, checkedRightsFilters).length > 0;
        });

      const members = { active: [], invited: [], inactive: [] };

      items.forEach((item) => {
        if (item.status === CONNECTION_STATUSES.ACTIVE.key) {
          members.active.push(item);
        } else if (item.status === CONNECTION_STATUSES.WAITING.key) {
          members.invited.push(item);
        } else if (item.status === CONNECTION_STATUSES.INACTIVE.key) {
          members.inactive.push(item);
        }
      });

      // Set tabs before pagination
      const newTabs = [
        { label: t`Active (${members.active.length})`, key: TABS_ENUM.active },
        { label: t`Invited (${members.invited.length})`, key: TABS_ENUM.invited },
        { label: t`Inactive (${members.inactive.length})`, key: TABS_ENUM.inactive },
      ];

      setTabs(newTabs);
      setItems(members);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkedProfileActivationFilters,
    checkedRightsFilters,
    companyConnections,
    invites,
    isLoadingUsersData,
    memberFilter,
    users,
  ]);

  const onShowInviteModal = (e) => {
    e.preventDefault();
    setIsShowInviteModal(true);
  };

  const showWarningToast = () => {
    addToast({
      title: i18n._(t`Warning`),
      subtitle: i18n._(t`Due to an internal error, some of the users may not have been invited`),
      type: TOAST_TYPES.ERROR,
    });
  };

  const onHideInviteModal = async (error, isDontRefresh) => {
    setIsShowInviteModal(false);

    if (isDontRefresh) {
      return;
    }

    // Show warning toast if there was a user creation error handled on server
    if (error) {
      showWarningToast();
    }

    setIsLoadingUsersData(true);

    await Promise.all([
      getCareerPlans({ status: CAREER_PLAN_STATUSES.CURRENT }, { populate: ['jobProfile'] }),
      getJobProfiles(),
      getTeams(),
      dispatch(userActions.getUsers()),
      dispatch(userActions.fetchUserData()),
      inviteService.getInvites(INVITE_STATUSES.SENT.key),
    ]).then(([careerPlans, jobProfiles, teams, _users, _fetchedUsers, invites]) => {
      setCareerPlans(careerPlans);
      // Override correct multi lang strings to prevent having to get it on every usage
      Object.values(careerPlans).forEach((cp) => {
        if (cp.jobProfile?.name) {
          cp.jobProfile.name = getMultiLangString(cp.jobProfile.name);
        }
      });
      Object.values(jobProfiles).forEach((jp) => {
        jp.name = getMultiLangString(jp.name);
      });
      setJobProfiles(jobProfiles);
      setTeams(teams);
      setInvites(invites);
      setIsLoadingUsersData(false);
    });
  };

  const updateAdminStatus = async (selectedUser, isAdmin) => {
    // confirm
    const confirmMessageMakeAdmin = i18n._(t`Are you sure you want to make this user admin?`);
    const confirmMessageRevokeAdmin = i18n._(
      t`Are you sure want to remove this user as admin of ALL his teams?`,
    );
    if (!(await confirm(i18n, isAdmin ? confirmMessageMakeAdmin : confirmMessageRevokeAdmin))) {
      return;
    }

    const connection = Object.values(companyConnections).find(
      (conn) => conn.user === selectedUser.id,
    );
    if (!connection) {
      return;
    }

    await updateAdminStatusService(selectedUser.id, isAdmin);

    // Local update for speed
    if (isAdmin) {
      connection.roles = uniq(connection.roles.concat([ROLES.ADMIN]));
    } else {
      connection.roles = connection.roles.filter((role) => role !== ROLES.ADMIN);
    }
    dispatch(updateCompanyConnection(connection));
  };

  const deleteUserFromCompany = async (selectedUser) => {
    // Confirm
    if (await confirm(i18n, i18n._(t`Are you sure want to remove this user from company?`))) {
      dispatch(userActions.deleteUserFromCompany(selectedUser.id));
    }
  };

  const changeOwnership = async (selectedUser) => {
    // Confirm
    const confirmMessageMakeAdmin = i18n._(
      t`Are you sure? You are about to transfer the company owner role to another member`,
    );

    if (!(await confirm(i18n, confirmMessageMakeAdmin))) {
      return;
    }

    const connection = Object.values(companyConnections).find(
      (conn) => conn.user === selectedUser.id,
    );
    if (!connection) {
      return;
    }

    const updatedUserConnections = await changeOwnershipService(selectedUser.id);

    Object.values(updatedUserConnections).map((userConn) =>
      dispatch(updateCompanyConnection(userConn)),
    );
    dispatch(userActions.fetchUserData());
  };

  const deleteMembers = async (membersToDelete) => {
    if (!isEmpty(membersToDelete)) {
      const membersIds = membersToDelete.map((member) => member.user);
      await dispatch(userActions.deleteUsers(membersIds));

      setIsShowCleanUpMembersModal(false);
    }
  };

  const switchUserDisabled = async (selectedUser, disabled) => {
    // Confirm
    const confirmTextEnable = i18n._(t`Are you sure want to enable this user?`);
    const confirmTextDisable = i18n._(t`Are you sure want to disable this user?`);
    if (await confirm(i18n, disabled ? confirmTextDisable : confirmTextEnable)) {
      const updatedUserConnection = await switchUserDisabledService(selectedUser.id, disabled);
      if (!isEmpty(updatedUserConnection)) {
        dispatch(updateCompanyConnection(updatedUserConnection));

        updatedUserConnection.status === CONNECTION_STATUSES.ACTIVE.key
          ? dispatch(finishRemoveInactiveUser(selectedUser))
          : dispatch(finishSetInactiveUser(selectedUser));
        dispatch(userActions.getUsers());
      }
    }
  };

  const updateInvite = async (invite, update) => {
    await inviteService.updateInviteDetails(invite.id, update);

    // Reload invites
    const invites = await inviteService.getInvites(INVITE_STATUSES.SENT.key);
    setInvites(invites);
  };

  const cancelInvite = async (invite) => {
    await inviteService.cancelInvite(invite.id);

    // Reload invites
    const invites = await inviteService.getInvites(INVITE_STATUSES.SENT.key);
    setInvites(invites);
  };

  const resendInvite = (invite) => {
    const loginUrl = window.location.origin;
    inviteService
      .resendInvite(invite.id, loginUrl)
      .then(() => {
        alert(i18n._(t`Invite has been sent.`));
      })
      .catch(() => {
        alert(i18n._(t`An error occurred.`));
      });
  };

  const syncMembers = () => {
    setIsShowSyncMembersModal(true);
  };

  const cleanupMembers = () => {
    setIsShowCleanUpMembersModal(true);
  };

  const getCols = (i18n) =>
    [
      {
        style: { position: 'sticky', left: 0, backgroundColor: 'white' },
        title: i18n._(t`Members`),
        name: 'member',
        width: '240px',
        getValue: getUserFullName,
      },
      { name: 'active', width: '40px' },
      { title: i18n._(t`Created`), width: '100px' },
      { title: i18n._(t`Rights`), width: '100px' },
      { title: i18n._(t`Roles`), width: '180px' },
      isAmbitionsEnabled && { title: i18n._(t`Ambitions`), width: '180px' },
      { title: i18n._(t`Team(s)`), width: '150px' },
      { title: i18n._(t`Coach of team(s)`), width: '150px' },
      {
        style: { position: 'sticky', right: 0, backgroundColor: 'white' },
        name: 'actions',
        width: '50px',
      },
    ].filter((c) => c);

  const handleChangeTab = (key) => {
    window.location.hash = key;
    setCurrentTab(key);
    setPagination(DEFAULT_PAGINATION);
  };

  const connectUserToIntegration = async (selectedIntegrationUsers) => {
    const { id, externalId } = selectedIntegrationUsers[0];

    await connectUserToIntegrationService(id, externalId, selectedMember.id);

    // Refresh user connections
    dispatch(userActions.fetchUserData());

    setSelectedMember(null);
  };

  const disconnectUserFromIntegration = async (member) => {
    // Open choice modal with warning
    if (
      await confirm(
        i18n,
        i18n._(
          t`Are you sure you want to disconnect this member from the integration. This member will no longer be impacted by changes in the connected system.`,
        ),
      )
    ) {
      await disconnectUserFromIntegrationService(member.id);

      // Refresh user connections
      dispatch(userActions.fetchUserData());
    }
  };

  const loadAllIntegrationUsers = async () => {
    // Get users if they have not been already loaded, or if they are not currently being loaded
    if (!isUsersFromIntegrationsLoading && !usersFromIntegrations.length) {
      // Set loading state on true
      setIsUsersFromIntegrationsLoading(true);

      const integrationUsers = [];
      await Promise.all(
        integrationSettings.map(async (integration) => {
          let users;
          try {
            users = await getUsersFromIntegration(integration.id, false);
          } catch (e) {
            // Do nothing
          }

          // For each user return the id and label of the integration they belong to
          if (users) {
            integrationUsers.push(
              ...users.map((user) => ({
                label: integration.name,
                id: integration.id,
                externalId: user.externalId,
                email: user.email,
              })),
            );
          } else {
            setIsIntegrationError(true);
          }
        }),
      );

      // Loading finished, set loading state to false
      setIsUsersFromIntegrationsLoading(false);
      setUsersFromIntegrations(integrationUsers);
    }
  };

  const openConnectToIntegrationModal = (member) => {
    loadAllIntegrationUsers();
    setIsShowConnectToIntegrationsModal(true);
    setSelectedMember(member);
  };

  const renderMember = (member) => {
    const userConnectionWithCompany =
      Object.values(companyConnections).find(
        (conn) => conn.user === member.id && conn.company === currentCompany.id,
      ) || {};
    const isDisabled = userConnectionWithCompany.status !== CONNECTION_STATUSES.ACTIVE.key;

    let roles = [];
    if (!isEmpty(userConnectionWithCompany) && !isEmpty(userConnectionWithCompany.roles)) {
      roles = [...userConnectionWithCompany.roles];
    }
    const isOwner = roles.indexOf(ROLES.OWNER) !== -1;
    const isAdmin = roles.indexOf(ROLES.ADMIN) !== -1;
    const isCoach = roles.indexOf(ROLES.COACH) !== -1;
    const isSuperAdmin =
      currentUser?.isSuperAdmin === true &&
      currentUser?.superAdminLevel !== undefined &&
      currentUser?.superAdminLevel >= 1;

    const userTeams = teams ? getUserTeams(member.id, teams) : [];
    const coachOf = teams ? getCoachTeams(member.id, teams) : [];

    const menuItems = [];

    if (!isAdmin) {
      menuItems.push({
        order: 1,
        label: i18n._(t`Make admin`),
        action: () => updateAdminStatus(member, true),
      });
    }

    // admin can't revoke / delete from company / disable himself or owner
    if (String(member.id) !== String(currentUser.id) && !isOwner) {
      if (isAdmin) {
        menuItems.push({
          order: 2,
          label: i18n._(t`Revoke admin`),
          action: () => updateAdminStatus(member, false),
        });
      }

      menuItems.push(
        ...[
          {
            order: 3,
            label: i18n._(t`Delete member`),
            action: () => deleteUserFromCompany(member),
          },
          {
            order: 4,
            label: isDisabled ? i18n._(t`Enable user`) : i18n._(t`Disable user`),
            action: () => switchUserDisabled(member, !isDisabled),
          },
        ],
      );

      if ((currentUser.isOwner || isSuperAdmin) && isAdmin && !isDisabled) {
        menuItems.push({
          order: 5,
          label: i18n._(t`Make member ‘owner’`),
          action: () => changeOwnership(member),
        });
      }

      if (userConnectionWithCompany.integration) {
        menuItems.push({
          order: 6,
          label: i18n._(t`Disconnect from integration`),
          action: () => disconnectUserFromIntegration(member),
        });
      } else if (integrationSettings.length) {
        menuItems.push({
          order: 6,
          label: i18n._(t`Connect to integration`),
          action: () => openConnectToIntegrationModal(member),
        });
      }
      if (!member.completedFirstTimeSetup) {
        menuItems.push({
          order: 7,
          label: i18n._(t`Resend welcome e-mail`),
          action: () => resendWelcomeApiEmailService(member.id),
        });
      }

      // show reset MFA only for users, where MFA is enabled
      if (member?.security?.use_mfa) {
        menuItems.push({
          order: 8,
          label: i18n._(t`Reset Multi-Factor Authentication`),
          action: async () => {
            const { status } = await resetMFA(member.id);
            if (status === RESPONSE_STATUSES.SUCCESS) {
              addToast({
                title: i18n._(t`Success`),
                subtitle: i18n._(t`Multi-Factor Authentication is reset!`),
                type: TOAST_TYPES.SUCCESS,
              });
            }
          },
        });
      }
    }

    function mapLikes(likes) {
      const result = {};
      Object.values(likes).forEach((like) => {
        result[like.jobProfile] = true;
      });
      return result;
    }

    const integrationSoftware =
      userConnectionWithCompany.integration && integrationSettings.length
        ? integrationSettings.find((x) => x.id === userConnectionWithCompany.integration)
        : null;

    const userCareerPlans = Object.values(careerPlans).filter(
      (plan) => plan.createdFor === member.id && plan.status === CAREER_PLAN_STATUSES.CURRENT,
    );
    const userLikes = mapLikes(filter(companyLikes, (like) => like.user === member.id));

    // Ambitions -> jobProfile that user liked, but is not assigned too
    const userAmbitions = filter(jobProfiles, (jobProfile) => {
      return (
        jobProfile.status === JOB_PROFILE_STATUSES.ACTIVE && // check only active jobProfiles
        userLikes[jobProfile.id] &&
        // filter out jobProfile assign to user (user has careerPlan)
        !userCareerPlans.find((r) => r.jobProfile.id === jobProfile.id)
      );
    }).sort((a, b) => a.name.localeCompare(b.name));

    const userId = member.id;

    return (
      <TableRow key={member.id} verticalAlign="top">
        <TableCol left>
          <StickyContainer left>
            {isDisabled ? (
              <AvatarCard
                userId={member.id}
                customTooltip={member.email}
                isEmailStyles={isEmpty(member.firstName) && isEmpty(member.lastName)}
                isDisabled
                showTooltip
                showNameTooltip
                tooltipMaxWidth="300px"
              />
            ) : (
              <LinkWrapper>
                <Link to={getUserPublicProfileURL(userId, {}, PASSPORT_TABS_ENUM.SETTINGS)}>
                  <AvatarCard
                    type={AvatarCard.TYPES.MEDIUM}
                    userId={member.id}
                    customTooltip={member.email}
                    isEmailStyles={isEmpty(member.firstName) && isEmpty(member.lastName)}
                    showTooltip
                    showNameTooltip
                    tooltipMaxWidth="300px"
                  />
                </Link>
              </LinkWrapper>
            )}
          </StickyContainer>
        </TableCol>
        <TableCol>
          <Tippy
            trigger="mouseenter"
            theme="light"
            popperOptions={tippyOpts}
            content={
              member.completedFirstTimeSetup ? (
                <Trans>This member has activated their profile</Trans>
              ) : (
                <Trans>This member did not activate their profile</Trans>
              )
            }
          >
            <Circle
              color={member.completedFirstTimeSetup ? COLOR_SET.CYAN_GREEN : COLOR_SET.ORANGE2}
            />
          </Tippy>
        </TableCol>
        <TableCol>
          <ColText>
            {integrationSoftware ? integrationSoftware.name : <Trans>Manual</Trans>}
          </ColText>
        </TableCol>
        <TableCol>
          <List>
            {isOwner && (
              <li>
                <Trans>Owner</Trans>
              </li>
            )}
            {isAdmin && (
              <li>
                <Trans>Admin</Trans>
              </li>
            )}
            {isCoach && (
              <li>
                <Trans>Coach</Trans>
              </li>
            )}
            <li>
              <Trans>User</Trans>
            </li>
          </List>
        </TableCol>
        <TableCol>
          {userCareerPlans.length > 0 && (
            <List>
              {userCareerPlans.map((plan, index) => (
                <li key={index}>{plan.jobProfile.name}</li>
              ))}
            </List>
          )}
        </TableCol>
        {isAmbitionsEnabled && (
          <TableCol>
            <AmbitionsColumn
              role={ROLES.ADMIN}
              items={userAmbitions.map((i) => i.name)}
              userId={member.id}
              onlyText
            />
          </TableCol>
        )}
        <TableCol>
          {!isDisabled && userTeams.length > 0 && (
            <List>
              {userTeams.map((t, index) => (
                <li key={index}>{t.name}</li>
              ))}
            </List>
          )}
        </TableCol>
        <TableCol>
          {coachOf.length > 0 && (
            <List>
              {coachOf.map((t, index) => (
                <li key={index}>{t.name}</li>
              ))}
            </List>
          )}
        </TableCol>
        <TableCol right={menuItems.length}>
          <StickyContainer right>
            <IconMenu items={orderBy(menuItems, ['order', 'asc'])} />
          </StickyContainer>
        </TableCol>
      </TableRow>
    );
  };

  const renderInvite = (invite) => {
    const isCoach = invite.teamsCoach && invite.teamsCoach.length > 0;
    const isAdmin = invite.isAdmin;
    const userTeams =
      (invite.teamsMember && invite.teamsMember.map((team) => teams[team]).filter(Boolean)) || [];
    const coachOf =
      (invite.teamsCoach && invite.teamsCoach.map((team) => teams[team]).filter(Boolean)) || [];

    const menuItems = [
      { order: 5, label: i18n._(t`Cancel invite`), action: () => cancelInvite(invite) },
      { order: 6, label: i18n._(t`Resend invite`), action: () => resendInvite(invite) },
    ];

    if (isAdmin) {
      menuItems.push({
        order: 4,
        label: i18n._(t`Remove as admin`),
        action: () => updateInvite(invite, { isAdmin: false }),
      });
    } else {
      menuItems.push({
        order: 3,
        label: i18n._(t`Make admin`),
        action: () => updateInvite(invite, { isAdmin: true }),
      });
    }

    if (isCoach) {
      menuItems.push({
        order: 2,
        label: i18n._(t`Remove as coach`),
        action: () => updateInvite(invite, { teamsCoach: [] }),
      });
    }

    if (userTeams.length) {
      menuItems.push({
        order: 1,
        label: i18n._(t`Remove from team`),
        action: () => updateInvite(invite, { teamsMember: [] }),
      });
    }

    const integrationSoftware =
      invite.integration && integrationSettings.length
        ? integrationSettings.find((x) => x.id === invite.integration)
        : null;

    const profiles =
      (invite.jobProfiles && invite.jobProfiles.map((jp) => jobProfiles[jp]).filter(Boolean)) || [];

    return (
      <TableRow key={invite.id} verticalAlign="top">
        <TableCol>
          <EmailCol>{invite.email}</EmailCol>
        </TableCol>
        <TableCol />
        <TableCol>
          <ColText>
            {integrationSoftware ? integrationSoftware.name : <Trans>Manual</Trans>}
          </ColText>
        </TableCol>
        <TableCol>
          <List>
            {isAdmin && (
              <li>
                <Trans>Admin</Trans>
              </li>
            )}
            {isCoach && (
              <li>
                <Trans>Coach</Trans>
              </li>
            )}
            <li>
              <Trans>User</Trans>
            </li>
          </List>
        </TableCol>
        <TableCol>
          {profiles.length > 0 && (
            <List>
              {profiles.map((prof, index) => (
                <li key={index}>{prof.name}</li>
              ))}
            </List>
          )}
        </TableCol>
        <TableCol />
        <TableCol>
          {userTeams.length > 0 && (
            <List>
              {userTeams.map((t, index) => (
                <li key={index}>{t.name}</li>
              ))}
            </List>
          )}
        </TableCol>
        <TableCol>
          {coachOf.length > 0 && (
            <List>
              {coachOf.map((t, index) => (
                <li key={index}>{t.name}</li>
              ))}
            </List>
          )}
        </TableCol>
        <TableCol>
          <IconMenu items={orderBy(menuItems, ['order', 'asc'])} />
        </TableCol>
      </TableRow>
    );
  };

  const renderRow = (member) => (member.isInvite ? renderInvite(member) : renderMember(member));

  const handleRightsFiltersChange = (activeFilters) => {
    setCheckedRightsFilters(activeFilters);
  };

  const handleProfileActivationFiltersChange = (activeFilters) => {
    setCheckedProfileActivationFilters(activeFilters);
  };

  const filterFunction = (search) => (user) => {
    return user && getUserFullName(user).toLowerCase().indexOf(search.toLowerCase()) !== -1;
  };

  const handleChangeFilter = (filter) => {
    setPagination(DEFAULT_PAGINATION);
    setMemberFilter(() => filter);
  };

  const updateMembers = async (updateBody) => {
    if (!isEmpty(updateBody)) {
      // update DB + redux-store
      await syncMembersFromCompanyIntegrations(updateBody);

      setIsLoadingUsersData(true);
      await Promise.all([
        getCareerPlans({ status: CAREER_PLAN_STATUSES.CURRENT }, { populate: ['jobProfile'] }),
        getJobProfiles(),
        getTeams(),
        dispatch(userActions.getUsers()),
        dispatch(userActions.fetchUserData()),
      ]).then(([careerPlans, jobProfiles, teams, _users, _fetchedUsers]) => {
        setCareerPlans(careerPlans);
        // Override correct multi lang strings to prevent having to get it on every usage
        Object.values(careerPlans).forEach((cp) => {
          if (cp.jobProfile?.name) {
            cp.jobProfile.name = getMultiLangString(cp.jobProfile.name);
          }
        });
        Object.values(jobProfiles).forEach((jp) => {
          jp.name = getMultiLangString(jp.name);
        });
        setJobProfiles(jobProfiles);
        setTeams(teams);
        setIsLoadingUsersData(false);
      });
    }

    setIsShowSyncMembersModal(false);
  };

  const onPageChangeClick = ({ skip, limit, index }) => {
    setPagination({ skip, limit, index });
  };

  const exportCSV = async () => {
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });
    await downloadMembersCSV();
  };

  const popoverRef = React.createRef();

  // Get each user with the same email as the selected member
  const userAvailableIntegrations = selectedMember
    ? usersFromIntegrations.filter((user) => user.email.toLowerCase() === selectedMember.email)
    : [];

  const MembersTable = useMemo(
    () => (
      <TableCard
        cols={getCols(i18n)}
        items={items[currentTab].slice(pagination.skip, pagination.index * pagination.limit)}
        renderRow={renderRow}
        searchBy="member"
        hideHeader
        hideSearch
        noTopBorder
        noMarginBottom
        noBottomBorder
        xScroll
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, pagination, currentTab],
  );

  return (
    <>
      <HeadingNavigation
        label={i18n._(t`Members`)}
        description={i18n._(t`An overview of all members`)}
        instructions={i18n._(t`How to manage members`)}
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.MANAGING_MEMBERS)}
        actions={
          <DropdownButton
            minWidth={248}
            maxWidth={526}
            popoverRef={popoverRef}
            content={
              <MemberOptions
                inviteMembers={onShowInviteModal}
                synchroniseMembers={syncMembers}
                cleanupMembers={cleanupMembers}
                exportCSV={exportCSV}
                integrationSettings={integrationSettings}
                isImportUsersEnabled={modulesStatuses.isImportUsersEnabled}
              />
            }
          >
            <Button
              label={
                <>
                  {i18n._(t`Options`)}
                  <ExpandMoreIcon fill="#fff" />
                </>
              }
            />
          </DropdownButton>
        }
      />

      <BaseLayout>
        <TabsContainer noBorderBottom>
          <TabsWrapper
            items={tabs}
            currentItem={currentTab}
            handleChangeTab={handleChangeTab}
            isSmall
          />
        </TabsContainer>
        <ActionsContainer noHorizontalBorders noBorderRadiusTop noBorderRadiusBottom>
          <FiltersHeading
            onFilterChange={handleChangeFilter}
            customSearchFilter={filterFunction}
            searchWidth={'248px'}
          >
            <SearchSelectButton
              checkedList={checkedRightsFilters}
              options={rightsFilters}
              title={i18n._(t`Rights`)}
              handleChange={handleRightsFiltersChange}
            />
            <SearchSelectButton
              checkedList={checkedProfileActivationFilters}
              options={profileActivationFilters}
              title={i18n._(t`Profile activation`)}
              handleChange={handleProfileActivationFiltersChange}
            />
          </FiltersHeading>
        </ActionsContainer>
        {MembersTable}
        {items[currentTab].length > PAGE_SIZE && (
          <>
            <Divider />
            <PaginationBar
              pagination={pagination}
              changePagination={onPageChangeClick}
              count={items[currentTab].length}
              noTopBorder
              showCount
            />
          </>
        )}
        {isShowConnectToIntegrationsModal && (
          <SelectModal
            onModalClose={() => {
              setSelectedMember(null);
              setIsShowConnectToIntegrationsModal(false);
            }}
            onSubmit={connectUserToIntegration}
            title={i18n._(t`Select integration`)}
            buttonLabel={i18n._(t`connect user`)}
            emptySetPlaceholder={i18n._(
              isIntegrationError
                ? INTEGRATIONS_CONN_ERROR_MSG
                : t`The email of this member cannot be found in any integration.`,
            )}
            items={userAvailableIntegrations}
            loading={isUsersFromIntegrationsLoading}
            loadingPlaceholder={i18n._(
              t`Loading the user connections to the integration, this could take a moment.`,
            )}
            singleChoice
          />
        )}
        {isShowInviteModal && (
          <InviteUsersModal
            invites={invites}
            integrationSettings={integrationSettings}
            onModalClose={onHideInviteModal}
          />
        )}

        {isShowSyncMembersModal && (
          <SyncMembersModal
            onClose={() => {
              setIsShowSyncMembersModal(false);
            }}
            onSubmit={updateMembers}
          />
        )}
        {isShowCleanUpMembersModal && (
          <CleanUpMembersModal
            onClose={() => {
              setIsShowCleanUpMembersModal(false);
            }}
            onSubmit={deleteMembers}
          />
        )}
      </BaseLayout>
    </>
  );
};

export default AdminMembersPage;
