import React from 'react';

import { CONFIRMATION_MODAL_TYPE, REVIEW_THEME_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button } from '~/components/Buttons/Button';
import { ButtonVariant, ButtonSize } from '~/components/Buttons/types';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';

import useBoolState from '~/hooks/useBoolState';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { DASHBOARD_TYPE } from '../constants';
import { ActionsWrap, ActionItemBlock } from '../design';
import { PopulatedReviewTheme } from '../types';

interface IHeaderActions {
  isDraft: boolean;
  isLoading: boolean;
  onPublish?: () => Promise<void>;
  onEdit: () => void;
  showPreview?: () => void;
  languageState: ILanguageStateReturn;
  isPublishDisabled?: boolean;
  item?: PopulatedReviewTheme | PopulatedReviewTemplate;
  dashboardType: DASHBOARD_TYPE;
}

const HeaderActions = ({
  isDraft,
  isLoading,
  onPublish,
  onEdit,
  languageState,
  isPublishDisabled,
  showPreview,
  item,
  dashboardType,
}: IHeaderActions) => {
  const $isShowEditThemeWarningModal = useBoolState(false);

  const { i18n } = useLingui();
  const onEditClick = () => {
    if (dashboardType === DASHBOARD_TYPE.TEMPLATE) {
      return onEdit();
    }
    item?.status === REVIEW_THEME_STATUS.PUBLISHED ? $isShowEditThemeWarningModal.on() : onEdit();
  };
  return (
    <>
      <ActionsWrap>
        <ActionItemBlock>
          <MultiLangComponent languageState={languageState} isSingleSelect />
        </ActionItemBlock>
        {showPreview !== undefined && (
          <ActionItemBlock>
            <Tooltip disabled={false} size={TOOLTIP_SIZES.BIG} tooltip={i18n._(t`Preview`)}>
              <span>
                <Button
                  label=""
                  icon={ICONS.SHOW}
                  size={ButtonSize.MEDIUM}
                  variant={ButtonVariant.ICON}
                  onClick={showPreview}
                />
              </span>
            </Tooltip>
          </ActionItemBlock>
        )}
        <ActionItemBlock>
          <Button
            label={i18n._(t`edit`)}
            icon={ICONS.EDIT_PENCIL}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.TEXT_PRIMARY}
            isLoading={isLoading}
            onClick={onEditClick}
          />
        </ActionItemBlock>
        {isDraft && Boolean(onPublish) && (
          <ActionItemBlock>
            <Button
              label={i18n._(t`Publish`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.PRIMARY}
              isLoading={isLoading}
              disabled={isPublishDisabled}
              onClick={onPublish as () => void}
            />
          </ActionItemBlock>
        )}
      </ActionsWrap>
      {$isShowEditThemeWarningModal.value && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          description={i18n._(t`'This will set the status back to draft`)}
          title={i18n._(t`Do you want to edit this theme?`)}
          cancelButton={i18n._(t`Cancel`)}
          submitButton={i18n._(t`Yes`)}
          onClose={() => $isShowEditThemeWarningModal.off()}
          onSubmit={() => onEdit()}
        />
      )}
    </>
  );
};

export { HeaderActions };
