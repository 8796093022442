import React, { BaseSyntheticEvent, useEffect } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import CheckBox from '~/components/CheckBox';
import Editor from '~/components/Editor';
import { ICONS } from '~/components/Icon';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';
import { UserAvatar } from '~/components/UserAvatar';

import { Content, Title, Header, Label, Footer, Actions, SettingItem, Error } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { IReviewDashboardUserForm, ISignatureForm } from '../../types';
import type { IUser } from '@learned/types';

const SignatureModal = ({
  onClose,
  formMethods,
  userId,
  onSubmit,
  signatureFormMethods,
  showSettings,
}: {
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  signatureFormMethods: UseFormReturn<ISignatureForm>;
  onClose: () => void;
  userId: IUser['id'];
  onSubmit: (e: BaseSyntheticEvent) => void;
  showSettings?: boolean;
}) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const getMultiLangString = useMultiLangString();

  const settings: Array<{ title: string; errorMessage?: string; key: keyof ISignatureForm }> = [
    {
      title: i18n._(t`I have read the contents of this report*`),
      key: 'isSeen',
      errorMessage: i18n._(t`You need to agree with having seen the content of the review`),
    },
    { title: i18n._(t`I agree with the content of this report`), key: 'isAgree' },
  ];

  useEffect(() => {
    signatureFormMethods.reset({
      isAgree: false,
      isSeen: false,
      comment: '',
      signature: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      width={750}
      contentStyles={{ padding: '23px 32px' }}
      borderRadius={10}
      hideFooter
      isHideHeader
      centerModal
      showDivider={false}
    >
      <Header>
        <Title>
          <Trans>Sign review</Trans>
        </Title>
        <Button
          variant={ButtonVariant.ICON}
          size={ButtonSize.MEDIUM}
          icon={ICONS.CLOSE}
          onClick={onClose}
        />
      </Header>
      <Content>
        <div className="field">
          <Label color={COLORS.SUBTEXT}>
            <Trans>Review:</Trans>
          </Label>
          <div className="content">{getMultiLangString(turnArrayIntoMultiLang(watch('name')))}</div>
        </div>
        <div className="field">
          <Label color={COLORS.SUBTEXT}>
            <Trans>Employee:</Trans>
          </Label>
          <div className="content">
            <UserAvatar userId={userId} />
          </div>
        </div>
        {showSettings && (
          <div className="settings">
            {settings.map(({ key, title, errorMessage }) => {
              const isSeenKey = key === 'isSeen';

              return (
                <Controller
                  control={signatureFormMethods.control}
                  rules={isSeenKey ? { validate: (v) => !!v } : {}}
                  name={key}
                  key={key}
                  render={({ field: { value } }) => (
                    <>
                      <SettingItem
                        error={isSeenKey && !!signatureFormMethods.formState.errors.isSeen}
                      >
                        <CheckBox
                          size={20}
                          isSecondary
                          checked={!!value}
                          onChange={() => {
                            signatureFormMethods.setValue(key, !value);
                          }}
                        />
                        <div className="title">{title}</div>
                      </SettingItem>
                      {isSeenKey && signatureFormMethods.formState.errors.isSeen && (
                        <Error>{errorMessage}</Error>
                      )}
                    </>
                  )}
                />
              );
            })}
          </div>
        )}

        <div className="comment">
          <Label>
            <Trans>Comment</Trans>
          </Label>
          <Controller
            control={signatureFormMethods.control}
            name="comment"
            render={({ field: { value, onChange } }) => (
              <Editor compact value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className="signature">
          <div className="input">
            <Label>
              <Trans>Signature</Trans>
            </Label>
            <Input
              register={signatureFormMethods.register('signature', {
                required: true,
                minLength: 1,
              })}
            />
          </div>
          <div className="preview">{signatureFormMethods.watch('signature')}</div>
        </div>
      </Content>
      <Footer>
        <Actions>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
          <Button
            label={i18n._(t`Save`)}
            type="button"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onSubmit}
            disabled={isEmpty(signatureFormMethods.watch('signature'))}
          />
        </Actions>
      </Footer>
    </Modal>
  );
};

export { SignatureModal };
