import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { DashboardDrawer } from '~/components/DashboardDrawer';
import { DashboardDrawerMenuKey } from '~/components/DashboardDrawer/types';
import { GoalDashboardModal } from '~/components/GoalDashboardModal';
import { ICONS } from '~/components/Icon';

// TODO
// import { CareerSection } from './CareerSection';
import { GoalsSection } from './GoalsSection';
import { useUserPreview } from './useUserPreview';

import { COLORS } from '~/styles';
import { isNotFalse } from '~/utils/typePredicates';

import { JobProfileViewModal } from '../Modals/JobProfileViewModal';

const UserPreviewDrawer = ({ userId }: { userId: string }) => {
  const { i18n } = useLingui();
  const { user, items, refetchData } = useUserPreview({ userId });
  const [goalPreviewId, setGoalPreviewId] = useState('');
  const [jobProfileId, setJobProfileId] = useState('');

  return (
    <>
      <DashboardDrawer
        user={user}
        menuItems={[
          // TODO: show career tab when we improved the job profile modal
          // We need to show more details about job profiles

          // permissions.isJobMatrixProductEnabled && {
          //   key: DashboardDrawerMenuKey.CAREER,
          //   title: i18n._(t`Career`),
          //   icon: ICONS.CAREER,
          //   content: (
          //     <CareerSection
          //       sections={items.careerPlans}
          //       onCareerPlanClick={(jobProfileId: string) => setJobProfileId(jobProfileId)}
          //     />
          //   ),
          //   color: COLORS.CAREER,
          // },
          {
            key: DashboardDrawerMenuKey.GOALS,
            title: i18n._(t`Goals`),
            icon: ICONS.GOALS,
            content: (
              <GoalsSection
                sections={items.goals}
                onGoalClick={(goalId: string) => setGoalPreviewId(goalId)}
              />
            ),
            color: COLORS.GOALS_PINK,
          },
        ].filter(isNotFalse)}
      />

      {goalPreviewId && (
        <GoalDashboardModal
          goalId={goalPreviewId}
          onGoalUpdate={() => {
            if (user?.id) {
              refetchData(user.id);
            }
          }}
          onCloseModal={() => {
            setGoalPreviewId('');
          }}
          isUpdatesAndNotes={false}
        />
      )}
      {jobProfileId && (
        <JobProfileViewModal
          jobProfileId={jobProfileId}
          onClose={() => {
            setJobProfileId('');
          }}
        />
      )}
    </>
  );
};

export { UserPreviewDrawer };
