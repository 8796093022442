import styled, { css } from 'styled-components';

import { ButtonSize, type IStyledButtonProps } from './types';

import { COLORS } from '~/styles';

export const StyledBaseButton = styled.button<IStyledButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  outline: none;
  max-width: 250px;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }

  ${({ size = ButtonSize.MEDIUM }) => {
    switch (size) {
      case ButtonSize.BIG:
        return css`
          font-size: 14px;
          height: 48px;
          min-width: 154px;
        `;
      case ButtonSize.MEDIUM:
      default:
        return css`
          font-size: 12px;
          height: 32px;
          min-width: 100px;
        `;
    }
  }}

  & .icon {
    display: inline-flex;
    align-items: center;
  }

  & .label {
    white-space: nowrap;
  }
`;

export const StyledPrimaryButton = styled(StyledBaseButton)`
  /* styles */
  border-radius: 100px;
  padding: 0 24px;

  /* default color */
  background-color: ${COLORS.COMPANY};
  border-color: ${COLORS.COMPANY};
  color: ${COLORS.WHITE};

  &:not(:disabled) {
    &:hover {
      background-color: ${COLORS.COMPANY_HOVER};
      border-color: ${COLORS.COMPANY_HOVER};
      color: ${COLORS.WHITE};
    }

    &:active {
      background-color: ${COLORS.WHITE};
      border-color: ${COLORS.COMPANY_HOVER};
      color: ${COLORS.COMPANY_HOVER};
    }
  }

  &:disabled {
    background-color: ${COLORS.INACTIVE};
    border-color: ${COLORS.INACTIVE};
  }
`;

export const StyledSecondaryButton = styled(StyledBaseButton)`
  /* styles */
  border-radius: 100px;
  padding: 0 24px;
  box-shadow: 0 2px 8px 0 rgba(145, 157, 165, 0.12);

  /* default color */
  background-color: ${COLORS.WHITE};
  border-color: transparent;
  color: ${COLORS.TEXT_MAIN};

  &:not(:disabled) {
    &:hover {
      background-color: ${COLORS.WHITE};
      border-color: ${COLORS.BORDERS};
      color: ${COLORS.TEXT_HOVER};
    }

    &:active {
      background-color: ${COLORS.WHITE};
      border-color: ${COLORS.COMPANY};
      color: ${COLORS.COMPANY};
    }
  }

  &:disabled {
    color: ${COLORS.INACTIVE};
  }

  & .loader {
    color: ${COLORS.COMPANY};
  }

  & .circleLabel {
    background-color: ${COLORS.COMPANY};
    color: ${COLORS.WHITE};
    box-sizing: border-box;
    border-radius: 100px;
    aspect-ratio: 1 / 1;
    min-width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
  }

  & .flip_vertically {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
  }
`;

export const StyledSquareButton = styled(StyledBaseButton)`
  /* styles */
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  max-width: 40px;
  min-width: 40px;
  width: auto;
  height: 40px;
  padding: 13px;
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(145, 157, 165, 0.12);
  transition: max-width 0.2s;

  & .label {
    display: none;
  }

  /* default color */
  background-color: ${COLORS.WHITE};
  border-color: ${COLORS.BORDERS};
  color: ${COLORS.TEXT_MAIN};

  &:not(:disabled) {
    &:hover {
      background-color: ${COLORS.WHITE};
      border-color: ${COLORS.BORDERS};
      color: ${COLORS.COMPANY};
      max-width: 250px;

      & .label {
        display: inline;
      }
    }

    &:active {
      background-color: ${COLORS.COMPANY};
      border-color: ${COLORS.COMPANY};
      color: ${COLORS.WHITE};
    }
  }

  &:disabled {
    color: ${COLORS.INACTIVE};
    max-width: 250px;

    & .label {
      display: inline;
    }
  }
`;

export const StyledTextPrimaryButton = styled(StyledBaseButton)`
  /* styles */
  border-radius: 100px;
  padding: 0 24px;

  /* default color */
  background-color: ${COLORS.TRANSPARENT};
  border-color: transparent;
  color: ${COLORS.COMPANY};

  &:not(:disabled) {
    &:hover {
      background-color: ${COLORS.HOVER};
      border-color: ${COLORS.HOVER};
      color: ${COLORS.COMPANY};
    }

    &:active {
      background-color: ${COLORS.WHITE};
      border-color: transparent;
      color: ${COLORS.COMPANY};
      font-weight: bold;
    }
  }

  &:disabled {
    color: ${COLORS.INACTIVE};
  }
`;

export const StyledTextDeleteButton = styled(StyledBaseButton)`
  /* styles */
  border-radius: 100px;
  padding: 0 24px;

  /* default color */
  background-color: ${COLORS.TRANSPARENT};
  border-color: transparent;
  color: ${COLORS.ACCENT_ERROR};

  &:not(:disabled) {
    &:hover {
      background-color: ${COLORS.ERROR_LIGHT};
      border-color: ${COLORS.ERROR_LIGHT};
      color: ${COLORS.ACCENT_ERROR};
    }

    &:active {
      background-color: ${COLORS.WHITE};
      border-color: transparent;
      color: ${COLORS.COMPANY};
      font-weight: bold;
    }
  }

  &:disabled {
    color: ${COLORS.INACTIVE};
  }
`;

export const StyledNavigationPrimaryButton = styled(StyledPrimaryButton)`
  /* styles */
  padding: 0 20px;
  min-width: 94px;

  &:not(:disabled) {
    &:hover {
      & .icon {
        transition: all 0.3s ease;
        transform: translateX(6px);
      }
    }
  }
`;

export const StyledNavigationSecondaryButton = styled(StyledTextPrimaryButton)`
  /* styles */
  padding: 0 20px;
  min-width: 94px;
  gap: 8px;

  /* default color */
  color: ${COLORS.SUBTEXT};

  &:not(:disabled) {
    &:hover {
      & .icon {
        transition: all 0.3s ease;
        transform: translateX(6px);
      }
    }
  }
`;

export const StyledCloseButton = styled(StyledBaseButton)`
  /* styles */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 7px;
  min-width: unset;
  width: 48px;
  height: 48px;

  /* default color */
  color: ${COLORS.TEXT_MAIN};
  background-color: transparent;
  border-color: transparent;

  &:not(:disabled) {
    &:hover {
      background-color: ${COLORS.INFO_LIGHT};
      color: ${COLORS.TEXT_HOVER};
    }

    &:active {
      background-color: ${COLORS.WHITE};
      border-color: transparent;
      color: ${COLORS.COMPANY};
    }
  }

  &:disabled {
    color: ${COLORS.INACTIVE};
  }

  & .loader {
    color: ${COLORS.COMPANY};
  }
`;

export const StyledIconButton = styled(StyledBaseButton)`
  /* styles */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 7px;
  min-width: unset;
  width: 32px;
  height: 32px;

  /* default color */
  color: ${COLORS.TEXT_MAIN};
  background-color: transparent;
  border-color: transparent;

  &:not(:disabled) {
    &:hover {
      background-color: ${COLORS.INFO_LIGHT};
      color: ${COLORS.TEXT_MAIN};
    }

    &:active {
      background-color: ${COLORS.WHITE};
      border-color: transparent;
      color: ${COLORS.COMPANY};
    }
  }

  &:disabled {
    color: ${COLORS.INACTIVE};
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      background-color: ${COLORS.HOVER};
      border-color: ${COLORS.HOVER};
    `}

  & .loader {
    color: ${COLORS.COMPANY};
  }
`;

export const StyledIconCircleButton = styled(StyledBaseButton)<{ color?: string }>`
  /* styles */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 7px;
  min-width: unset;
  width: 32px;
  height: 32px;

  /* default color */
  color: ${({ color }) => color || COLORS.TEXT_MAIN};
  background-color: transparent;
  border-color: transparent;

  &:not(:disabled) {
    &:hover {
      background-color: ${COLORS.INFO_LIGHT};
      color: ${({ color }) => color || COLORS.TEXT_MAIN};
    }

    &:active {
      background-color: ${COLORS.WHITE};
      border-color: transparent;
      color: ${({ color }) => color || COLORS.COMPANY};
    }
  }

  &:disabled {
    color: ${COLORS.INACTIVE};
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      background-color: ${COLORS.HOVER};
      border-color: ${COLORS.HOVER};
    `}

  & .loader {
    color: ${({ color }) => color || COLORS.COMPANY};
  }
`;

export const StyledDeleteIconButton = styled(StyledBaseButton)`
  /* styles */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 7px;
  min-width: unset;
  width: 32px;
  height: 32px;

  /* default color */
  color: ${COLORS.ACCENT_ERROR};
  background-color: transparent;
  border-color: transparent;

  &:not(:disabled) {
    &:hover {
      background-color: ${COLORS.ERROR_LIGHT};
      border-color: ${COLORS.ERROR_LIGHT};
    }

    &:active {
      background-color: ${COLORS.WHITE};
      border-color: transparent;
      color: ${COLORS.COMPANY};
    }
  }

  &:disabled {
    color: ${COLORS.INACTIVE};
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      background-color: ${COLORS.ERROR_LIGHT};
      border-color: ${COLORS.ERROR_LIGHT};
    `}

  & .loader {
    color: ${COLORS.ACCENT_ERROR};
  }
`;

export const StyledMultiIconButton = styled(StyledBaseButton)`
  /* styles */
  border-radius: 100px;
  padding: 0 24px;
  box-shadow: 0 2px 8px 0 rgba(145, 157, 165, 0.12);
  gap: 8px;

  /* default color */
  background-color: ${COLORS.WHITE};
  border-color: transparent;
  color: ${COLORS.TEXT_MAIN};

  &:not(:disabled) {
    &:hover {
      background-color: ${COLORS.WHITE};
      border-color: ${COLORS.BORDERS};
      color: ${COLORS.TEXT_HOVER};
    }

    &:active {
      background-color: ${COLORS.WHITE};
      border-color: ${COLORS.COMPANY};
      color: ${COLORS.COMPANY};
    }
  }

  &:disabled {
    color: ${COLORS.INACTIVE};
  }

  & .loader {
    color: ${COLORS.COMPANY};
  }
`;
