import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormWatch } from 'react-hook-form';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import { ErrorIconContainer } from '../design';

import type { IReviewCycleForm } from '../types';
import type { IJobProfile } from '@learned/types';

interface IErrorIconProps {
  watch: UseFormWatch<IReviewCycleForm>;
  jobs: IJobProfile[];
  errors?: {
    noFocusArea?: string;
    noSkill?: string;
  };
}

const ErrorIcon = ({ watch, jobs, errors }: IErrorIconProps) => {
  const { i18n } = useLingui();
  const [errorMessage, setErrorMessage] = useState('');
  const skills = watch('skills');

  useEffect(() => {
    // first errors about missing skills
    if (errors?.noSkill) {
      const errorMessage = errors?.noSkill
        .split('|')
        .map((jobName) =>
          i18n._(
            t`- Job ${jobName} required a minimum 1 skill in at least one of the enabled skill-categories.`,
          ),
        )
        .join('\n');
      const messageStart = i18n._(t`Make sure:`);
      const errorTips = i18n._(t`- Answering the skill-category question is mandatory.`);
      setErrorMessage(`${messageStart}\n${errorMessage}\n${errorTips}`);

      // then errors about missing focus areas
    } else if (errors?.noFocusArea) {
      setErrorMessage(
        errors?.noFocusArea
          .split('|')
          .map((skill) => i18n._(t`Skill ${skill} does not contain focus areas`))
          .join('\n'),
      );
    } else {
      // this should reach NEVER
      setErrorMessage(i18n._(t`Validation error`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, skills, errors]);

  return (
    <ErrorIconContainer>
      <ToolTip tooltip={errorMessage} placement={TOOLTIP_PLACEMENTS.BOTTOM}>
        <span>
          <Icon icon={ICONS.ERROR_SIGN} size={ICON_SIZES.SMALL} />
        </span>
      </ToolTip>
    </ErrorIconContainer>
  );
};

export { ErrorIcon };
