import React from 'react';

import { StickyContainer } from 'react-sticky';
import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const Main = styled(StickyContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  background: ${COLORS.BG_PAGE};
  position: relative;
`;

const PageContent = styled.div`
  flex: 1;
  height: 100%;
`;

const FocusLayoutWithoutFooter = ({ children }) => (
  <Wrapper>
    <Main id="main-content">
      <PageContent>{children}</PageContent>
    </Main>
  </Wrapper>
);

export default FocusLayoutWithoutFooter;
