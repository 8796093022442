import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import nanoid from 'nanoid';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray } from 'react-hook-form';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import {
  AddNewFocusAreaButton,
  Count,
  DroppableContainer,
  FocusArea,
  IconWrapper,
  Level,
  LevelHeader,
  LevelName,
  StyledIcon,
} from './design';
import { FocusAreaModal } from './FocusAreaModal';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';

import type { IGeneralForm } from '../types';
import type { IMultiLangString } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

type FocusAreasProps = {
  levelName: IMultiLangString;
  index: number;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: ILanguageStateReturn;
  readonly?: boolean;
  isForceClosed?: boolean;
  setOpenedFocusAreaLevel?: (focusAreaLevel: number) => void;
};

function FocusAreas({
  levelName,
  index,
  formMethods,
  languageState,
  setOpenedFocusAreaLevel,
  readonly = false,
  isForceClosed = false,
}: FocusAreasProps) {
  const getMultiLangString = useMultiLangString();
  const { control, watch } = formMethods;
  const skillName = watch('name');
  const focusAreasArray = useFieldArray({ control, name: `focusAreas.${index}.values` });
  const { fields, remove, insert, update } = focusAreasArray;
  const [editingFocusArea, setEditingFocusArea] = useState<{
    name: IMultiLangString;
    _id: string;
  }>();
  const [creatingFocusArea, setCreatingFocusArea] = useState(false);
  const [opened, setOpened] = useState(false);

  const createFocusArea = (newValues: { name: IMultiLangString }) => {
    insert(fields.length, { name: newValues.name, _id: nanoid() });
  };

  const updateFocusArea = (newValues: { name: IMultiLangString; _id: string }) => {
    const aliasIndex = fields.findIndex((focusArea) => focusArea._id === newValues._id);
    update(aliasIndex, newValues);
  };

  const deleteFocusArea = (id: string) => {
    const aliasIndex = fields.findIndex((focusArea) => focusArea._id === id);
    remove(aliasIndex);
  };

  return (
    <>
      {(!readonly || fields.length > 0) && (
        <Level isEmpty={fields.length === 0}>
          <LevelHeader
            onClick={() =>
              setOpened((prev) => {
                if (!prev || isForceClosed) {
                  setOpenedFocusAreaLevel?.(index);
                  return true;
                } else {
                  return false;
                }
              })
            }
          >
            <LevelName>{getMultiLangString(levelName)}</LevelName>
            <Count>
              <Trans>{fields.length} focus area&apos;s</Trans>
              <StyledIcon
                icon={ICONS.DROPDOWN}
                size={ICON_SIZES.SMALL}
                $opened={opened && !isForceClosed}
              />
            </Count>
          </LevelHeader>
          {opened && !isForceClosed && (
            <>
              <Droppable droppableId={`${index}`}>
                {(provided) => (
                  <DroppableContainer
                    isEmpty={fields.length === 0}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {fields.map((field, idx) => {
                      return (
                        <Draggable
                          key={field.id}
                          draggableId={field.id}
                          index={idx}
                          isDragDisabled={readonly}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <FocusArea
                                onClick={!readonly ? () => setEditingFocusArea(field) : undefined}
                              >
                                {getMultiLangString(field.name)}
                              </FocusArea>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </DroppableContainer>
                )}
              </Droppable>

              {!readonly && (
                <AddNewFocusAreaButton type="button" onClick={() => setCreatingFocusArea(true)}>
                  <IconWrapper>
                    <Icon icon={ICONS.ADD_PLUS} size={ICON_SIZES.SMALL} />
                  </IconWrapper>
                </AddNewFocusAreaButton>
              )}
            </>
          )}
          {!readonly && editingFocusArea && (
            <FocusAreaModal
              title={<Trans>Edit focus area</Trans>}
              onClose={() => setEditingFocusArea(undefined)}
              onSave={updateFocusArea}
              onDelete={deleteFocusArea}
              languageState={languageState}
              focusArea={editingFocusArea}
              skillName={getMultiLangString(convertLanguageValue(skillName))}
            />
          )}
          {!readonly && creatingFocusArea && (
            <FocusAreaModal
              title={<Trans>Create focus area</Trans>}
              onClose={() => setCreatingFocusArea(false)}
              onCreate={createFocusArea}
              onDelete={deleteFocusArea}
              languageState={languageState}
              skillName={getMultiLangString(convertLanguageValue(skillName))}
            />
          )}
        </Level>
      )}
    </>
  );
}

export { FocusAreas };
