import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  ROLES,
  SURVEY_STATUS,
  SURVEY_TASK_STATUS,
} from '@learned/constants';
import { ISurvey, ISurveyPopulated, ISurveyQuestion, ISurveyTemplate } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';

import { Button } from '~/components/Buttons/Button';
import { ButtonSize, ButtonVariant } from '~/components/Buttons/types';
import DashboardHeader from '~/components/DashboardHeader';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { FillOutSurvey } from '~/components/FillOutSurvey';
import { ICONS } from '~/components/Icon';
import { LastSaved } from '~/components/LastSaved';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SECTION_TYPE, SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { PopulatedSurveyTemplate } from '~/pages/SurveyTemplateUpdate/types';

import {
  StepChooseTemplate,
  StepGeneral,
  StepParticipants,
  StepSettings,
  StepSummary,
} from './components';
import { ActionItemBlock, ActionsWrap } from './design';
import { resolver } from './validation';

import { LastSavedStatus } from '~/constants/lastSaved';
import routes from '~/constants/routes';
import { useAutoSave } from '~/hooks/useAutoSave';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useQueryURL } from '~/hooks/useQueryURL';
import { checkModuleSurvey } from '~/selectors/baseGetters';
import { deleteSurveys, getSurvey, updateSurvey } from '~/services/surveys';
import { getSurveyTemplate } from '~/services/surveyTemplates';
import { COLORS } from '~/styles';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IGeneralForm } from './types';

const Wrapper = styled.div`
  display: flex;
  gap: 151px;
  margin-top: 49px;
`;

const PreviewWrapper = styled.div`
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background-color: ${COLORS.BG_PAGE};
`;

const SurveyUpdate = () => {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const {
    values: { sectionStep },
  } = useQueryURL({ keys: ['sectionStep'] });
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const params: Record<string, string | undefined> = useParams();
  const history = useHistory();
  const surveyId = params.surveyId as string;

  const languageState = useLanguageState();
  const { addToast } = useToasts();
  const [oldStatus, setOldStatus] = useState<SURVEY_STATUS>();
  const [previewTemplate, setPreviewTemplate] = useState<PopulatedSurveyTemplate | null>(null);
  const [previewTemplateId, setPreviewTemplateId] = useState<string | null>(null);

  const generalFormMethods = useForm<IGeneralForm>({
    mode: 'all',
    resolver,
    context: { companyPrimaryLanguage: languageState.companyPrimaryLanguage.locale },
    defaultValues: {
      name: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      description: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      template: null,
      isShared: false,
    },
  });

  const autosave = useAutoSave(() =>
    onSubmit(generalFormMethods.watch('status'), { isRedirect: false, isErrorToast: false }),
  );

  const {
    watch,
    setValue,
    formState: { errors },
  } = generalFormMethods;

  const sectionState = useSectionState([
    {
      title: i18n._(t`Choose template`),
    },
    {
      title: i18n._(t`Name and description`),
    },
    {
      title: i18n._(t`Settings`),
      fields: [i18n._(t`Dates`), i18n._(t`Notifications`), i18n._(t`Anonymity`)],
    },
    {
      title: i18n._(t`Participants`),
    },
    {
      title: i18n._(t`Final check`),
    },
  ]);

  useEffect(() => {
    if (!isModuleSurveyEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleSurveyEnabled, history]);

  useEffect(() => {
    if (sectionStep) {
      sectionState.setCurrentSection(+sectionStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionStep]);

  useEffect(() => {
    sectionState.setErrorSection(0, !!errors?.template);
    // eslint-disable-next-line
  }, [errors?.template]);

  useEffect(() => {
    sectionState.setErrorSection(1, !!errors?.name || !!errors?.description);
    // eslint-disable-next-line
  }, [!!errors?.name, !!errors?.description]);

  useEffect(() => {
    sectionState.setErrorSection(3, !!errors?.participants?.ids);
    // eslint-disable-next-line
  }, [!!errors?.participants?.ids]);

  const [isLoading, setIsLoading] = useState(false);

  const surveyName = generalFormMethods
    .watch('name')
    .find(({ locale }) => locale === languageState.companyPrimaryLanguage.locale)?.value;
  const title =
    watch('status') === SURVEY_STATUS.DRAFT
      ? i18n._(t`Create survey: ${surveyName}`)
      : i18n._(t`Edit survey: ${surveyName}`);

  useEffect(() => {
    let isMounted = true;
    const fetchSurvey = async () => {
      if (!surveyId) {
        return;
      }
      const { data } = await getSurvey(surveyId, { populate: ['template', 'participants.ids'] });
      const survey: ISurveyPopulated = data[API_RETURN_FIELDS.SURVEY];
      if (isMounted && survey) {
        const name = turnMultiLangIntoArray(survey.name, languageState.companyLanguages);
        const description = turnMultiLangIntoArray(
          survey.description,
          languageState.companyLanguages,
        );

        setOldStatus(survey.status);

        // set value
        setValue('name', name);
        setValue('description', description);
        setValue('dates', survey.dates);
        setValue('type', survey.type);
        setValue('status', survey.status);
        setValue('notifications', survey.notifications);
        setValue('anonymity', survey.anonymity);
        setValue('isShared', survey.isShared);
        setValue('pulse', survey.pulse);
        setValue('participants', survey.participants);
        setValue('template', survey.template);

        // set all sections to done for published survey
        if (survey.status !== SURVEY_STATUS.DRAFT) {
          sectionState.sections.forEach((_i, index) =>
            sectionState.setTypeSection(index, SECTION_TYPE.DONE),
          );
        }
      }
    };

    fetchSurvey();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [surveyId]);

  // fetch template for template preview mode
  useEffect(() => {
    let mounted = true;

    const fetchTemplate = async () => {
      if (!previewTemplateId) {
        return;
      }
      setIsLoading(true);
      const { data } = await getSurveyTemplate(previewTemplateId, {
        populate: ['questions'],
        joinToQuestions: ['themeName'],
      });

      const surveyTemplate: PopulatedSurveyTemplate = data[API_RETURN_FIELDS.SURVEY_TEMPLATE];
      if (mounted && surveyTemplate) {
        setPreviewTemplate(surveyTemplate);
      }
      setIsLoading(false);
    };
    fetchTemplate();

    return () => {
      mounted = false;
    };
  }, [previewTemplateId]);

  const onFail = ({ isErrorToast = false }: { isErrorToast?: boolean }) => {
    if (isErrorToast) {
      addToast({
        title: i18n._(t`Error`),
        subtitle: i18n._(t`Please fill in all obligated fields`),
        type: TOAST_TYPES.INFO,
      });
    }

    // need timeout, to have time update errors, otherwise goToFirstErrorSection does not work
    setTimeout(sectionState.goToFirstErrorSection, 100);
    setValue('status', oldStatus as SURVEY_STATUS);
  };

  const onSuccess = async (status: SURVEY_STATUS, isRedirect?: boolean) => {
    const body = {
      name: turnArrayIntoMultiLang(generalFormMethods.watch('name')),
      description: turnArrayIntoMultiLang(generalFormMethods.watch('description')),
      type: generalFormMethods.watch('type'),
      status,
      template: generalFormMethods.watch('template')?.id || null,
      anonymity: generalFormMethods.watch('anonymity'),
      isShared: generalFormMethods.watch('isShared'),
      participants: {
        type: generalFormMethods.watch('participants.type'),
        ids: generalFormMethods.watch('participants.ids').map((item) => item.id || item), // ids populated, need to store only ids
      },
      dates: generalFormMethods.watch('dates'),
      notifications: generalFormMethods.watch('notifications'),
      pulse: generalFormMethods.watch('pulse'),
    };

    try {
      const result = await updateSurvey(surveyId, body);
      if (result.code === 200 && isRedirect) {
        if (status === SURVEY_STATUS.DRAFT) {
          routes.SURVEYS_OVERVIEW.go(
            { role: ROLES.USER },
            {
              noHash: true,
              hash: 'organisation',
            },
          );
          addToast({ title: i18n._(t`Survey saved as draft`), type: TOAST_TYPES.INFO });
        } else {
          routes.SURVEY_DASHBOARD.go({}, { surveyId, isBackPath: true });
          addToast({ title: i18n._(t`Survey published`), type: TOAST_TYPES.SUCCESS });
        }
      }
    } catch (_e) {
      setValue('status', oldStatus as SURVEY_STATUS);
    }
  };

  const onSubmit = async (
    status: SURVEY_STATUS,
    {
      isRedirect = false,
      isErrorToast = false,
    }: { isRedirect?: boolean; isErrorToast?: boolean } = {},
  ) => {
    setValue('status', status);
    autosave.cancel(); // cancel all not finished autosave

    const submit = generalFormMethods.handleSubmit(
      () => onSuccess(status, isRedirect),
      () => onFail({ isErrorToast }),
    );

    return submit();
  };

  const onPublish = async () => {
    try {
      setIsLoading(true);
      await onSubmit(SURVEY_STATUS.ACTIVE, { isRedirect: true, isErrorToast: true });
    } finally {
      setIsLoading(false);
    }
  };
  const onSave = async () => {
    try {
      setIsLoading(true);
      await onSubmit(SURVEY_STATUS.DRAFT, { isRedirect: true, isErrorToast: true });
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Are you sure you want to delete survey?`),
      description: i18n._(
        t`This would mean, that removing a survey will effect historic reporting and cannot be undone.`,
      ),
    });

    if (isConfirmed) {
      await deleteSurveys([surveyId]);
      routes.SURVEYS_OVERVIEW.go(
        // @ts-ignore
        { role: ROLES.USER },
        {
          noHash: true,
          hash: 'organisation',
        },
      );
      addToast({ title: 'Survey deleted', type: TOAST_TYPES.INFO });
    }
  };

  const onQuestionEdit = async (questionId: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Be aware`),
      description: i18n._(t`Changing this question will affect all surveys that use this question`),
    });

    if (isConfirmed) {
      routes.SURVEY_TEMPLATE_UPDATE.go(
        {},
        { surveyTemplateId: previewTemplate?.id, isBackPath: true, query: { questionId } },
      );
    }
  };

  const onTemplatePreview = (template: ISurveyTemplate) => setPreviewTemplateId(template.id);

  if (previewTemplate) {
    return (
      <PreviewWrapper>
        <FillOutSurvey
          onEdit={onQuestionEdit}
          isPreview
          surveyTask={{
            status: SURVEY_TASK_STATUS.DRAFT,
            name: previewTemplate.name,
            questions: previewTemplate.questions as unknown as ISurveyQuestion[],
            survey: { status: SURVEY_STATUS.ACTIVE } as ISurvey,
          }}
          goBack={() => {
            setPreviewTemplate(null);
            setPreviewTemplateId(null);
          }}
        />
      </PreviewWrapper>
    );
  }

  return (
    <>
      <DashboardHeader
        title={title}
        onBack={goBack}
        actions={
          <ActionsWrap>
            <LastSaved
              time={autosave.state.time}
              status={autosave.state.status as unknown as LastSavedStatus}
              errorMessage={autosave.state.message}
            />
            <ActionItemBlock>
              <MultiLangComponent languageState={languageState} />
            </ActionItemBlock>

            <ActionItemBlock>
              <Tooltip size={TOOLTIP_SIZES.BIG} disabled={false} tooltip={i18n._(t`Delete`)}>
                <span>
                  <Button
                    label=""
                    size={ButtonSize.MEDIUM}
                    variant={ButtonVariant.ICON_DELETE}
                    isLoading={isLoading}
                    onClick={onDelete}
                  />
                </span>
              </Tooltip>
              {watch('status') === SURVEY_STATUS.DRAFT && (
                <Tooltip
                  size={TOOLTIP_SIZES.BIG}
                  disabled={false}
                  tooltip={i18n._(t`Save as Draft`)}
                >
                  <span>
                    <Button
                      label=""
                      icon={ICONS.SAVE}
                      size={ButtonSize.MEDIUM}
                      variant={ButtonVariant.ICON}
                      isLoading={isLoading}
                      onClick={onSave}
                    />
                  </span>
                </Tooltip>
              )}
            </ActionItemBlock>
            <ActionItemBlock>
              <Button
                label={
                  watch('status') === SURVEY_STATUS.DRAFT ? i18n._(t`Publish`) : i18n._(t`Save`)
                }
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.PRIMARY}
                isLoading={isLoading}
                onClick={onPublish}
              />
            </ActionItemBlock>
          </ActionsWrap>
        }
      />
      <Wrapper>
        <SideBar
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          setCurrentSection={sectionState.setCurrentSection}
        />
        <ShowSpinnerIfLoading loading={isLoading}>
          {sectionState.currentSection === 0 && (
            <StepChooseTemplate
              languageState={languageState}
              formMethods={generalFormMethods}
              setCurrentSection={sectionState.setCurrentSection}
              autosave={autosave}
              onTemplatePreview={onTemplatePreview}
            />
          )}
          {sectionState.currentSection === 1 && (
            <StepGeneral
              languageState={languageState}
              formMethods={generalFormMethods}
              setCurrentSection={sectionState.setCurrentSection}
              autosave={autosave}
            />
          )}

          {sectionState.currentSection === 2 && (
            <StepSettings
              languageState={languageState}
              formMethods={generalFormMethods}
              setCurrentSection={sectionState.setCurrentSection}
              autosave={autosave}
            />
          )}

          {sectionState.currentSection === 3 && (
            <StepParticipants
              languageState={languageState}
              formMethods={generalFormMethods}
              setCurrentSection={sectionState.setCurrentSection}
              autosave={autosave}
            />
          )}

          {sectionState.currentSection === 4 && (
            <StepSummary
              languageState={languageState}
              formMethods={generalFormMethods}
              setCurrentSection={sectionState.setCurrentSection}
              onPublish={onPublish}
              onTemplatePreview={onTemplatePreview}
            />
          )}
        </ShowSpinnerIfLoading>
      </Wrapper>
    </>
  );
};

export { SurveyUpdate };
