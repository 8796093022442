import React from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_TYPES, TASK_STATUS } from '@learned/constants';
import { useSelector } from 'react-redux';

import { GiveReview } from '~/pages/ReviewGiveFeedback/components/GiveReview';
import { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';

import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';

type GiveReviewPreviewProps = {
  template: PopulatedReviewTemplate;
  onClose: () => void;
};

const GiveReviewPreview = ({ template, onClose }: GiveReviewPreviewProps) => {
  const languageState = useLanguageState();
  const currentUser = useSelector(getUser);
  const date = new Date();
  const startDate = new Date(date.setDate(date.getDate() - 1));
  const endDate = new Date(date.setDate(date.getDate() + 8));

  const createFakeReviewTask = () => {
    return {
      company: template.company,
      data: {
        reviewId: 'fakeReviewId',
      },
      deadLine: endDate,
      description: template.description,
      id: 'fakeId',
      isDeleted: false,
      name: template.name,
      startDate,
      status: TASK_STATUS.TODO,
      target: 'fakeTargetId',
      targetCollection: 'userReviews',
      type: REVIEW_TYPES.SELF,
      userFrom: currentUser.id,
      userTo: {
        id: currentUser.id,
      },
    };
  };

  return (
    <>
      <GiveReview
        onBack={onClose}
        isPreview
        // @ts-ignore
        reviewTask={createFakeReviewTask()}
        // @ts-ignore
        userReview={{
          ...template,
          peers: [],
          settings: {
            startDate,
            endDate,
            isCoachesAskedToScheduleReview: true,
            isAutoArchive: true,
            isAutoTimeline: true,
            isCalibrate: true,
            isDigitalSign: true,
          },
          coaches: [],
          userReviewQuestions: template.questions
            .filter(({ type }) => type !== REVIEW_QUESTION_TYPES.SKILL_CATEGORY)
            .map(({ id }) => id),
        }}
        introTitle={''}
        languageState={languageState}
        useMultiLangString={useMultiLangString}
      />
    </>
  );
};

export { GiveReviewPreview };
