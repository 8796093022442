import React, { useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';

import { TabSlider } from '~/components/TabSlider';

import { StyledTableGrid, NameRow, FocusAreaName } from './RequiredSkills.design';
import { SectionTitle, Container } from './Setup.design';

import type { IColumnTable } from '~/@types/table';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getCompanySettings } from '~/selectors/companySettings';
import { isNotNil } from '~/utils/typePredicates';

import type { IJobProfilePopulated } from '../types';
import type { IMultiLangString, ISkillCategory } from '@learned/types';
import type { I18n } from '@lingui/core';

interface ISkillCategoryColumn {
  id: string;
  name: IMultiLangString | null;
  focusArea: IMultiLangString;
}

const createSkillCategoryColumns = (getMultiLangString: ReturnType<typeof useMultiLangString>) =>
  [
    {
      name: (i18n: I18n) => i18n._(t`Skill or KPI`),
      accessor: 'name',
      maxWidth: '200px',
      mergeSameCells: true,
      mergeSameCellsKey: 'id',
      renderCell: ({ name }) => {
        return name ? <NameRow maxWidth="200px">{getMultiLangString(name)}</NameRow> : <></>;
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`Focus Area`),
      accessor: 'focusArea',
      renderCell: ({ focusArea }) => {
        return <FocusAreaName>{getMultiLangString(focusArea)}</FocusAreaName>;
      },
    },
  ] as IColumnTable<ISkillCategoryColumn>[];

function RequiredSkills({ jobProfile }: { jobProfile: IJobProfilePopulated }) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const companySettings = useSelector(getCompanySettings);
  const columns = useMemo(
    () => createSkillCategoryColumns(getMultiLangString),
    [getMultiLangString],
  );

  const DELETED_FA_MESSAGE = i18n._(t`(focus area deleted from the skill)`);

  const tabItems = useMemo(() => {
    const uniqueSkillCategories = uniqBy(
      jobProfile.skills
        .map((skill) => skill.skill?.skillCategory)
        .filter((item) => item !== undefined),
      'id',
    );

    return companySettings.skillCategories
      .map((id: string) => uniqueSkillCategories.find((item) => item.id === id))
      .filter(isNotNil)
      .map((item: ISkillCategory) => {
        const data: ISkillCategoryColumn[] = [];

        jobProfile.skills.forEach((skill) => {
          if (skill.skill?.skillCategory?.id !== item.id) {
            return;
          }
          skill.selectedFocusAreas.forEach((selectedFocusArea) => {
            if (!isEmpty(selectedFocusArea.focusAreas)) {
              selectedFocusArea.focusAreas.forEach((focusArea) => {
                data.push({
                  id: skill.skill.id,
                  name: skill.skill.name,
                  focusArea: isEmpty(focusArea.name)
                    ? { en_GB: DELETED_FA_MESSAGE }
                    : focusArea.name,
                });
              });
            } else {
              data.push({
                id: skill.skill.id,
                name: skill.skill.name,
                focusArea: { en_GB: DELETED_FA_MESSAGE },
              });
            }
          });
        });

        return {
          key: item.id,
          label: getMultiLangString(item.name),
          content: (
            <StyledTableGrid
              thColumnPadding="16px 19px"
              data={data}
              columns={columns}
              visibleColumn={'false'}
            />
          ),
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ skills: jobProfile.skills })]);

  return (
    <Container>
      <SectionTitle>
        <Trans>Expected behavior and knowledge</Trans>
      </SectionTitle>
      {!isEmpty(tabItems) && <TabSlider items={tabItems} noPadding tabTitleAlignment={'left'} />}
    </Container>
  );
}

export { RequiredSkills };
