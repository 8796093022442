import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useParams } from 'react-router';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { DashboardHeader } from '~/components/DashboardHeader';
import { HeaderTabs } from '~/components/HeaderTabs';
import { ICONS } from '~/components/Icon';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { useJobProfile, useMemberCareerplans, useTabs } from '~/pages/JobProfileViewAdmin/hooks';

import { Card, Center, Container, DraftBar, End, Start, Tabs, StyledButton } from './design';
import { Members, Setup } from './tabs';

import routes from '~/constants/routes';
import { useFromQuery } from '~/hooks/useFromQuery';
import { COLORS } from '~/styles';

function JobProfileViewAdmin() {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const { roleId } = useParams<{ roleId: string }>();
  const { isLoading, jobProfile } = useJobProfile(roleId);
  const {
    careerPlans,
    refetch,
    isLoading: isMemberCareerPlansLoading,
  } = useMemberCareerplans({ jobProfile: roleId });
  const { tabs, currentTab, onChangeTab } = useTabs(careerPlans?.length ?? 0);
  const isDraft = jobProfile?.published === false;

  return (
    <>
      <DashboardHeader
        title={i18n._(t`Job Library`)}
        onBack={goBack}
        isBackIcon
        actions={
          <StyledButton
            label={i18n._(t`Edit`)}
            icon={ICONS.EDIT_PENCIL}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.TEXT_PRIMARY}
            onClick={() =>
              routes.JOB_UPDATE.go(
                {},
                {
                  roleId,
                  isBackPath: true,
                },
              )
            }
          />
        }
        subHeader={
          isDraft && (
            <DraftBar>
              <Start />
              <Center>
                <Trans>Draft version</Trans>
              </Center>
              <End>
                <Trans>This job is not yet visible to others</Trans>
              </End>
            </DraftBar>
          )
        }
      />
      <Container isDraft={isDraft}>
        <Card>
          {!isDraft && (
            <HeaderTabs
              tabs={Object.values(tabs)}
              selectedTab={currentTab}
              handleChangeTab={onChangeTab}
              defaultBackground={COLORS.WHITE}
            />
          )}

          <Tabs>
            <ShowSpinnerIfLoading loading={isLoading || isMemberCareerPlansLoading || !jobProfile}>
              {currentTab === tabs.setup.key && jobProfile && <Setup jobProfile={jobProfile} />}
              {currentTab === tabs.members.key && !isDraft && jobProfile && (
                <Members
                  jobProfile={jobProfile}
                  careerPlans={careerPlans}
                  refetchCareerPlans={refetch}
                />
              )}
            </ShowSpinnerIfLoading>
          </Tabs>
        </Card>
      </Container>
    </>
  );
}

export { JobProfileViewAdmin };
