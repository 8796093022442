import { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  REVIEW_STATUS,
  ROLES,
  REVIEW_JOIN,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import routes from '~/constants/routes';
import { useLanguageState } from '~/hooks/useLanguageState';
import { getUser } from '~/selectors/baseGetters';
import {
  archiveReviewById,
  deleteReviewById,
  fetchReviewById,
  shareCalibrationInput,
  unarchiveReviewById,
} from '~/services/reviews';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { IReviewDashboardCycleForm } from '../types';

import type { IMultiLangString, IReview, IReviewTasks } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewProps {
  formMethods: UseFormReturn<IReviewDashboardCycleForm>;
  reviewId: IReview['id'];
  showFetchFailureToast?: boolean;
}

export const useReview = ({ formMethods, reviewId, showFetchFailureToast }: UseReviewProps) => {
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const history = useHistory();
  const { setValue } = formMethods;
  const languageState = useLanguageState(true);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(getUser);

  const [item, setItem] = useState<IReview>();

  const isAdmin = user?.isAdmin;
  const isCreator = item && user && item?.createdBy === user.id;

  const isAllowToDelete = isAdmin || isCreator;
  const isAllowToEdit =
    (isAdmin || isCreator) &&
    item &&
    [REVIEW_STATUS.PUBLISHED, REVIEW_STATUS.ACTIVE, REVIEW_STATUS.COMPLETED].includes(item.status);
  const isAllowToArchive =
    (isAdmin || isCreator) &&
    item &&
    [REVIEW_STATUS.ACTIVE, REVIEW_STATUS.COMPLETED].includes(item.status);
  const isAllowToShareCalibrationInput =
    (isAdmin || isCreator) &&
    item &&
    [REVIEW_STATUS.ACTIVE, REVIEW_STATUS.COMPLETED].includes(item.status);

  const isAllowToUnarchive =
    (isAdmin || isCreator) && item && [REVIEW_STATUS.ARCHIVED].includes(item.status);

  const fetchReview = async () => {
    try {
      const result = await fetchReviewById(
        reviewId,
        { join: [REVIEW_JOIN.EMPLOYEES_TOTAL] },
        showFetchFailureToast,
      );
      const review: IReview = result.data[API_RETURN_FIELDS.REVIEW];
      const employeesTotal: number = result.data[REVIEW_JOIN.EMPLOYEES_TOTAL];
      return { review, employeesTotal };
    } catch (e: unknown) {
      if (e instanceof Error) {
        // API sends 403 for item not found, had to check the message
        if (e.message === '[403]: Item is not found') {
          history.replace(routes.ERROR + '?errorCode=404');
        }
      }
    }
    return { review: undefined, employeesTotal: 0 };
  };

  const setFormValues = async () => {
    setIsLoading(true);
    const { review, employeesTotal } = await fetchReview();
    if (!review) {
      setIsLoading(false);
      return;
    }
    setItem(review);
    setValue('notifications', review.notifications);
    setValue('name', turnMultiLangIntoArray(review.name, languageState.companyLanguages));
    setValue('reviewTemplate', review.reviewTemplate);
    setValue(
      'reviewTemplateName',
      turnMultiLangIntoArray(
        (review?.backup?.reviewTemplate?.name as IMultiLangString) || '',
        languageState.companyLanguages,
      ),
    );
    setValue('privacy', review.privacy);
    setValue('settings', review.settings);
    setValue('status', review.status);
    setValue('createdBy', review.createdBy);
    setValue('tasks', review.tasks as unknown as IReviewTasks);
    setValue('employeesTotal', employeesTotal);
    setValue(
      'description',
      turnMultiLangIntoArray(review.description, languageState.companyLanguages),
    );
    setIsLoading(false);
  };

  useEffect(() => {
    setFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  const goToReviewCycles = () =>
    history.push(
      routes.REVIEWS.build(
        // @ts-ignore
        { role: ROLES.USER },
        { hash: 'review-cycles' },
      ),
    );

  const onEdit = (section?: string) => {
    if (isAllowToEdit) {
      const path = routes.UPDATE_REVIEW_CYCLE.build(
        { companyId: undefined, teamId: undefined, role: ROLES.USER },

        {
          // @ts-ignore
          reviewId,
          isBackPath: true,
          query: { currentSection: section },
        },
      );
      history.push(path);
    }
  };

  const onDelete = async () => {
    if (isAllowToDelete) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete review cycle?`),
        description: i18n._(
          t`Are you sure you want to delete this review cycle? All reviews within this cycle will be deleted. This action cannot be undone.`,
        ),
      });

      if (isConfirmed) {
        await deleteReviewById(reviewId);

        addToast({
          title: i18n._(t`Review cycle deleted`),
          type: TOAST_TYPES.INFO,
        });

        goToReviewCycles();
      }
    }
  };

  const onArchive = async () => {
    if (isAllowToArchive) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Archive review cycle?`),
        description: i18n._(
          t`Are you sure you want to archive this review cycle? All reviews that are part of this cycle will be archived and can no longer be edited. Open tasks related to this cycle will be cancelled.`,
        ),
      });

      if (isConfirmed) {
        await archiveReviewById(reviewId);
        addToast({
          title: i18n._(t`Review cycle archived`),
          type: TOAST_TYPES.INFO,
        });

        // refetch data
        await setFormValues();
      }
    }
  };

  const onUnarchive = async () => {
    if (isAllowToUnarchive) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Unarchive review cycle?`),
        description: i18n._(
          t`Are you sure you want to unarchive this review cycle? This allows for reviews part of this cycle to be unarchived again.`,
        ),
      });

      if (isConfirmed) {
        await unarchiveReviewById(reviewId);
        addToast({
          title: i18n._(t`Review cycle unarchived`),
          type: TOAST_TYPES.INFO,
        });

        // refetch data
        await setFormValues();
      }
    }
  };

  const onShareCalibrationInput = async () => {
    if (isAllowToShareCalibrationInput) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Share input?`),
        description: i18n._(
          t`Are you sure you want to share coach input with the employees? This action cannot be undone.`,
        ),
      });

      if (isConfirmed) {
        await shareCalibrationInput(reviewId);
        addToast({
          title: i18n._(t`Coach input shared!`),
          type: TOAST_TYPES.SUCCESS,
        });
      }
    }
  };

  return {
    isAdmin,
    isCreator,
    isAllowToDelete,
    isAllowToEdit,
    isAllowToArchive,
    isAllowToUnarchive,
    isAllowToShareCalibrationInput,
    isLoading,
    onArchive,
    onUnarchive,
    onDelete,
    onEdit,
    onShareCalibrationInput,
    goToReviewCycles,
  };
};
