import React from 'react';

import { REVIEW_VERSION } from '@learned/constants';
import { Trans } from '@lingui/macro';
import { UseFormReturn } from 'react-hook-form';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { GeneralSettings } from './GeneralSettings';
import { NotificationSettings } from './NotificationSettings';
import { PrivacySettings } from './PrivacySettings';

import { Form, Title } from '../../design';

import type { IReviewIndividualForm } from '../../types';
import type { IReviewTemplate } from '@learned/types';

interface SettingsSectionProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewIndividualForm>;
  reviewTemplates: IReviewTemplate[];
  version?: REVIEW_VERSION;
  isEdit?: boolean;
}

const SettingsSection = ({
  formMethods,
  reviewTemplates,
  version,
  isEdit,
}: SettingsSectionProps) => {
  return (
    <>
      <Form>
        <Title>
          <Trans>Settings</Trans>
        </Title>
        <GeneralSettings
          formMethods={formMethods}
          reviewTemplates={reviewTemplates}
          version={version}
          isEdit={isEdit}
        />
        <NotificationSettings formMethods={formMethods} />
        <PrivacySettings formMethods={formMethods} />
      </Form>
    </>
  );
};

export { SettingsSection };
