import React from 'react';

import { GOAL_PROGRESS_TYPES, GOAL_STATUSES, SYMBOLS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { StatusLabel } from '~/components/GoalsBlock/components/StatusLabel';
import ProgressBarWide from '~/components/ProgressBarWide';

import {
  CalculationText,
  CurrentValueContainer,
  GoalValueContainer,
  LabelContainer,
  ProgressBarContainer,
  ProgressContainer,
  StartValueContainer,
  StyledUpdateButton,
} from './design';
import { GoalProgressModal } from './GoalProgressModal';

import useBoolState from '~/hooks/useBoolState';
import { getTeams, getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';
import { getGoalProgressWithDecimals } from '~/utils/getGoalProgressWithDecimals';
import { isUserCanEditGoal } from '~/utils/isUserCanEditGoal';

import type { IGoal } from '@learned/types';

const GoalProgressSection = ({
  goal,
  disableUpdate,
  onGoalUpdate,
}: {
  goal: IGoal;
  disableUpdate?: boolean;
  onGoalUpdate?: () => void;
}) => {
  const $isUpdateModalVisible = useBoolState(false);
  const $isHovered = useBoolState(false);

  const { i18n } = useLingui();

  const isAvgActivity = goal.progressType === GOAL_PROGRESS_TYPES.AVG;
  const isAvgContributingGoals =
    goal.progressType === GOAL_PROGRESS_TYPES.AVG_OF_CONTRIBUTING_GOALS;
  const isNumbers = goal.progressType === GOAL_PROGRESS_TYPES.NUMBERS;
  const isPercentage = goal.progressType === GOAL_PROGRESS_TYPES.PERCENTAGE;
  const isCurrency = goal.progressType === GOAL_PROGRESS_TYPES.CURRENCY;
  const isMetric = isCurrency || isNumbers || isPercentage;

  const isBinary = goal.progressType === GOAL_PROGRESS_TYPES.IS_DONE;

  const formatProgressValue = (progress: number) => {
    return getGoalProgressWithDecimals(String(progress), goal.progressType as GOAL_PROGRESS_TYPES);
  };

  const progressValue = formatProgressValue(goal.progress || 0);
  const calculatedProgressValue = formatProgressValue(goal.calculatedProgress || 0);
  const minValue = formatProgressValue(goal.progressDetails?.min || 0);
  const maxValue = formatProgressValue(goal.progressDetails?.max || 0);

  const currentProgress =
    isAvgActivity || isAvgContributingGoals
      ? `${calculatedProgressValue}%`
      : isBinary
      ? `${progressValue}%`
      : isNumbers
      ? `${progressValue}`
      : isPercentage
      ? `${progressValue}%`
      : isCurrency
      ? `${SYMBOLS[goal.progressDetails?.currency || 'dollar']}${progressValue}`
      : `${goal.progress}`;

  const startProgress = isCurrency
    ? `${SYMBOLS[goal.progressDetails?.currency || 'dollar']}${minValue}`
    : isNumbers
    ? `${minValue}`
    : isPercentage
    ? `${minValue}%`
    : null;

  const goalProgress = isCurrency
    ? `${SYMBOLS[goal.progressDetails?.currency || 'dollar']}${maxValue}`
    : isNumbers
    ? `${maxValue}`
    : isPercentage
    ? `${maxValue}%`
    : null;

  const currentUser = useSelector(getUser);
  const teams = useSelector(getTeams);

  const isEditor = isUserCanEditGoal({ goal, user: currentUser, teams });
  const isOwner = goal.owners?.includes(currentUser?.id as string);

  const isProgressEditable =
    goal.progressType !== GOAL_PROGRESS_TYPES.AVG &&
    goal.progressType !== GOAL_PROGRESS_TYPES.AVG_OF_CONTRIBUTING_GOALS &&
    goal.status !== GOAL_STATUSES.ARCHIVED &&
    (isEditor || isOwner) &&
    !disableUpdate;

  return (
    <>
      <CalculationText>
        {goal.progressType === undefined ? (
          ''
        ) : isAvgActivity ? (
          <Trans>The goal progress is calculated by the average progress of the activities</Trans>
        ) : isAvgContributingGoals ? (
          <Trans>
            The goal progress is calculated by the average of the progress of the contributing goals
          </Trans>
        ) : (
          <Trans>The goal progress can be updated manually</Trans>
        )}
      </CalculationText>

      <ProgressContainer onMouseEnter={$isHovered.on} onMouseLeave={$isHovered.off}>
        <CurrentValueContainer>
          {currentProgress !== 'undefined' ? currentProgress.replace('.', ',') : 0}
        </CurrentValueContainer>

        <ProgressBarContainer>
          {/* @ts-ignore */}
          <ProgressBarWide
            value={goal.calculatedProgress}
            height="5px"
            rounded={true}
            isActive={true}
            style={{ width: isMetric ? '310px' : '425px' }}
            backgroundColor={COLORS.PROGRESSBAR_BACKGROUND}
          />
        </ProgressBarContainer>

        {isMetric && (
          <StartValueContainer>
            <Trans>Start</Trans>
            <br />
            {startProgress}
          </StartValueContainer>
        )}

        {isMetric && (
          <GoalValueContainer>
            <Trans>Goal</Trans>
            <br />
            {goalProgress}
          </GoalValueContainer>
        )}
        <LabelContainer isMetric={isMetric}>
          {$isHovered.value && isProgressEditable ? (
            <StyledUpdateButton
              label={i18n._(t`Update`)}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={$isUpdateModalVisible.on}
            />
          ) : (
            <StatusLabel status={goal.status} progress={goal.calculatedProgress} />
          )}
        </LabelContainer>
      </ProgressContainer>

      {$isUpdateModalVisible.value && isProgressEditable && (
        <GoalProgressModal
          onClose={$isUpdateModalVisible.off}
          goal={goal}
          isMetric={isMetric}
          isCurrency={isCurrency}
          isPercentage={isPercentage}
          isProgressEditable={isProgressEditable}
          isBinary={isBinary}
          onGoalUpdate={onGoalUpdate}
        />
      )}
    </>
  );
};

export { GoalProgressSection };
