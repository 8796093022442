import React, { useState } from 'react';

import {
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_RATING_STATUS,
  REVIEW_RATING_TYPE,
} from '@learned/constants';
import { IUser } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import {
  Answer,
  AnswerContainer,
  AnswerPlaceholder,
  AnswerWrapper,
  IconWrapper,
  Row,
  UserAvatarContainer,
  UserAvatarName,
  UserContainer,
} from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import { NotSharedLabel } from '../NotSharedLabel';

import type { IQuestionDefaultData } from '../../../../types';

interface IAvatarWrapperProps {
  id?: string;
  email?: string;
  answer?: string;
  reviewType: string;
}

const AvatarWrapper = ({ id, email, answer, reviewType }: IAvatarWrapperProps) => {
  return (
    <UserAvatarContainer $answered={!!answer}>
      {id ? <UserAvatar userId={id} /> : <UserAvatar user={{ email: email! } as IUser} />}{' '}
      <UserAvatarName>
        (<Trans>{reviewType}</Trans>)
      </UserAvatarName>
    </UserAvatarContainer>
  );
};

function TextAnswers({
  question,
  openFirstAnswerByDefault,
}: {
  question: IQuestionDefaultData;
  openFirstAnswerByDefault?: boolean;
}) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const [answers, setAnswers] = useState(
    [
      ...(question.otherCoachRatings?.map((answer) => ({
        ...answer,
        isOpened: false,
        reviewType: REVIEW_QUESTION_EVALUATORS.COACH,
      })) ?? []),
      { ...question.otherSelfRating, reviewType: 'self review', isOpened: false },
      ...(question.otherPeerRatings?.map((answer) => ({
        ...answer,
        isOpened: false,
        reviewType: REVIEW_QUESTION_EVALUATORS.PEER,
      })) ?? []),
    ].map(
      openFirstAnswerByDefault
        ? (item, index) => (index === 0 ? { ...item, isOpened: true } : item)
        : (item) => item,
    ),
  );

  const onClick = (questionId: number) => {
    setAnswers(
      answers.map((answer, index) => {
        if (questionId === index) {
          return {
            ...answer,
            isOpened: !answer.isOpened,
          };
        }
        return answer;
      }),
    );
  };

  return (
    <>
      {answers?.map((rating, index) => (
        <Row key={index}>
          <AnswerContainer clickable onClick={() => onClick(index)} $answered={!!rating?.answer}>
            <UserContainer>
              <Icon
                transform={rating.isOpened ? 'rotate(90)' : 'rotate(0)'}
                size={ICON_SIZES.MEDIUM}
                icon={ICONS.NEXT}
                color={rating.isOpened ? COLORS.COMPANY : COLORS.INACTIVE}
              />
              <div>
                <AvatarWrapper
                  key={index}
                  id={rating.createdBy?.id}
                  answer={rating?.answer as string}
                  email={rating.createdBy?.email}
                  reviewType={rating.reviewType}
                />
              </div>
            </UserContainer>
            <ToolTip
              disabled={
                !(
                  rating.status === REVIEW_RATING_STATUS.CALIBRATE &&
                  question.yourRating.type === REVIEW_RATING_TYPE.COACH
                )
              }
              key={index}
              placement={TOOLTIP_PLACEMENTS.TOP}
              tooltip={i18n._(t`This input is not yet shared with the employee`)}
            >
              <IconWrapper $answered={!!rating?.answer}>
                {rating.status === REVIEW_RATING_STATUS.CALIBRATE &&
                  question.yourRating.type === REVIEW_RATING_TYPE.COACH && <NotSharedLabel />}
                <Icon icon={ICONS.TEXT_ANSWER} size={ICON_SIZES.LARGE} />
              </IconWrapper>
            </ToolTip>
          </AnswerContainer>
          {rating.isOpened && (
            <AnswerWrapper>
              {rating?.answer ? (
                <Answer html={getMultiLangString((rating?.answer as string) || '')} />
              ) : (
                <AnswerPlaceholder>
                  {rating?.answer === null ? (
                    <Trans>Answered this question with N/A</Trans>
                  ) : (
                    <Trans>No answer provided yet</Trans>
                  )}
                </AnswerPlaceholder>
              )}
            </AnswerWrapper>
          )}
        </Row>
      ))}
    </>
  );
}

export { TextAnswers };
