import React, { useEffect, useState } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_THEME_STATUS,
} from '@learned/constants';
import {
  IMultiLangString,
  IReviewQuestion,
  IReviewQuestionCustomSkillSettings,
  IReviewQuestionDefaultData,
  IReviewQuestionGoalPlanSettings,
  IReviewQuestionSkillCategorySettings,
  ISkillCategory,
  WithPartial,
} from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import DashboardHeader from '~/components/DashboardHeader';
import { IconNames } from '~/components/IconOld';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import BaseLayout from '~/layouts/BaseLayout';
import { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';
import { QuestionModal } from '~/pages/ReviewThemeSetup/components/QuestionModal';
import { QuestionPreviewModal } from '~/pages/ReviewThemeSetup/components/QuestionPreviewModal';
import { SkillModal } from '~/pages/ReviewThemeSetup/components/SkillModal';
import {
  IQuestionForm,
  ISelectedSkill,
  type ISkill,
  IThemeQuestionDefaultData,
} from '~/pages/ReviewThemeSetup/types';
import {
  convertQuestionOptions,
  getEvaluators,
  getLabelsForAvailableLanguages,
  getRatingLabels,
} from '~/pages/ReviewThemeSetup/utils';

import { HeaderActions } from './HeaderActions';
import { MainInfoBlock } from './MainInfoBlock';
import { QuestionsBlock } from './QuestionsBlock';
import { SubHeader } from './SubHeader';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getCompanySettings } from '~/selectors/companySettings';
import { getSkillCategories } from '~/services/skillCategories';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { DASHBOARD_TYPE } from '../constants';
import { DashboardWrapper } from '../design';

import type { PopulatedReviewTheme } from '../types';

interface IThemeTemplateDashboard {
  differentRatingScales?: string[];
  dashboardType: DASHBOARD_TYPE;
  onPublish?: () => Promise<void>;
  showPreview?: () => void;
  onEdit: () => void;
  isLoading: boolean;
  item: PopulatedReviewTheme | PopulatedReviewTemplate | undefined;
  isPublishDisabled?: boolean;
  deleteQuestion?: (questionId: string) => void;
  updateQuestion: (
    question: Omit<WithPartial<IReviewQuestion, 'name' | 'type'>, 'company' | 'meta'>,
  ) => void;
  showTemplates?: boolean;
  isTheme?: boolean;
}

const ThemeTemplateDashboard = ({
  differentRatingScales,
  dashboardType,
  onPublish,
  item,
  onEdit,
  isLoading,
  isPublishDisabled,
  showPreview,
  deleteQuestion,
  updateQuestion,
  isTheme = false,
  showTemplates = true,
}: IThemeTemplateDashboard) => {
  const { i18n } = useLingui();
  const location = useLocation();
  const languageState = useLanguageState(true);
  const multiLanguageState = useLanguageState(false);
  const getString = useMultiLangString();
  const { languages } = languageState;
  const currentCompany = useSelector(getCompanySettings);
  const [previewQuestion, setPreviewQuestion] = useState<
    | (IReviewQuestion & { themeName?: IMultiLangString } & { themeIcon?: IconNames } & {
        themeIconColor?: string;
      } & {
        skillCategory?: ISkillCategory;
      })
    | undefined
  >();
  const [editQuestion, setEditQuestion] = useState<IQuestionForm | null | undefined>(undefined);
  const [skillCategories, setSkillCategories] = useState<
    { value: string; label: Record<string, string>; levels: number; type: string }[]
  >([]);
  const [selectedSkills, setSelectedSkills] = useState<ISelectedSkill[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<ISkill | null>(null);
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [selectedTheme, setSelectedTheme] = useState(undefined);
  const $isSkillModalOpen = useBoolState(false);
  const itemName = isEmpty(item) ? (
    !isLoading ? (
      <Trans>No data</Trans>
    ) : (
      ''
    )
  ) : (
    (item as PopulatedReviewTheme)?.name[languages[0].locale] || (
      <Trans>No data in selected language</Trans>
    )
  );

  useEffect(() => {
    const fetchSkillCategories = async () => {
      const response = await getSkillCategories();
      setSkillCategories(
        Object.values(response).map((skillCatgory) => {
          const category = skillCatgory as {
            id: string;
            name: Record<string, string>;
            skillLevels: Record<string, string>[];
            type: string;
          };
          return {
            value: category.id,
            label: category.name,
            levels: category.skillLevels?.length,
            type: category.type,
          };
        }),
      );
    };

    fetchSkillCategories();
  }, [currentCompany]);

  const goBack = () => {
    routes.REVIEWS.go({}, { hash: location.hash.substring(1) });
  };

  const handleDeleteFromQuestionModal = () => {
    if (editQuestion) {
      deleteQuestion?.(editQuestion?.id || '');
      setEditQuestion(undefined);
    }
  };

  const handleSubmit = (e: IQuestionForm) => {
    const evaluators = Object.keys(e.settings.evaluators)
      .map((key: string) =>
        e.settings?.evaluators?.[key as keyof typeof e.settings.evaluators] ? key : null,
      )
      .filter(Boolean);
    const questionToEdit = {
      id: e.id as string,
      type: e.type.key as REVIEW_QUESTION_TYPES,
      theme: selectedTheme === 'OTHER' ? '' : (selectedTheme as unknown as string),
      name: convertLanguageValue(e.name),
      description: convertLanguageValue(e.description),
      settings: {
        options: e?.settings?.isManualScale ? getRatingLabels(languageState, e?.options) : null,
        evaluators:
          e.type.key === REVIEW_QUESTION_TYPES.GOAL_PLAN
            ? [REVIEW_QUESTION_EVALUATORS.EMPLOYEE, REVIEW_QUESTION_EVALUATORS.COACH] // for plan-goal - always employee and coach (not editable)
            : evaluators,
        isCommentsAllowed: e.settings.isCommentsAllowed,
        isCommentsObligated: e.settings.isCommentsObligated,
        isAnswerObligated: !e.settings.isAnswerObligated,
        isMeasurementReversed: e.settings.isMeasurementReversed,
        isManualScale: e.settings.isManualScale,
        skillCategory:
          e.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY && e.skillOrKpiCategory?.id,
        skills: e.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && e.settings.skills,
        subTypes: e.settings.subTypes,
      },
      skillCategory: {
        id: e.skillOrKpiCategory?.id,
        name: {
          [languageState.companyPrimaryLanguage.locale]: e.skillOrKpiCategory?.value,
        },
        type: e.skillOrKpiCategory?.type,
      },
    };

    if (editQuestion) {
      updateQuestion(questionToEdit);
      setEditQuestion(undefined);
    }
  };

  const setQuestionToEdit = (question: IReviewQuestion) => {
    if (!question) {
      return;
    }
    const nameLabels = turnMultiLangIntoArray(question.name, multiLanguageState.companyLanguages);
    const descriptionLabels = turnMultiLangIntoArray(
      question.description || {},
      multiLanguageState.companyLanguages,
    );
    setEditQuestion({
      id: question.id,
      name: getLabelsForAvailableLanguages(multiLanguageState, nameLabels),
      description: getLabelsForAvailableLanguages(multiLanguageState, descriptionLabels),
      theme: question?.theme,
      // @ts-ignore
      type: question?.type,
      settings: {
        isCommentsAllowed:
          (question.settings as IThemeQuestionDefaultData)?.isCommentsAllowed ?? false,
        isCommentsObligated:
          (question.settings as IThemeQuestionDefaultData)?.isCommentsObligated ?? false,
        isMeasurementReversed:
          (question.settings as IThemeQuestionDefaultData)?.isMeasurementReversed ?? false,
        isManualScale: (question.settings as IThemeQuestionDefaultData)?.isManualScale ?? false,
        evaluators: getEvaluators(
          (question.settings as IThemeQuestionDefaultData)
            .evaluators as REVIEW_QUESTION_EVALUATORS[],
        ),
        skillCategory:
          (question.settings as IReviewQuestionSkillCategorySettings)?.skillCategory || '',
        skills: (question.settings as IReviewQuestionCustomSkillSettings)?.skills,
        isAnswerObligated:
          !(question.settings as IReviewQuestionDefaultData)?.isAnswerObligated || false,
        subTypes: (question.settings as IReviewQuestionGoalPlanSettings)?.subTypes || undefined,
      },
      options: convertQuestionOptions(question, languageState.companyLanguages),
    });
  };

  const handleSelectedSkillSubmit = (selectedSkill: ISelectedSkill) => {
    setSelectedSkills([
      ...selectedSkills.filter(({ skill }: ISelectedSkill) => skill !== selectedSkill.skill),
      selectedSkill,
    ]);
    $isSkillModalOpen?.toggle();
  };

  return (
    <>
      <DashboardHeader
        title={itemName}
        onBack={goBack}
        height="108px"
        actions={
          <HeaderActions
            isDraft={item?.status === REVIEW_THEME_STATUS.DRAFT}
            onPublish={onPublish}
            onEdit={onEdit}
            isLoading={isLoading}
            languageState={languageState}
            showPreview={showPreview}
            isPublishDisabled={isPublishDisabled}
            item={item}
            dashboardType={dashboardType}
          />
        }
        subHeader={
          item?.status === REVIEW_THEME_STATUS.DRAFT && <SubHeader dashboardType={dashboardType} />
        }
      />
      <BaseLayout maxWidth="750px" loading={isLoading}>
        {!isEmpty(item) && (
          <DashboardWrapper>
            <MainInfoBlock item={item} dashboardType={dashboardType} locale={languages[0].locale} />
            <QuestionsBlock
              differentRatingScales={differentRatingScales || []}
              // @ts-ignore
              questions={item.questions}
              languageState={languageState}
              dashboardType={dashboardType}
              theme={isTheme ? (item as PopulatedReviewTheme) : undefined}
              showTemplates={showTemplates}
              // @ts-ignore
              setPreviewQuestion={setPreviewQuestion}
            />
          </DashboardWrapper>
        )}
        {!!previewQuestion && (
          <QuestionPreviewModal
            question={previewQuestion}
            // @ts-ignore
            evaluators={previewQuestion.settings.evaluators || []}
            onClose={() => setPreviewQuestion(undefined)}
            themeName={getString((isTheme ? item?.name : previewQuestion.themeName) || '')}
            themeIcon={isTheme ? (item as PopulatedReviewTheme).icon : previewQuestion.themeIcon}
            iconColor={
              isTheme ? (item as PopulatedReviewTheme).iconColor : previewQuestion.themeIconColor
            }
            onEdit={async () => {
              const isConfirmed = await confirm({
                type: CONFIRMATION_MODAL_TYPE.WARNING,
                title: i18n._(t`Be aware!`),
                description: i18n._(
                  t`Changing this question will affect all review templates that use this question.`,
                ),
              });
              if (isConfirmed) {
                setPreviewQuestion(undefined);
                setSelectedSkills(
                  (previewQuestion?.settings as IReviewQuestionCustomSkillSettings)?.skills || [],
                );
                setQuestionToEdit(previewQuestion);
              }
            }}
          />
        )}
        {editQuestion && (
          <QuestionModal
            hidePreview
            languageState={multiLanguageState}
            onClose={() => setEditQuestion(undefined)}
            onDelete={deleteQuestion && handleDeleteFromQuestionModal}
            onSubmit={handleSubmit}
            setIsSkillModalOpen={$isSkillModalOpen.set}
            skillCategories={skillCategories}
            selectedSkills={selectedSkills}
            setSelectedSkills={setSelectedSkills}
            selectTheme={true}
            selectedQuestionToEdit={editQuestion}
            selectedTheme={selectedTheme ?? null}
            setSelectedTheme={setSelectedTheme}
            // @ts-ignore
            setPreviewQuestion={setPreviewQuestion}
          />
        )}
        {$isSkillModalOpen.value && (
          <SkillModal
            onClose={() => $isSkillModalOpen.off()}
            onSubmit={handleSelectedSkillSubmit}
            setSelectedSkill={setSelectedSkill}
            selectedSkill={selectedSkill}
            skills={skills}
            setSkills={setSkills}
            selectedSkills={selectedSkills}
          />
        )}
      </BaseLayout>
    </>
  );
};

export { ThemeTemplateDashboard };
