import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 24px 32px 26px;
  margin-bottom: 46px;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.86;
  color: ${COLORS.CHARCOAL};
`;

export const TitleNone = styled.span`
  margin-left: 8px;
  color: ${COLORS.INACTIVE};
`;

export const TitleText = styled.span`
  margin-left: 8px;
  color: ${COLORS.SUBTEXT};
`;

export const RatingsGridTitle = styled.div`
  display: flex;
  padding: 20px 0;
  align-items: center;
  width: fit-content;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  margin-top: 11px;
`;
