import React, { useState } from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import compareAsc from 'date-fns/compareAsc';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import every from 'lodash/every';
import { useDispatch } from 'react-redux';

import { ButtonVariant } from '~/components/Buttons';
import { confirm } from '~/components/ConfirmDialog';
import { ICONS } from '~/components/Icon';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { UserAvatar } from '~/components/UserAvatar';
import { IEmployee } from '~/pages/Reviews/Modals/SelectGuestsModal/types';

import { NameRow, StyledTableList } from './Members.design';
import { Title, TitleBlock } from './Setup.design';
import { StartEndDateModal } from './StartEndDateModal';

import type { IColumnTable } from '~/@types/table';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { useMultiSelectState } from '~/hooks/useMultiSelectState';
import { usePagination } from '~/hooks/usePagination';
import {
  createCareerPlans,
  downloadCareerPlansCSV,
  removeCareerPlans,
} from '~/services/careerPlans';
import { removeCareerPlansFromReduxStore } from '~/store/careerPlans/actions';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';

import { SelectEmployeesModal } from '../../../pages/Reviews/EditCycle/ReviewCycleForm/components/SelectEmployeesModal';

import type { ICareerPlanPopulated, IJobProfilePopulated } from '../types';
import type { I18n } from '@lingui/core';

enum CAREER_PLAN_SORT_OPTIONS {
  NAME_A_Z = 'nameAsc',
  NAME_Z_A = 'nameDesc',
  START_DATE_NEW_OLD = 'startDateAsc',
  START_DATE_OLD_NEW = 'startDateDesc',
  END_DATE_NEW_OLD = 'endDateAsc',
  END_DATE_OLD_NEW = 'endDateDesc',
  TIME_IN_ROLE_LEAST_MOST = 'timeInRoleAsc',
  TIME_IN_ROLE_MOST_LEAST = 'timeInRoleDesc',
}

function compareUndefinedDates(a: Date | undefined, b: Date | undefined) {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined) {
    return 1;
  }
  if (b === undefined) {
    return -1;
  }
  return compareAsc(a, b);
}

function differenceInCalendarMonthsUndefined(a: Date | undefined, b: Date | undefined) {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined) {
    return 1;
  }
  if (b === undefined) {
    return -1;
  }
  return differenceInCalendarMonths(a, b);
}

const sortMethods: Record<
  CAREER_PLAN_SORT_OPTIONS,
  (a: ICareerPlanPopulated, b: ICareerPlanPopulated) => number
> = {
  [CAREER_PLAN_SORT_OPTIONS.NAME_A_Z]: (a, b) =>
    getUserFullName(a.createdFor).localeCompare(getUserFullName(b.createdFor)),
  [CAREER_PLAN_SORT_OPTIONS.NAME_Z_A]: (a, b) =>
    getUserFullName(b.createdFor).localeCompare(getUserFullName(a.createdFor)),
  [CAREER_PLAN_SORT_OPTIONS.START_DATE_OLD_NEW]: (a, b) =>
    compareUndefinedDates(a.startDate, b.startDate),
  [CAREER_PLAN_SORT_OPTIONS.START_DATE_NEW_OLD]: (a, b) =>
    compareUndefinedDates(b.startDate, a.startDate),
  [CAREER_PLAN_SORT_OPTIONS.END_DATE_OLD_NEW]: (a, b) =>
    compareUndefinedDates(a.endDate, b.endDate),
  [CAREER_PLAN_SORT_OPTIONS.END_DATE_NEW_OLD]: (a, b) =>
    compareUndefinedDates(b.endDate, a.endDate),
  [CAREER_PLAN_SORT_OPTIONS.TIME_IN_ROLE_LEAST_MOST]: (a, b) =>
    differenceInCalendarMonthsUndefined(a.endDate, a.startDate) -
    differenceInCalendarMonthsUndefined(b.endDate, b.startDate),
  [CAREER_PLAN_SORT_OPTIONS.TIME_IN_ROLE_MOST_LEAST]: (a, b) =>
    differenceInCalendarMonthsUndefined(b.endDate, b.startDate) -
    differenceInCalendarMonthsUndefined(a.endDate, a.startDate),
};

const CAREER_PLANS_COLUMNS: IColumnTable<ICareerPlanPopulated>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Member`),
    accessor: 'member',
    maxWidth: '210px',
    minWidth: '210px',
    renderCell: (item) => {
      return (
        <NameRow>
          <UserAvatar userId={item.createdFor.id} />
        </NameRow>
      );
    },
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Start date`),
    accessor: 'startDate',
    maxWidth: '140px',
    minWidth: '140px',
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.START_DATE_NEW_OLD,
        label: (i18n: I18n) => i18n._(t`New - old`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.START_DATE_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Old - new`),
      },
    },
    renderCell: (item) => {
      return (
        <NameRow $isInactive={item.isInactiveUser}>
          {convertToTimeString(item.startDate, TIME_FORMATS.CLASSIC)}
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`End date`),
    accessor: 'endDate',
    maxWidth: '130px',
    minWidth: '130px',
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.END_DATE_NEW_OLD,
        label: (i18n: I18n) => i18n._(t`New - old`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.END_DATE_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Old - new`),
      },
    },

    renderCell: (item) => {
      return (
        <NameRow $isInactive={item.isInactiveUser}>
          {item.endDate ? convertToTimeString(item.endDate, TIME_FORMATS.CLASSIC) : ''}
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Time in role`),
    accessor: 'meta.createdDate',
    maxWidth: '140px',
    minWidth: '140px',
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.TIME_IN_ROLE_LEAST_MOST,
        label: (i18n: I18n) => i18n._(t`Least - most`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.TIME_IN_ROLE_MOST_LEAST,
        label: (i18n: I18n) => i18n._(t`Most - least`),
      },
    },
    renderCell: (item) => {
      const timeInMonths = item.startDate
        ? differenceInCalendarMonths(item.endDate ?? new Date(), item.startDate)
        : undefined;
      const timeInYears = timeInMonths ? Math.floor(timeInMonths / 12) : undefined;

      let displayTime = <></>;
      if (timeInYears && timeInYears > 0) {
        displayTime =
          timeInYears === 1 ? (
            <Trans>{timeInYears} year</Trans>
          ) : (
            <Trans>{timeInYears} years</Trans>
          );
      } else if (timeInMonths !== undefined) {
        displayTime =
          timeInMonths === 1 ? (
            <Trans>{timeInMonths} month</Trans>
          ) : (
            <Trans>{timeInMonths} months</Trans>
          );
      }

      return <NameRow $isInactive={item.isInactiveUser}>{displayTime}</NameRow>;
    },
  },
];

function Members({
  jobProfile,
  careerPlans,
  refetchCareerPlans,
}: {
  jobProfile: IJobProfilePopulated;
  careerPlans: ICareerPlanPopulated[];
  refetchCareerPlans: () => void;
}) {
  const { addToast } = useToasts();
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();
  const { pagination, changePagination } = usePagination(20);
  const [sortBy, setSortBy] = useState<CAREER_PLAN_SORT_OPTIONS>(CAREER_PLAN_SORT_OPTIONS.NAME_A_Z);
  const [careerPlanToEdit, setCareerPlanToEdit] = useState<ICareerPlanPopulated>();
  const [showStepEmployeesModal, setShowStepEmployeesModal] = useState(false);
  const dispatch = useDispatch();

  const {
    selectedItems: selectedCareerPlans,
    onSelectItem,
    isItemChecked,
    onCheckAll,
    resetSelectedItems,
  } = useMultiSelectState(careerPlans);
  const [search, setSearch] = useState('');
  const filteredCareerPlans = careerPlans
    .filter((plan) => {
      return getUserFullName(plan.createdFor).toLowerCase().includes(search.toLowerCase());
    })
    .sort(sortMethods[sortBy])
    .slice(pagination.skip, pagination.index * pagination.limit);

  async function removeSelectedCareerPlans() {
    const textConfirm = i18n._(
      t`Are you sure you want to remove ${selectedCareerPlans.length} users from this role?`,
    );
    if (await confirm(i18n, textConfirm)) {
      await removeCareerPlans(selectedCareerPlans);
      await refetchCareerPlans();
      resetSelectedItems();
      // need to update redux store and remove the career plan
      dispatch(removeCareerPlansFromReduxStore(selectedCareerPlans));
    }
  }

  async function removeSelectedCareerPlan(id: string) {
    const textConfirm = i18n._(t`Are you sure you want to remove this user from this role?`);
    if (await confirm(i18n, textConfirm)) {
      await removeCareerPlans([id]);
      await refetchCareerPlans();
      resetSelectedItems();
      // need to update redux store and remove the career plan
      dispatch(removeCareerPlansFromReduxStore([id]));
    }
  }

  return (
    <>
      <TitleBlock>
        <Title>{getMultiLangString(jobProfile.name)}</Title>
      </TitleBlock>
      <StyledTableList
        data={filteredCareerPlans}
        columns={CAREER_PLANS_COLUMNS}
        filtersProps={{ isFiltered: true, filters: { search, setSearch } }}
        paginationProps={{ pagination, changePagination, totalCount: careerPlans?.length ?? 0 }}
        sortProps={{ sortBy, setSortBy }}
        menuProps={{
          isMenuVisible: true,
          createMenuItems: (item) => [
            {
              label: <Trans>Edit start or end date</Trans>,
              action: () => setCareerPlanToEdit(item),
              icon: ICONS.EDIT_PENCIL,
            },
            {
              icon: ICONS.DELETE_BIN,
              action: () => removeSelectedCareerPlan(item.id),
              isWarning: true,
              label: <Trans>Delete from role</Trans>,
            },
          ],
        }}
        actionButton={{
          label: <Trans>Assign</Trans>,
          onClick: () => {
            setShowStepEmployeesModal(true);
          },
          variant: ButtonVariant.PRIMARY,
          icon: ICONS.ADD_PLUS,
        }}
        secondaryButton={{
          label: <Trans>Export csv</Trans>,
          onClick: async () => {
            addToast({
              title: i18n._(t`Exporting CSV`),
              subtitle: i18n._(
                t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
              ),
              type: TOAST_TYPES.INFO,
            });
            await downloadCareerPlansCSV(jobProfile.id);
          },
          variant: ButtonVariant.SECONDARY,
          icon: ICONS.IMPORT,
        }}
        multiSelectProps={{
          isMultiSelectVisible: true,
          multiSelect: {
            checkedCount: selectedCareerPlans.length,
            isAllChecked: every(careerPlans.map((plans) => selectedCareerPlans.includes(plans.id))),
            onSelectItem,
            isItemChecked,
            onCheckAll,
            onDelete: () => removeSelectedCareerPlans(),
            onDeleteButtonText: i18n._(t`Remove from role`),
          },
        }}
      />
      {careerPlanToEdit && (
        <StartEndDateModal
          careerPlan={careerPlanToEdit}
          onClose={(shouldRefetch = false) => {
            setCareerPlanToEdit(undefined);
            if (shouldRefetch) {
              refetchCareerPlans();
            }
          }}
        />
      )}
      {showStepEmployeesModal && (
        <SelectEmployeesModal
          usersToHide={careerPlans.map((plan) => plan.createdFor.id)}
          onSave={async (employees: IEmployee[]) => {
            const jobsData = employees.map((employee) => ({
              jobProfileId: jobProfile.id,
              status: CAREER_PLAN_STATUSES.CURRENT,
              userId: employee.id,
            }));

            await createCareerPlans(jobsData);
            await refetchCareerPlans();
          }}
          onClose={() => {
            setShowStepEmployeesModal(false);
          }}
        />
      )}
    </>
  );
}

export { Members };
