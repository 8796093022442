import React, { Dispatch, SetStateAction, useMemo } from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_QUESTION_TYPES_V1 } from '@learned/constants';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';
import { SkillQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/SkillQuestion';
import type {
  AnswerReviewForm,
  IPopulatedReviewTask,
  IQuestionCustomSkillData,
  IQuestionCustomSkillV1Data,
  IQuestionData,
  IQuestionDefaultData,
  IQuestionGoalPlanData,
} from '~/pages/ReviewGiveFeedback/types';

import { OldSkillQuestion } from './Questions/OldSkillQuestion';
import { PlanGoalQuestion } from './Questions/PlanGoalQuestion';
import { RatingQuestion } from './Questions/RatingQuestion';
import { TextAreaQuestion } from './Questions/TextAreaQuestion';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

import type { IUserReview, IUserReviewPopulated } from '@learned/types';

interface IQuestionView {
  questionData: IQuestionData;
  languageState: ILanguageStateReturn;
  className?: string;
  ratings: AnswerReviewForm['ratings'];
  reviewTask?: IPopulatedReviewTask;
  onChange?: (data: {
    questionId: string;
    oldAnswer?: string | null | number;
    answer?: string | null | number;
    comment?: string;
    isNotApplicable?: boolean;
  }) => void;
  onBlurTextArea?: () => void;
  hasError?: boolean;
  hasCommentError?: boolean;
  showOtherRatings?: boolean;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  goalsPlanned?: IUserReview['goalsPlanned'];
  setGoalsPlanned: Dispatch<SetStateAction<IUserReview['goalsPlanned'] | undefined>>;
  userReview?: IUserReviewPopulated;
}

export interface WithComments {
  comment?: string;
}

const Container = styled.div`
  margin-top: 66px;
  min-width: 750px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-content: center;
`;

const AnswerView = styled.div<{ overflow?: string }>`
  width: fit-content;
  min-width: 750px;
  padding: 23px 33px;
  background: ${COLORS.WHITE};
  overflow: ${({ overflow }) => overflow || 'auto'};
  margin-bottom: 88px;
  border-radius: 6px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.18;
  letter-spacing: -0.24px;
  color: ${COLORS.ICONS_PRIMARY};
  margin-bottom: 10px;
`;

const Description = styled.div`
  .showMore {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  p {
    width: 750px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.63;
    color: ${COLORS.SUBTEXT};
  }
`;

const ThemeTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 2.17;
  letter-spacing: -0.13px;
  color: ${COLORS.SUBTEXT};
`;

const HeaderWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const QuestionView = ({
  languageState,
  className,
  questionData,
  ratings,
  onChange,
  reviewTask,
  hasError,
  hasCommentError,
  showOtherRatings,
  useMultiLangString,
  onBlurTextArea,
  setGoalsPlanned,
  goalsPlanned,
  userReview,
}: IQuestionView) => {
  const getMultiLangString = useMultiLangString();
  const isExternalPeer = reviewTask?.userTo?.email;

  const prepareQuestion = (
    questionType: REVIEW_QUESTION_TYPES | REVIEW_QUESTION_TYPES_V1,
    questionData: IQuestionData,
  ) => {
    switch (questionType) {
      case REVIEW_QUESTION_TYPES.TEXT: {
        const textData = questionData as IQuestionDefaultData;
        const relevantRating = ratings.find((rating) => rating.question === textData.question.id);
        return (
          <TextAreaQuestion
            key={textData.question.id}
            onChange={onChange}
            onBlur={onBlurTextArea}
            question={textData as IQuestionDefaultData}
            languageState={languageState}
            // @ts-ignore
            questionData={textData.question}
            canAnswer={textData.canAnswer}
            defaultValues={{
              answer: (relevantRating?.answer as string | null) ?? '',
              isNotApplicable: relevantRating?.isNotApplicable ?? false,
            }}
            hasError={hasError}
            showOtherRatings={showOtherRatings}
          />
        );
      }
      case REVIEW_QUESTION_TYPES.RATING: {
        const ratingData = questionData as IQuestionDefaultData;
        const relevantRating = ratings.find((rating) => rating.question === ratingData.question.id);
        return (
          <RatingQuestion
            key={ratingData.question.id}
            onChange={onChange}
            question={ratingData as IQuestionDefaultData}
            canAnswer={ratingData.canAnswer}
            languageState={languageState}
            // @ts-ignore
            questionData={ratingData.question}
            defaultValues={{
              answer: relevantRating?.answer?.toString() ?? '',
              comment: (relevantRating?.comment as string | null) ?? '',
              isNotApplicable: relevantRating?.isNotApplicable ?? false,
            }}
            hasError={hasError}
            hasCommentError={hasCommentError}
            showOtherRatings={showOtherRatings}
            useMultiLangString={useMultiLangString}
          />
        );
      }
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY: {
        const skillQuestionData = questionData as IQuestionCustomSkillData;
        const relevantRatings = ratings.filter((rating) =>
          skillQuestionData.subQuestions.map((sq) => sq.question.id).includes(rating.question),
        );

        return (
          <SkillQuestion
            useMultiLangString={useMultiLangString}
            key={skillQuestionData.questionNumber}
            // @ts-ignore
            onChange={onChange}
            languageState={languageState}
            canAnswer={skillQuestionData.canAnswer}
            focusAreaQuestions={skillQuestionData.subQuestions}
            defaultValues={{
              answers: relevantRatings.map((rating) => ({
                value: (rating.answer as number) ?? undefined,
              })),
              isNotApplicable: relevantRatings[0]?.isNotApplicable ?? false,
              comment: relevantRatings[0]?.comment ?? '',
            }}
            hasError={hasError}
            hasCommentError={hasCommentError}
            showOtherRatings={showOtherRatings}
          />
        );
      }
      case REVIEW_QUESTION_TYPES.GOAL_PLAN: {
        const planGoalQuestionData = questionData as IQuestionGoalPlanData;
        return (
          !isExternalPeer && (
            <PlanGoalQuestion
              key={planGoalQuestionData.question.id}
              userFrom={reviewTask?.userFrom}
              subTypes={planGoalQuestionData.question.settings.subTypes}
              setGoalsPlanned={setGoalsPlanned}
              goalsPlanned={goalsPlanned}
              userReview={userReview}
            />
          )
        );
      }
      case REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1:
      case REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1: {
        const oldSkillQuestionData = questionData as IQuestionCustomSkillV1Data;
        const relevantRating = ratings.find((rating) =>
          oldSkillQuestionData.subQuestions.map((sq) => sq.question.id).includes(rating.question),
        );

        return (
          <OldSkillQuestion
            key={oldSkillQuestionData.questionNumber}
            onChange={onChange}
            question={oldSkillQuestionData as IQuestionCustomSkillV1Data}
            canAnswer={oldSkillQuestionData.canAnswer}
            languageState={languageState}
            defaultValues={{
              oldAnswer: relevantRating?.oldAnswer?.toString(),
              isNotApplicable: relevantRating?.isNotApplicable ?? false,
              comment: relevantRating?.comment ?? '',
            }}
            hasError={hasError}
            hasCommentError={hasCommentError}
            showOtherRatings={showOtherRatings}
            useMultiLangString={useMultiLangString}
          />
        );
      }
      default:
        return null;
    }
  };

  const description = useMemo(() => {
    return getMultiLangString(questionData?.questionDescription || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(questionData?.questionDescription)]);

  return (
    <Container className={className}>
      <HeaderWrapper>
        <ThemeTitle>{`${questionData.themeName} | Question: ${questionData.questionNumber}`}</ThemeTitle>
        <Title>{questionData.questionTitle}</Title>
        {!isEmpty(description) && (
          <Description>
            {/* added questionNumber to key prop to recognize the children and calculate height */}
            {/* @ts-ignore */}
            <ShowMore showMoreClassName="showMore" maxHeight={50} key={questionData.questionNumber}>
              <RickTextView html={description} />
            </ShowMore>
          </Description>
        )}
      </HeaderWrapper>
      <AnswerView
        overflow={questionData.type === REVIEW_QUESTION_TYPES.GOAL_PLAN ? 'unset' : 'auto'}
      >
        {prepareQuestion(questionData.type, questionData)}
      </AnswerView>
    </Container>
  );
};

export { QuestionView };
