import React from 'react';

import Tippy, { useSingleton } from '@tippyjs/react';
import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

import type { TippyProps } from '@tippyjs/react';

/**
 * DEFAULT fontsize: 12; padding 2px 8px
 * DEFAULT_TL (DEFAULT_TEXT_LARGE)  fontsize: 14; padding 2px 8px
 * BIG fontsize: 12; padding 12px 20px
 * MEDIUM fontsize: 12; padding 8px 12px
 */
export enum TOOLTIP_SIZES {
  DEFAULT,
  DEFAULT_TL,
  BIG,
  MEDIUM,
}

export enum TOOLTIP_PLACEMENTS {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export interface ITippyProps extends TippyProps {
  children?: React.ReactElement;
  tooltip?: React.ReactNode;
  maxWidth?: string;
  size?: TOOLTIP_SIZES;
  placement?: TOOLTIP_PLACEMENTS;
}

const TippyContent = styled.div<{ maxWidth?: string }>`
  word-break: break-word;
  white-space: pre-wrap;
  max-width: ${(props) => `${props.maxWidth}` || '200px'};
`;
const getContentStyle = (size: TOOLTIP_SIZES) => {
  switch (size) {
    case TOOLTIP_SIZES.DEFAULT_TL: {
      return 'font-size: 14px; padding: 2px 8px;';
    }
    case TOOLTIP_SIZES.BIG: {
      return 'font-size: 12px; padding: 12px 20px;';
    }
    case TOOLTIP_SIZES.MEDIUM: {
      return 'font-size: 12px; padding: 8px 12px;';
    }
    default: {
      return 'font-size: 12px; padding: 2px 8px;';
    }
  }
};

const StyledTippy = styled(Tippy)<{ $size: TOOLTIP_SIZES }>`
  background-color: ${COLORS.TOOLTIP_BACKGROUND} !important;
  ${({ $size }) =>
    $size === TOOLTIP_SIZES.DEFAULT_TL &&
    css`
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
    `}

  .tippy-arrow {
    color: ${COLORS.TOOLTIP_BACKGROUND};
  }
  .tippy-content {
    ${({ $size }) => getContentStyle($size)};
  }
`;

const opts = {
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
      },
    },
  ],
};

const ToolTip = ({
  children,
  maxWidth = '200px',
  tooltip,
  placement = TOOLTIP_PLACEMENTS.TOP,
  size = TOOLTIP_SIZES.DEFAULT,
  ...props
}: ITippyProps) =>
  tooltip ? (
    <StyledTippy
      content={tooltip ? <TippyContent maxWidth={maxWidth}>{tooltip}</TippyContent> : null}
      popperOptions={opts}
      $size={size}
      placement={placement}
      {...props}
    >
      {children && children}
    </StyledTippy>
  ) : (
    (children as JSX.Element)
  );

export default ToolTip;
export { useSingleton };
