import React from 'react';

import styled, { css } from 'styled-components';

import { ColorByPercentage } from '~/components/TableGrid';

import { COLORS } from '~/styles';

const CellBlock = styled.div<{ onClick?: () => void }>`
  height: 60px;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  &:hover {
    font-weight: bold;
  }
`;

const TotalCountLable = styled.span`
  color: ${COLORS.PLACEHOLDERS};
`;

const NoColor = styled.div<{ $isLast?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: ${COLORS.BG_LIST};
  box-sizing: border-box;

  ${({ $isLast }) =>
    $isLast &&
    css`
      background-color: ${COLORS.BG_ELEMENTS};
    `}
`;

type TCellProps = {
  isWeightCell?: boolean;
  isLast?: boolean;
  value: string | null;
  children?: React.ReactNode;
  noColor?: boolean;
  onClick?: () => void;
  ratingLabelsCount?: number;
};

function Cell({
  value,
  noColor = false,
  onClick,
  ratingLabelsCount,
  isLast,
  isWeightCell,
}: TCellProps) {
  return (
    <CellBlock onClick={onClick}>
      {noColor || Number(value) === 0 || isNaN(Number(value)) ? (
        <NoColor $isLast={isLast}>
          {value ? (
            <span>
              {value?.toString().replace('.', ',')}
              {isWeightCell ? '%' : ''}
              {ratingLabelsCount ? <TotalCountLable>/{ratingLabelsCount}</TotalCountLable> : ''}
            </span>
          ) : null}
        </NoColor>
      ) : !isNaN(Number(value)) && ratingLabelsCount ? (
        <ColorByPercentage value={(Number(value) * 100) / ratingLabelsCount}>
          {value?.toString().replace('.', ',')}
          <TotalCountLable>/{ratingLabelsCount}</TotalCountLable>
        </ColorByPercentage>
      ) : null}
    </CellBlock>
  );
}

export { Cell };
