import React, { ChangeEvent, useRef, ReactNode } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { Button, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { File, FilesWrapper, Header, IconWrapper, Placeholder, Title, Wrapper } from './design';

import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

import type { IFile } from '@learned/types';

interface AttachmentsProps {
  attachments: IFile[];
  onDelete: (fileId: IFile['id']) => void;
  onUpload: (file?: File) => void;
  isAdmin: boolean;
  isAttachmentHandleDisabled?: boolean;
  disabledAttachmentUploadToolTip?: ReactNode;
}

const Attachments = ({
  isAdmin,
  attachments,
  onDelete,
  onUpload,
  isAttachmentHandleDisabled,
  disabledAttachmentUploadToolTip,
}: AttachmentsProps) => {
  const { i18n } = useLingui();
  const inputRef = useRef<HTMLInputElement>();
  const currentUser = useSelector(getUser);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onUpload(e.target.files?.[0]);
  };

  return (
    <Wrapper>
      <Header>
        <Title>
          <IconWrapper>
            <Icon icon={ICONS.UPLOAD} size={ICON_SIZES.SMALL} />
          </IconWrapper>
          <Trans>Attachments</Trans>
          <div className="description">
            <Trans>Recommended formats: PDF, CSV, JPG, PNG, EPS (max 5 MB)</Trans>
          </div>
        </Title>
        <Button
          variant={ButtonVariant.SECONDARY}
          label={i18n._(t`Upload file(s)`)}
          onClick={() => {
            inputRef?.current?.click();
          }}
          disabled={isAttachmentHandleDisabled}
          tooltip={isAttachmentHandleDisabled ? disabledAttachmentUploadToolTip : ''}
        />
        <input
          // @ts-ignore
          ref={inputRef}
          onInput={onChange}
          type="file"
          accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,.pdf,.doc,.docx,.xml,.pages"
          hidden
        />
      </Header>
      {isEmpty(attachments) ? (
        <Placeholder color={COLORS.PLACEHOLDERS}>
          <Trans>There are no attachments uploaded</Trans>
        </Placeholder>
      ) : (
        <FilesWrapper>
          {attachments.map(({ id, name, url, user }) => (
            <File key={id}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {name}
              </a>

              {(isAdmin || currentUser.id === user) && !isAttachmentHandleDisabled && (
                <Button
                  className="delete"
                  variant={ButtonVariant.ICON_DELETE}
                  onClick={() => {
                    onDelete(id);
                  }}
                />
              )}
            </File>
          ))}
        </FilesWrapper>
      )}
    </Wrapper>
  );
};

export { Attachments };
