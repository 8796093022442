import React from 'react';

import { API_RETURN_FIELDS, NEXT_STEP_TYPES } from '@learned/constants';

import { NextSteps } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/NextSteps';
import { IReviewNextStep } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/types';

import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { getRelevantSteps } from '~/services/nextSteps';

const NextStepsPDFView = ({ userReviewId }: { userReviewId: string }) => {
  const [nextSteps] = useAsyncFetch<IReviewNextStep[]>({
    fetch: async () => {
      const { data } = await getRelevantSteps({
        targetId: userReviewId,
        type: NEXT_STEP_TYPES.REVIEW,
      });
      return data[API_RETURN_FIELDS.CURRENT_CONVO_STEPS].reverse();
    },
    initialState: [],
  });

  return (
    <NextSteps nextSteps={nextSteps} onOpenMySummary={() => {}} onCheck={() => {}} isPDFView />
  );
};

export default NextStepsPDFView;
