import React from 'react';

import UserDefaultAvatar from '~/assets/user-default.svg';

import { AnonymousCircle, AvatarContainer, DeletedCircle, StyledImage } from '../design';

import type { IUser } from '@learned/types';

// we need forwardRef for Tooltip
const Avatar = React.forwardRef(
  (
    {
      imgUrl,
      isAnonymous,
      isDeleted,
      className,
      onClick,
    }: {
      imgUrl?: string;
      isAnonymous?: boolean;
      isDeleted?: boolean;
      className?: string;
      onClick?: (user: IUser) => void | undefined;
    },
    ref,
  ) => {
    return (
      // @ts-ignore
      <AvatarContainer ref={ref} className={`avatar ${className || ''}`} onClick={onClick}>
        {isDeleted && <DeletedCircle />}
        {isAnonymous && !isDeleted && <AnonymousCircle />}
        {!isAnonymous && !isDeleted && (
          <StyledImage src={imgUrl ?? UserDefaultAvatar} alt="User avatar" />
        )}
      </AvatarContainer>
    );
  },
);

export { Avatar };
