import styled from 'styled-components';

import { ButtonPrimary, ButtonWhite } from '~/components/Button';

import { COLORS } from '~/styles';

export const Wrapper = styled.form`
  justify-content: center;
  border-radius: 6px;
  padding: 24px 32px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > button {
    padding: none;
    width: 22px;
    height: 22px;
  }
`;

export const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  margin-top: 0;
`;

export const QuestionTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.18;
  letter-spacing: -0.24px;
  color: ${COLORS.ICONS_PRIMARY};
  margin-bottom: 10px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  & .section {
    display: flex;
    gap: 20px;
  }
`;

export const PreviewButton = styled.button`
  font-size: 12px;
  height: 32px;
  border-radius: 100px;
  min-width: 83px;
  text-align: center;
  border: none;
  cursor: pointer;
  color: ${COLORS.COMPANY};
  &:disabled {
    color: ${COLORS.INACTIVE_HARD};
    cursor: default;
  }
`;

export const NeedHelp = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  height: 32px;
  border-radius: 100px;
  min-width: 83px;
  text-align: center;
  border: none;
  cursor: pointer;
  color: ${COLORS.COMPANY};
`;

export const CancelButton = styled(ButtonWhite)`
  font-size: 12px;
  height: 32px;
  border-radius: 100px;
  min-width: 83px;
  text-align: center;
  border: none;
`;

export const SubmitButton = styled(ButtonPrimary)`
  font-size: 12px;
  height: 32px;
  border-radius: 100px;
  min-width: 83px;
  text-align: center;
`;
