import React from 'react';

import { JOB_SORTING } from '@learned/constants';
import { t } from '@lingui/macro';
import Tippy from '@tippyjs/react';

import AvatarComponent from './components/AvatarComponent';
import { JobColumn } from './JobProfile.design';

import type { IColumnTable } from '~/@types/table';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { TJobProfileExtended } from './types';
import type { I18n } from '@lingui/core';

export const createJobColumns = (
  getMultiLangString: ReturnType<typeof useMultiLangString>,
): IColumnTable<TJobProfileExtended>[] => {
  const evaluateCharLength = (text?: string) => !!(text && text.length < 24);

  return [
    {
      name: (i18n: I18n) => i18n._(t`Jobs`),
      accessor: 'jobs',
      maxWidth: '200px',
      renderCell: (job: TJobProfileExtended) => {
        return (
          <JobColumn isLargerWidth>
            <Tippy
              content={getMultiLangString(job?.name)}
              offset={[18, 10]}
              disabled={evaluateCharLength(getMultiLangString(job?.name))}
            >
              <span>{getMultiLangString(job?.name)}</span>
            </Tippy>
          </JobColumn>
        );
      },
      sortBy: {
        asc: {
          key: JOB_SORTING.NAME_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: JOB_SORTING.NAME_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`Job Family`),
      accessor: 'job_family',
      maxWidth: '175px',
      renderCell: (job: TJobProfileExtended) => {
        return (
          <JobColumn>
            <Tippy
              content={job?.jobFamilyName}
              offset={[18, 10]}
              disabled={evaluateCharLength(job?.jobFamilyName)}
            >
              <span>{job?.jobFamilyName}</span>
            </Tippy>
          </JobColumn>
        );
      },
      sortBy: {
        asc: {
          key: JOB_SORTING.JOB_FAMILY_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: JOB_SORTING.JOB_FAMILY_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`Levels`),
      accessor: 'levels',
      maxWidth: '175px',
      renderCell: (job: TJobProfileExtended) => {
        return (
          <JobColumn>
            <Tippy
              content={job?.jobGroupNameWithLevel}
              offset={[18, 10]}
              disabled={evaluateCharLength(job?.jobGroupNameWithLevel)}
            >
              <span>{job?.jobGroupNameWithLevel}</span>
            </Tippy>
          </JobColumn>
        );
      },
      sortBy: {
        asc: {
          key: JOB_SORTING.LEVEL_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: JOB_SORTING.LEVEL_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`Created`),
      accessor: 'created',
      maxWidth: '175px',
      renderCell: (job: TJobProfileExtended) => {
        return (
          <JobColumn>
            <Tippy
              content={job?.createdByName}
              offset={[18, 10]}
              disabled={evaluateCharLength(job?.createdByName)}
            >
              <span>{job?.createdByName}</span>
            </Tippy>
          </JobColumn>
        );
      },
      sortBy: {
        asc: {
          key: JOB_SORTING.CREATED_BY_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: JOB_SORTING.CREATED_BY_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`Employees`),
      accessor: 'employees',
      maxWidth: '175px',
      renderCell: (job: TJobProfileExtended) => {
        return (
          <JobColumn>
            <AvatarComponent avatarArr={job?.members || []} placement="flex-start" />
          </JobColumn>
        );
      },
      sortBy: {
        asc: {
          key: JOB_SORTING.MEMBERS_HIGH_TO_LOW,
          label: (i18n: I18n) => i18n._(t`High to Low`),
        },
        desc: {
          key: JOB_SORTING.MEMBERS_LOW_TO_HIGH,
          label: (i18n: I18n) => i18n._(t`Low to High`),
        },
      },
    },
  ];
};
