import React from 'react';

import { REVIEW_VERSION } from '@learned/constants';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { InvitationSection } from './components/InvitationSection';
import { SettingsSection } from './components/SettingsSection';
import { TimelineSection } from './components/TimelineSection';
import { StepTimelineContainer } from './design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

import type { IReviewIndividualForm } from './types';
import type { IReviewInvitationTemplate, IReviewTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface StepSettingsProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewIndividualForm>;
  languageState: ILanguageStateReturn;
  invitationTemplates: Array<IReviewInvitationTemplate>;
  refreshInvitationTemplates: () => void;
  reviewTemplates: IReviewTemplate[];
  version?: REVIEW_VERSION;
  isEdit?: boolean;
}

const StepSettings = ({
  formMethods,
  sectionState,
  languageState,
  invitationTemplates,
  refreshInvitationTemplates,
  reviewTemplates,
  version,
  isEdit,
}: StepSettingsProps) => {
  return (
    <StepTimelineContainer>
      <SettingsSection
        sectionState={sectionState}
        formMethods={formMethods}
        reviewTemplates={reviewTemplates}
        version={version}
        isEdit={isEdit}
      />
      <InvitationSection
        formMethods={formMethods}
        languageState={languageState}
        invitationTemplates={invitationTemplates}
        refreshInvitationTemplates={refreshInvitationTemplates}
      />
      <TimelineSection
        sectionState={sectionState}
        formMethods={formMethods}
        languageState={languageState}
      />
    </StepTimelineContainer>
  );
};

export { StepSettings };
