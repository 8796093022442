import React from 'react';

import { IGoal } from '@learned/types';

import { THead } from '~/components/TableList/design';

import { TableWrapper } from './PDFView.design';

interface IProps {
  goals: IGoal[] | [];
}

const PDFViewGoalsGrid: React.FC<IProps> = ({ goals }) => {
  return (
    <TableWrapper>
      <THead>
        <tr>
          <th> NAME </th>
        </tr>
      </THead>
      <tbody>
        {goals.map((goal: IGoal) => (
          <tr key={goal.id}>
            <td>{goal.name}</td>
          </tr>
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default PDFViewGoalsGrid;
