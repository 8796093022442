import { createAction } from 'redux-act';

import { getCareerPlans as getCareerPlansFromDB } from '~/services/careerPlans';

export const startGetCareerPlans = createAction('start get career plans');
export const finishGetCareerPlans = createAction('finish get career plans');
export const removeCareerPlans = createAction('remove career plans');

export function getCareerPlans() {
  return function (dispatch) {
    dispatch(startGetCareerPlans());

    return getCareerPlansFromDB().then((careerPlans) => {
      dispatch(finishGetCareerPlans(careerPlans));
      return careerPlans;
    });
  };
}

/**
 * This function removes selected career plans from the Redux store.
 * @param {Array<string>} careerPlanIds - An array of career plan IDs to be removed.
 */
export function removeCareerPlansFromReduxStore(careerPlanIds) {
  return async function (dispatch) {
    dispatch(removeCareerPlans(careerPlanIds));
  };
}
