import { REVIEW_QUESTION_EVALUATORS, REVIEW_QUESTION_TYPES } from '@learned/constants';
import { IReviewQuestionDefaultData, IReviewRating, IUserReviewQuestion } from '@learned/types';
import { v4 as uuidv4 } from 'uuid';

import { IChildTransformed, IRow, IRowTransformed } from './types';

export const transformRowsToItems = (rows: IRow[]) => {
  const transformedRows: (IRowTransformed | IChildTransformed)[] = [];

  rows.forEach((row) => {
    const parentTemporalUniqueId = uuidv4();

    transformedRows.push({
      ...row,
      isCollapsed: true,
      temporalUniqueId: parentTemporalUniqueId,
    });
    if (row?.children?.length) {
      row?.children.forEach((child: any) => {
        const childTemporalUniqueId = uuidv4();
        transformedRows.push({
          ...child,
          isVisible: false,
          parent: parentTemporalUniqueId,
          temporalUniqueId: childTemporalUniqueId,
        });
      });
    }
  });

  return transformedRows;
};

export const isRatedOptionalQuestion = (
  question: IUserReviewQuestion & { reviewRatings?: IReviewRating[] },
) => {
  const isQuestionHasRating = ![
    REVIEW_QUESTION_TYPES.GOAL_PLAN,
    REVIEW_QUESTION_TYPES.TEXT,
    // eslint-disable-next-line
    // @ts-ignore
  ].includes(question.type);

  return (
    isQuestionHasRating &&
    !(question.settings as IReviewQuestionDefaultData).isAnswerObligated &&
    (question.settings as IReviewQuestionDefaultData)?.isManualScale === false &&
    (question.settings as IReviewQuestionDefaultData)?.evaluators.includes(
      REVIEW_QUESTION_EVALUATORS.COACH,
    ) &&
    question?.reviewRatings &&
    question?.reviewRatings?.filter(({ answer }) => answer !== null && answer !== -1).length > 0
  );
};
