import React from 'react';

import { REVIEW_AVERAGE_SCORE_COLUMNS, REVIEW_AVERAGE_SCORE_ROW_TYPE } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { Cell } from './Cell';
import { CoachLabel, CountLabel, PeerLabel, SelfLabel } from './design';
import { FirstCellQuestion } from './FirstCellQuestion';
import { FirstCellTheme } from './FirstCellTheme';

import { ColumnPosition } from '~/@types/table';

import { ICell, IChildTransformed, IColumn, IRow, IRowTransformed } from '../../types';

const createFirstColumn = () => {
  return {
    id: 'theme',
    name: (i18n: I18n) => i18n._(t`Theme`),
    accessor: 'theme',
    isFixed: true,
    position: ColumnPosition.LEFT,
    maxWidth: '327px',
    minWidth: '327px',
    showHeaderTooltip: true,
    renderCell: (item: IRowTransformed | IChildTransformed, onClick: () => void) => {
      return item.type === REVIEW_AVERAGE_SCORE_ROW_TYPE.THEME ? (
        <FirstCellTheme item={item as IRowTransformed} onClick={onClick} />
      ) : (
        <FirstCellQuestion item={item as IChildTransformed} onClick={onClick} />
      );
    },
  };
};

const getColumnLabel = (column: IColumn & { count?: number }) => {
  switch (column.id) {
    case REVIEW_AVERAGE_SCORE_COLUMNS.COACH:
      return (
        <span>
          <CoachLabel>{column.name}</CoachLabel>
          <CountLabel>{Number(column.count) > 1 ? ` (${column.count})` : ''}</CountLabel>
        </span>
      );
    case REVIEW_AVERAGE_SCORE_COLUMNS.PEER:
      return (
        <span>
          <PeerLabel>{column.name}</PeerLabel>
          <CountLabel>{Number(column.count) > 1 ? ` (${column.count})` : ''}</CountLabel>
        </span>
      );
    case REVIEW_AVERAGE_SCORE_COLUMNS.SELF:
      return <SelfLabel>{column.name}</SelfLabel>;
    default:
      return column.name;
  }
};

const createOtherColumns = (columns: IColumn[]) => {
  return columns.map((column, index) => {
    return {
      id: column.id,
      name: getColumnLabel(column),
      accessor: column.id,
      renderCell: (row: IRow) => {
        const cell = row.cells?.find((cell) => cell.columnId === column.id) as ICell;
        const cellIndex = row.cells?.findIndex((cell) => cell.columnId === column.id);

        return (
          <Cell
            noColor={column.id !== REVIEW_AVERAGE_SCORE_COLUMNS.COACH}
            ratingLabelsCount={index === 0 ? undefined : Number(cell?.all)}
            value={cell?.value}
            isWeightCell={column.id === REVIEW_AVERAGE_SCORE_COLUMNS.WEIGHT}
            isLast={cellIndex === row.cells.length - 1 && Number(row.id) === 1}
          />
        );
      },
      isFixed: false,
      maxWidth: '260px',
      minWidth: '92px',
      padding: '7px 10px',
      centerAlign: true,
    };
  });
};

const createColumns = (columns: IColumn[]) => {
  return [createFirstColumn(), ...createOtherColumns(columns)];
};

export { createColumns };
