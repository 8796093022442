import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_TYPES,
  ROLES,
  TASK_STATUS,
  TASK_TYPE,
  USER_REVIEW_PEER_TYPE,
  USER_REVIEW_STATUS,
} from '@learned/constants';
import {
  ICalendarEvent,
  IFile,
  IPeer,
  ITask,
  IUser,
  IUserReview,
  IUserReviewQuestion,
  JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID,
  JOIN_USER_REVIEW_BY_ID,
  POPULATE_USER_REVIEW_BY_ID,
} from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { getUser, getUsers } from '~/selectors/baseGetters';
import {
  archiveUserReviewById,
  deleteUserReviewById,
  getUserReview,
  unarchiveUserReviewById,
  updateCoaches,
  updateGuests,
  updatePeers,
} from '~/services/userReviews';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IReviewDashboardUserForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewProps {
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  userReviewId: IUserReview['id'];
}

export const useUserReview = ({ formMethods, userReviewId }: UseReviewProps) => {
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const history = useHistory();
  const $refetch = useBoolState();
  const query = useFromQuery({ includeHash: true });
  const { setValue, watch } = formMethods;
  const languageState = useLanguageState(true);
  const user = useSelector(getUser);
  const users = useSelector(getUsers);
  const [isLoading, setIsLoading] = useState(false);
  const [showNominatePeersModal, setShowNominatePeersModal] = React.useState(false);
  const [showSelectCoachesModal, setShowSelectCoachesModal] = React.useState(false);
  const [showSelectGuestsModal, setShowSelectGuestsModal] = React.useState(false);
  const [showPlanCalendarEventModal, setShowPlanCalendarEventModal] = React.useState(false);

  const [item, setItem] = useState<
    IUserReview & {
      tasks: ITask[];
      employeeTasksInOtherUserReviews: ITask[];
      userReviewQuestions: IUserReviewQuestion[];
    }
  >();

  const isAdmin = user?.isAdmin;
  const isCreator = item && user && item?.createdBy === user.id;
  const isEmployee = item && user && item?.createdFor === user.id;
  const isInputCoach = item && user && item.coaches.includes(user.id);
  const isGuest = item && user && item.guests.includes(user.id);
  const isTaskOwner = (task: ITask) => user.id && task?.userTo?.id && user.id === task?.userTo?.id;

  const signatures = watch('signatures');
  const isSigned = signatures.some(
    (signature) => signature && user && signature.userId === user.id,
  );
  const isReviewCycleType = item && item.type === REVIEW_TYPES.REVIEW_CYCLE;
  const isDraft = item?.status === USER_REVIEW_STATUS.DRAFT;
  const isUpcoming = item?.status === USER_REVIEW_STATUS.PUBLISHED;
  const isCompleted = item?.status === USER_REVIEW_STATUS.COMPLETED;
  const isSigning = item?.status === USER_REVIEW_STATUS.SIGNING;
  const isArchived = item?.status === USER_REVIEW_STATUS.ARCHIVED;

  const employeeFirstName = watch('employee')?.firstName || watch('employee')?.email;
  const isDigitalSign = item?.settings?.isDigitalSign;

  const isAllowToEditSummaryOrNextSteps =
    // if digital sign is enabled and review is not locked(signed or archived or completed)
    ((isDigitalSign && !isSigning && !isArchived && !isCompleted) ||
      //  if digital sign is not enabled and review is not archived
      (!isDigitalSign && !isArchived)) &&
    // users allow to edit
    (isCreator || isEmployee || isInputCoach || isGuest || isAdmin);

  const isEmployeeQuestions = watch('evaluators').includes(REVIEW_QUESTION_EVALUATORS.EMPLOYEE);
  const isPeerQuestions = watch('evaluators').includes(REVIEW_QUESTION_EVALUATORS.PEER);
  const isCoachQuestions = watch('evaluators').includes(REVIEW_QUESTION_EVALUATORS.COACH);

  // SELF && NOMINATE PEERS
  const isReviewBlockedForChangesWithoutUpcoming =
    isDraft || isArchived || (isDigitalSign && (isSigning || isCompleted));
  const isReviewBlockedForChanges =
    isDraft || isUpcoming || isArchived || (isDigitalSign && (isSigning || isCompleted));
  const isTaskBlockedForChanges = (task: ITask) =>
    [TASK_STATUS.DECLINED, TASK_STATUS.EXPIRED, TASK_STATUS.UPCOMING].includes(task.status);
  const isAllowToGiveSelfReview = (task: ITask) =>
    isTaskOwner(task) && !isReviewBlockedForChanges && !isTaskBlockedForChanges(task);
  const isAllowToNominatePeers = (task: ITask) =>
    (isTaskOwner(task) || isAdmin || isCreator) &&
    !isReviewBlockedForChanges &&
    !isTaskBlockedForChanges(task);
  const isAllowToGiveCoachReview = (task: ITask) =>
    isTaskOwner(task) && !isReviewBlockedForChanges && !isTaskBlockedForChanges(task);
  const isAllowToGivePeerReview = (_task: ITask) => false; // peer can not do it, just stick to structure

  // DELETE
  const isAllowToDeletePeers = (isEmployee || isAdmin || isCreator) && !isReviewBlockedForChanges;
  const isAllowToDeleteCoaches =
    (isAdmin || isCreator) && !isReviewBlockedForChangesWithoutUpcoming; // p.s. employee can not delete coaches
  const isAllowToDeleteGuests = (isAdmin || isCreator) && !isReviewBlockedForChangesWithoutUpcoming;

  // ADD
  const isAllowToAddPeers = (isEmployee || isAdmin || isCreator) && !isReviewBlockedForChanges;
  const isAllowToAddCoaches = (isAdmin || isCreator) && !isReviewBlockedForChangesWithoutUpcoming; // p.s. employeee can not add coaches
  const isAllowToAddGuests = (isAdmin || isCreator) && !isReviewBlockedForChangesWithoutUpcoming;

  // DELETE
  const isShowToDelete = isAdmin || isCreator;
  const isDisableToDelete = isReviewCycleType;
  const isAllowToDelete = isShowToDelete && !isDisableToDelete;

  // PLAN CONVERSATION
  const isAllowToPlanConversation = (isEmployee || isGuest) && !isArchived;
  const isAllowedToAccessMeetingLink =
    (isEmployee || isGuest || isAdmin || isCreator) && !isArchived;

  // EDIT
  const isShowToEdit =
    (isAdmin || isCreator) &&
    item &&
    [
      USER_REVIEW_STATUS.PUBLISHED,
      USER_REVIEW_STATUS.ACTIVE,
      USER_REVIEW_STATUS.COMPLETED,
    ].includes(item.status);
  const isDisableToEdit = isReviewCycleType;
  const isAllowToEdit = isShowToEdit && !isDisableToEdit;

  // ARCHIVE
  const isAllowToArchive =
    (isAdmin || isCreator || isInputCoach) &&
    item &&
    [USER_REVIEW_STATUS.ACTIVE, USER_REVIEW_STATUS.SIGNING, USER_REVIEW_STATUS.COMPLETED].includes(
      item.status,
    );

  // UNARCHIVE
  const isAllowToUnarchive =
    (isAdmin || isCreator || isInputCoach) && item && USER_REVIEW_STATUS.ARCHIVED === item.status;

  // EXPORT PDF
  const isAllowToExportPDF =
    item &&
    [
      USER_REVIEW_STATUS.ACTIVE,
      USER_REVIEW_STATUS.SIGNING,
      USER_REVIEW_STATUS.COMPLETED,
      USER_REVIEW_STATUS.ARCHIVED,
    ].includes(item.status);

  // DIGITAL SIGN
  const isShowToSign =
    isDigitalSign &&
    !isSigned &&
    (isEmployee || isGuest) &&
    [USER_REVIEW_STATUS.ACTIVE, USER_REVIEW_STATUS.SIGNING].includes(item.status);

  const isDisableToSignEmployee =
    item && item.coaches && signatures && item.coaches.length !== signatures.length;
  const isDisableToSign = isEmployee && !isSigned && isDisableToSignEmployee;

  const signedUsersIds = watch('signatures').map((s) => s.userId);
  const isAllowToResetSignatures = (isGuest || isAdmin || isCreator) && !isArchived;
  const isAllowToSign = (userId: IUser['id']) => {
    // enable button only for current user
    if (user.id !== userId) {
      return false;
    }

    if (userId === watch('employee').id) {
      const isAllGuestsSigned = watch('guests').every((guestId) =>
        signedUsersIds.includes(guestId),
      );
      return isAllGuestsSigned && !isArchived;
    }

    return watch('guests').includes(userId) && !signedUsersIds.includes(userId) && !isArchived;
  };

  const fetchUserReview = async () => {
    const result = await getUserReview(userReviewId, {
      populate: [POPULATE_USER_REVIEW_BY_ID.QUESTIONS, POPULATE_USER_REVIEW_BY_ID.ATTACHMENTS],
      join: [
        JOIN_USER_REVIEW_BY_ID.TASKS,
        JOIN_USER_REVIEW_BY_ID.EMPLOYEE_TASKS_IN_OTHER_USER_REVIEWS,
        JOIN_USER_REVIEW_BY_ID.CALENDAR_EVENT,
        JOIN_USER_REVIEW_BY_ID.REVIEW_TEMPLATE,
      ],
      joinToQuestions: [
        JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID.REVIEW_RATINGS,
        JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID.REVIEW_THEMES,
        JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID.SKILLS,
        JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID.JOB_PROFILES,
      ],
    });
    const userReview: IUserReview & {
      tasks: ITask[];
      employeeTasksInOtherUserReviews: ITask[];
      userReviewQuestions: IUserReviewQuestion[];
    } = result.data[API_RETURN_FIELDS.USER_REVIEW];

    setItem(userReview);
    return userReview;
  };

  const setFormValues = async () => {
    setIsLoading(true);
    const userReview = await fetchUserReview();

    const employeeId = userReview.createdFor;
    const employee = users[userReview?.createdFor];

    setValue('name', turnMultiLangIntoArray(userReview.name, languageState.companyLanguages));
    setValue(
      'description',
      turnMultiLangIntoArray(userReview.description, languageState.companyLanguages),
    );
    setValue('status', userReview.status);
    const tasksSelf = userReview.tasks
      .filter(
        (task) =>
          [TASK_TYPE.REVIEW_SELF_EVALUATE, TASK_TYPE.REVIEW_PEER_NOMINATE].includes(task.type) &&
          task.userTo.id &&
          employeeId &&
          task.userTo.id === employeeId,
      )
      .sort((task) => (task.type === TASK_TYPE.REVIEW_SELF_EVALUATE ? -1 : 1)); // self review - first
    const tasksReceivedPeers = userReview.tasks.filter(
      (task) =>
        [TASK_TYPE.REVIEW_PEER_EVALUATE].includes(task.type) &&
        task.userFrom &&
        employeeId &&
        task.userFrom === employeeId,
    );

    const tasksReceivedCoaches = userReview.tasks.filter(
      (task) =>
        [TASK_TYPE.REVIEW_COACH_EVALUATE].includes(task.type) &&
        task.userFrom &&
        employeeId &&
        task.userFrom === employeeId,
    );

    const taskNominatePeers =
      userReview.tasks.find((task) => [TASK_TYPE.REVIEW_PEER_NOMINATE].includes(task.type)) || null;

    const tasksProvidedPeers = userReview.employeeTasksInOtherUserReviews.filter((task) =>
      [TASK_TYPE.REVIEW_PEER_EVALUATE].includes(task.type),
    );

    const tasksProvidedCoaches = userReview.employeeTasksInOtherUserReviews.filter((task) =>
      [TASK_TYPE.REVIEW_COACH_EVALUATE].includes(task.type),
    );

    setValue('tasksSelf', tasksSelf);
    setValue('taskNominatePeers', taskNominatePeers);
    setValue('tasksReceivedPeers', tasksReceivedPeers);
    setValue('tasksReceivedCoaches', tasksReceivedCoaches);
    setValue('tasksProvidedPeers', tasksProvidedPeers);
    setValue('tasksProvidedCoaches', tasksProvidedCoaches);
    setValue('employee', employee);
    setValue('guests', userReview.guests);
    setValue('coaches', userReview.coaches);
    setValue('signatures', userReview.signatures);
    setValue('settings', userReview.settings);
    setValue('privacy', userReview.privacy);
    setValue('dateOfConversation', userReview.dateOfConversation);
    setValue('dateOfConversationEnd', userReview.dateOfConversationEnd);
    setValue('attachments', (userReview.attachments as unknown as IFile[]) || []);
    // @ts-ignore
    setValue('calendarEvent', userReview.calendarEvent as unknown as ICalendarEvent);
    setValue(
      'evaluators',
      _.chain(userReview.userReviewQuestions)
        // @ts-ignore
        .map((q) => q?.settings?.evaluators)
        .flatten()
        .uniq()
        .value(),
    );

    setIsLoading(false);
  };

  const goToReviews = () => {
    query.goBack();
  };

  const onEdit = () => {
    if (isAllowToEdit) {
      const pathSelf = routes.UPDATE_REVIEW_SELF.build(
        { companyId: undefined, teamId: undefined, role: ROLES.USER },
        // @ts-ignore
        { reviewId: item.review, isBackPath: true },
      );
      const pathIndividual = routes.UPDATE_REVIEW_INDIVIDUAL.build(
        {
          role: ROLES.USER,
          companyId: undefined,
          teamId: undefined,
        },
        {
          isBackPath: true,
          // @ts-ignore
          reviewId: item.review,
        },
      );

      history.push(item.type === REVIEW_TYPES.SELF ? pathSelf : pathIndividual);
    }
  };

  const onArchive = async () => {
    if (isAllowToArchive) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Archive review?`),
        description: i18n._(
          t`Are you sure you want to archive this review? The review can no longer be edited.`,
        ),
      });

      if (isConfirmed) {
        await archiveUserReviewById(userReviewId);
        addToast({
          title: i18n._(t`Review archived`),
          type: TOAST_TYPES.INFO,
        });
        // re-fetch data
        await setFormValues();
      }
    }
  };

  const onUnarchive = async () => {
    if (isAllowToUnarchive) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Unarchive review?`),
        description: i18n._(
          t`Are you sure you want to unarchive this review? The review can again be edited.`,
        ),
      });

      if (isConfirmed) {
        await unarchiveUserReviewById(userReviewId);
        addToast({
          title: i18n._(t`Review unarchived`),
          type: TOAST_TYPES.INFO,
        });
        // re-fetch data
        await setFormValues();
      }
    }
  };

  const onDelete = async () => {
    if (isAllowToDelete) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete review?`),
        description: i18n._(
          t`Are you sure you want to delete this review? This action cannot be undone.`,
        ),
      });

      if (isConfirmed) {
        await deleteUserReviewById(userReviewId);

        addToast({
          title: i18n._(t`Review deleted`),
          type: TOAST_TYPES.INFO,
        });

        goToReviews();
      }
    }
  };

  const onExportPDF = () => {
    if (isAllowToExportPDF) {
      // TODO
    }
  };

  const onGiveSelfReview = (task: ITask) => {
    routes.REVIEW_GIVE_FEEDBACK_SELF.go(undefined, { taskId: task.id, isBackPath: true });
  };

  const onGiveCoachReview = (task: ITask) => {
    routes.REVIEW_GIVE_FEEDBACK_COACH.go(undefined, { taskId: task.id, isBackPath: true });
  };

  const openNominatePeersModal = () => {
    setShowNominatePeersModal(true);
  };

  const closeNominatePeersModal = (isRefresh = false) => {
    setShowNominatePeersModal(false);

    // refresh data
    if (isRefresh) {
      setFormValues();
    }
  };

  const openSelectCoachesModal = () => {
    setShowSelectCoachesModal(true);
  };

  const closeSelectCoachesModal = (isSubmit: boolean) => {
    setShowSelectCoachesModal(false);

    // refresh data
    if (isSubmit) {
      setFormValues();
    }
  };

  const openSelectGuestsModal = () => {
    setShowSelectGuestsModal(true);
  };

  const closeSelectGuestsModal = () => {
    setShowSelectGuestsModal(false);

    // refresh data
    setFormValues();
  };

  const openPlanCalendarEventModal = () => {
    setShowPlanCalendarEventModal(true);
  };

  const closePlanCalendarEventModal = (isSubmit?: boolean) => {
    setShowPlanCalendarEventModal(false);

    if (isSubmit) {
      // refresh data
      setFormValues();
    }
  };

  const onDeleteCoach = async (task: ITask) => {
    if (isAllowToDeleteCoaches) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete coach?`),
        description: i18n._(
          t`Are you sure you want to delete this coach from the review? Provided input will be lost. This cannot be undone.`,
        ),
      });

      if (isConfirmed) {
        await updateCoaches(userReviewId, {
          delete: [task.userTo?.id as string],
        });

        addToast({
          title: i18n._(t`Review coach deleted`),
          type: TOAST_TYPES.INFO,
        });

        // refresh data
        await setFormValues();
      }
    }
  };

  const onDeleteGuest = async (guestId: string) => {
    if (isAllowToDeleteCoaches) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete guest?`),
        description: i18n._(t`Are you sure you want to delete this guest from the review?`),
      });

      if (isConfirmed) {
        await updateGuests(userReviewId, {
          delete: [guestId],
        });

        addToast({
          title: i18n._(t`Review guest deleted`),
          type: TOAST_TYPES.INFO,
        });

        // refresh data
        await setFormValues();
      }
    }
  };

  const onDeletePeer = async (task: ITask) => {
    if (isAllowToDeletePeers) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete peer?`),
        description: i18n._(
          t`Are you sure you want to delete this peer from the review? Provided input will be lost. This cannot be undone.`,
        ),
      });

      if (isConfirmed) {
        const isPeerOutside = task.userTo?.email;
        await updatePeers(userReviewId, {
          delete: [
            {
              type: isPeerOutside ? USER_REVIEW_PEER_TYPE.EMAIL : USER_REVIEW_PEER_TYPE.USER,
              value: task.userTo?.email || task.userTo?.id,
            } as IPeer,
          ],
        });

        addToast({
          title: i18n._(t`Review peer deleted`),
          type: TOAST_TYPES.INFO,
        });

        // refresh data
        await setFormValues();
      }
    }
  };

  const onAddCoaches = async (coaches: IUser[]) => {
    if (isAllowToAddCoaches) {
      await updateCoaches(userReviewId, {
        add: coaches.map((coach) => coach.id),
      });

      addToast({
        title: i18n._(t`Review coaches added`),
        type: TOAST_TYPES.INFO,
      });

      // refresh data
      await setFormValues();
    }
  };

  const onAddGuests = async (guests: IUser[]) => {
    if (isAllowToAddGuests) {
      await updateGuests(userReviewId, {
        add: guests.map((guest) => guest.id),
      });

      addToast({
        title: i18n._(t`Review guests added`),
        type: TOAST_TYPES.INFO,
      });

      // refresh data
      await setFormValues();
    }
  };

  useEffect(() => {
    setFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReviewId, $refetch.value]);

  return {
    userReview: item,
    isLoading,
    isEmployee,
    employeeFirstName,
    // delete
    isShowToDelete,
    isDisableToDelete,
    isAllowToDelete,
    // edit
    isAllowToEdit,
    isShowToEdit,
    isDisableToEdit,
    // archive
    isAllowToArchive,
    isAllowToUnarchive,
    // export PDF
    isAllowToExportPDF,
    // sign
    isShowToSign,
    isDigitalSign, // is setting enabled
    isDisableToSign,
    goToReviews,
    onEdit,
    onDelete,
    onArchive,
    onUnarchive,
    onExportPDF,
    isAllowToEditSummaryOrNextSteps,
    isAllowToGiveSelfReview,
    isAllowToNominatePeers,
    isAllowToGiveCoachReview,
    isAllowToGivePeerReview,
    showNominatePeersModal,
    openNominatePeersModal,
    closeNominatePeersModal,
    onGiveSelfReview,
    onGiveCoachReview,
    isAllowToDeletePeers,
    isAllowToDeleteCoaches,
    isAllowToDeleteGuests,
    isAllowToAddPeers,
    isAllowToAddCoaches,
    isAllowToAddGuests,
    isAllowToPlanConversation,
    isAllowedToAccessMeetingLink,
    onDeleteCoach,
    onDeletePeer,
    onDeleteGuest,
    onAddCoaches,
    onAddGuests,
    showSelectCoachesModal,
    openSelectCoachesModal,
    closeSelectCoachesModal,
    showSelectGuestsModal,
    openSelectGuestsModal,
    closeSelectGuestsModal,
    showPlanCalendarEventModal,
    openPlanCalendarEventModal,
    closePlanCalendarEventModal,
    isAllowToSign,
    isAllowToResetSignatures,
    isAdmin,
    isGuest,
    refetch: $refetch,
    isEmployeeQuestions,
    isPeerQuestions,
    isCoachQuestions,
    isCreator,
    isInputCoach,
  };
};
