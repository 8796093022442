import styled from 'styled-components';

import { COLORS } from '~/styles';

import Divider from '../UI/Divider';

export const ModalContainer = styled.div`
  box-sizing: border-box;
  padding: 30px 22px 30px 30px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  height: 36px;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const ContentContainer = styled.div`
  max-height: 672px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 10px;
  margin-bottom: 27px;
`;
