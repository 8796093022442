import { useEffect, useMemo, useState } from 'react';

import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';

import { useInactiveUsers } from '~/pages/Reviews/hooks/useInactiveUsers';

import { getUser } from '~/selectors/baseGetters';
import { getUserTeams } from '~/services/teams';

import type { IReviewDashboardUserForm } from '../types';
import type { ITeam } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

export const useUserPreview = ({
  isAdmin,
  isEmployee,
  isInputCoach,
  formMethods,
}: {
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  isAdmin?: boolean;
  isEmployee?: boolean;
  isInputCoach?: boolean;
}) => {
  const [coaches, setCoaches] = useState<string[]>();
  const { removeInactiveUsers } = useInactiveUsers();
  const user = useSelector(getUser);
  const { watch } = formMethods;

  const fetchCoaches = async (employeeId: string) => {
    // @ts-ignore
    const res = await getUserTeams(employeeId, { isMember: true });
    setCoaches(
      removeInactiveUsers(
        uniq(flatten(Object.values(res as Record<string, ITeam>).map((item) => item.coaches))),
      ),
    );
  };

  useEffect(() => {
    if (watch('employee.id')) {
      fetchCoaches(watch('employee.id'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('employee.id')]);

  const showUserPreviewDrawer = useMemo(() => {
    if (isAdmin || isEmployee || isInputCoach) {
      return true;
    }

    if (coaches?.includes(user?.id) && watch('privacy.isAllEmployeeCoachesCanSeeReview')) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([coaches, isAdmin, isEmployee, isInputCoach, user?.id, watch('privacy')])]);

  return {
    showUserPreviewDrawer,
  };
};
