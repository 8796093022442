import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  GOAL_TYPES,
  Locals,
  REVIEW_QUESTION_TYPES,
  SKILL_CATEGORIES_ID,
} from '@learned/constants';
import { IReviewTheme } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { uniq } from 'lodash';
import size from 'lodash/size';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import Editor from '~/components/Editor';
import { Input } from '~/components/Input';
import { LabelWrapper, SubText } from '~/pages/ReviewThemeSetup/design';
import type {
  IQuestionForm,
  ISelectedSkill,
  ISkill,
  ISkillCategory,
} from '~/pages/ReviewThemeSetup/types';
import { handleEnterKeyPressOnFields } from '~/pages/ReviewThemeSetup/utils';

import {
  Label,
  Row,
  Wrapper,
  QuestionDescription,
  Button,
  QuestionDescriptionButtonWrap,
} from './design';
import { prepareDefaultValue, preparePlaceholder } from './utils';

import { QUESTION_TYPES } from '~/constants/questionsTypes';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getReviewTheme } from '~/services/reviewThemes';
import { getSkillsById } from '~/services/skills';

import { CustomSkill, Ratings } from '../../../Questions';
import { getRatingScale } from '../../utils';
import { AnswerTypeSelector } from '../AnswerTypeSelector';
import { CustomSkillSection } from '../CustomSkillSection';
import { Footer } from '../Footer';
import { SubGoalTypeSelector } from '../SubGoalTypeSelector';
import { SubSkillCategorySelector } from '../SubSkillCategorySelector';
import { ThemeSelector } from '../ThemeSelector';

interface IEditableQuestionProps {
  skills?: ISkill[];
  formMethods: UseFormReturn<IQuestionForm>;
  languageState: ILanguageStateReturn;
  onDelete?: (id: IQuestionForm['id']) => void;
  onDuplicate?: (id: IQuestionForm['id'], index?: number) => Promise<void>;
  setSelectedSkill?: (skill: ISkill | null) => void;
  setIsSkillModalOpen: (open: boolean) => void;
  selectTheme?: boolean;
  skillCategories: ISkillCategory[];
  selectedSkills?: ISelectedSkill[];
  setSelectedSkills: (skills: ISelectedSkill[]) => void;
  onThemeSelect?: (themeId: string) => void;
  setCurrentStep?: (step: number) => void;
  selectedQuestionToEdit?: IQuestionForm | null;
  reviewThemeId?: string;
}

const EditableQuestion = ({
  skills,
  languageState,
  formMethods,
  onDelete,
  onDuplicate,
  skillCategories,
  selectedSkills,
  setSelectedSkill,
  setIsSkillModalOpen,
  selectTheme,
  selectedQuestionToEdit,
  onThemeSelect,
  setCurrentStep,
  setSelectedSkills,
  reviewThemeId,
}: IEditableQuestionProps) => {
  const { i18n } = useLingui();
  const { watch, control, register, unregister, setValue, getValues } = formMethods;

  const typeWatch = watch('type');
  const skillCategoryWatch = watch('skillOrKpiCategory') as {
    key: string;
    value: string;
    id?: string;
    type?: string;
  };
  const getMultiLangString = useMultiLangString();
  const currentCompany = useSelector(getCurrentCompany);
  const [selectedTheme, setSelectedTheme] = useState<IReviewTheme>();

  const [skillsList, setSkillsList] = useState<ISkill[]>([]);

  const {
    products: {
      performance: {
        settings: {
          labels: { ratingLabels, skillLabels },
        },
      },
    },
  } = currentCompany;

  const ANSWER_TYPES = [
    {
      id: QUESTION_TYPES.RATING,
      key: QUESTION_TYPES.RATING,
      title: i18n._(t`Rating`),
      order: 1,
    },
    {
      id: QUESTION_TYPES.SKILL_CATEGORY,
      key: QUESTION_TYPES.SKILL_CATEGORY,
      title: i18n._(t`Skill or KPI from job profile`),
      order: 2,
    },
    {
      id: QUESTION_TYPES.CUSTOM_SKILL,
      key: QUESTION_TYPES.CUSTOM_SKILL,
      title: i18n._(t`Custom skill`),
      order: 3,
    },
    {
      id: QUESTION_TYPES.GOAL_PLAN,
      key: QUESTION_TYPES.GOAL_PLAN,
      title: i18n._(t`Plan goals`),
      order: 4,
    },
    {
      id: QUESTION_TYPES.TEXT,
      key: QUESTION_TYPES.TEXT,
      title: i18n._(t`Text answer`),
      order: 5,
    },
  ];

  const answersList: { key: string; value: string; id?: string }[] = [
    ...ANSWER_TYPES.sort((a, b) => a.order - b.order)
      .map((answerType) => {
        return { value: answerType.title, key: answerType.id };
      })
      .flat(),
  ];

  const skillOrKpiCategory = [
    ...skillCategories.map(({ value, label, type }) => {
      return {
        value: getMultiLangString(label || ''),
        key: QUESTION_TYPES.SKILL_CATEGORY,
        id: value,
        label: REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
        type,
      };
    }),
  ];

  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'description',
    control,
    unregister,
    languageState,
  });
  useEffect(() => {
    const fetchSkills = async () => {
      const selectedSkillIds = selectedSkills?.map((skill) => skill.skill) || [];
      const skills = await getSkillsById(uniq(selectedSkillIds));
      setSkillsList(skills);
    };
    fetchSkills();
  }, [selectedSkills]);

  useEffect(() => {
    const fetchTheme = async () => {
      if (selectedQuestionToEdit?.theme) {
        const { data } = await getReviewTheme(selectedQuestionToEdit?.theme);
        const reviewTheme: IReviewTheme = data[API_RETURN_FIELDS.REVIEW_THEME];
        setSelectedTheme(reviewTheme);
      } else if (reviewThemeId) {
        const { data } = await getReviewTheme(reviewThemeId);
        const reviewTheme: IReviewTheme = data[API_RETURN_FIELDS.REVIEW_THEME];
        setSelectedTheme(reviewTheme);
        onThemeSelect && onThemeSelect(reviewTheme.id);
      }

      if (selectedQuestionToEdit?.theme) {
        onThemeSelect?.(selectedQuestionToEdit.theme);
      }
    };
    fetchTheme();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestionToEdit]);

  useEffect(() => {
    setValue('settings.skills', selectedSkills);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSkills]);

  const subTypesWatch = watch('settings.subTypes');
  useEffect(() => {
    if (!subTypesWatch && typeWatch.key === QUESTION_TYPES.GOAL_PLAN) {
      setValue('settings.subTypes', [GOAL_TYPES.BUSINESS, GOAL_TYPES.PERSONAL]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTypesWatch, typeWatch]);

  useEffect(() => {
    const { options: scale, type } = getValues();

    let ratingScale;

    if (scale?.length) {
      ratingScale = scale;
    } else {
      ratingScale = getRatingScale(
        type.key as REVIEW_QUESTION_TYPES,
        ratingLabels,
        skillLabels,
        languageState,
      );
    }

    setValue('options', ratingScale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeWatch]);

  const getAnswerType = (type?: string, subCategory?: string) => {
    switch (type) {
      case QUESTION_TYPES.RATING:
        return <Ratings formMethods={formMethods} ratingLabels={ratingLabels} />;
      case QUESTION_TYPES.SKILL_CATEGORY:
        if (subCategory === SKILL_CATEGORIES_ID.KPIS) {
          return <Ratings formMethods={formMethods} ratingLabels={ratingLabels} />;
        } else {
          return;
        }
      case QUESTION_TYPES.GOAL_PLAN:
        return (
          <QuestionDescription>
            <Trans>
              A question will be generated that allows the participant to set one or multiple goals.
            </Trans>
          </QuestionDescription>
        );

      default:
        return null;
    }
  };

  const onThemeChange = (theme: IReviewTheme) => {
    setSelectedTheme(theme);
    onThemeSelect && onThemeSelect(theme?.id);
  };

  const onAddSkillClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentStep?.(0);
    setIsSkillModalOpen(true);
  };

  const shouldRenderCustomSkill = () => {
    const isNotRatingOrTextOrGoalPlan = ![
      QUESTION_TYPES.RATING,
      QUESTION_TYPES.TEXT,
      QUESTION_TYPES.GOAL_PLAN,
    ].includes(typeWatch.key as REVIEW_QUESTION_TYPES);

    const isNotSkillCategoryKPI =
      typeWatch?.key !== QUESTION_TYPES.SKILL_CATEGORY ||
      skillCategoryWatch?.type !== SKILL_CATEGORIES_ID.KPIS;

    return isNotRatingOrTextOrGoalPlan && isNotSkillCategoryKPI;
  };

  return (
    <Wrapper>
      {typeWatch?.key === QUESTION_TYPES.GOAL_PLAN && (
        <Row paddingTop={0} paddingBottom={16}>
          <Banner
            type={BANNER_TYPES.INFO}
            subTitle={i18n._(
              t`A question is generated that allows the participant to set one or more goals.`,
            )}
          />
        </Row>
      )}
      <Row paddingTop={0}>
        <AnswerTypeSelector answersList={answersList} formMethods={formMethods} />
      </Row>
      {typeWatch?.key === QUESTION_TYPES.CUSTOM_SKILL && (
        <QuestionDescriptionButtonWrap>
          <Trans>Skill</Trans>
          {selectedSkills && selectedSkills.length > 0 && (
            <Button onClick={onAddSkillClick}>
              <Trans>Add skill</Trans>
            </Button>
          )}
        </QuestionDescriptionButtonWrap>
      )}
      {typeWatch?.key === QUESTION_TYPES.SKILL_CATEGORY && (
        <Row>
          <SubSkillCategorySelector
            skillOrKpiCategory={skillOrKpiCategory}
            formMethods={formMethods}
            skillCategoryWatch={skillCategoryWatch}
          />
        </Row>
      )}
      {typeWatch?.key === QUESTION_TYPES.CUSTOM_SKILL && (
        <CustomSkillSection
          skillCategories={skillCategories}
          formMethods={formMethods}
          skillsList={skillsList}
          selectedSkills={selectedSkills}
          setSelectedSkills={setSelectedSkills}
          setSelectedSkill={setSelectedSkill}
          setIsSkillModalOpen={setIsSkillModalOpen}
          setCurrentStep={setCurrentStep}
          onAddSkillClick={onAddSkillClick}
        />
      )}
      {typeWatch?.key === QUESTION_TYPES.GOAL_PLAN && (
        <Row paddingTop={10}>
          <SubGoalTypeSelector formMethods={formMethods} />
        </Row>
      )}
      <Row paddingTop={20}>
        {nameFieldArray.fields?.map(({ locale }, index) => {
          const isFirstField = index === 0;
          const isKpiType = skillCategoryWatch?.type === SKILL_CATEGORIES_ID.KPIS;
          const currentValue = getValues(`name.${index}.value`);

          const messages = {
            kpi: i18n._(t`How did you/this employee perform on the KPIs`),
            behavior: i18n._(t`How often do you/this employee show the expected behavior?`),
            goal: i18n._(t`What personal goals will you/employee work on in the upcoming period?`),
          };

          // Logic to determine the default value
          const defaultVal = prepareDefaultValue({
            questionType: typeWatch?.key as QUESTION_TYPES,
            isKpiType,
            currentValue,
            messages,
          });

          const isDefaultLang =
            locale === languageState.companyPrimaryLanguage.locale &&
            // currently it's only available for dutch and english
            [Locals.en_GB, Locals.nl_NL].includes(locale as Locals);

          if (!currentValue && isDefaultLang) {
            setValue(`name.${index}.value`, defaultVal);
          }

          // Change the default value if the question text is not changed but the type is
          if (
            [messages.kpi, messages.behavior, messages.goal].includes(currentValue) &&
            isDefaultLang
          ) {
            setValue(`name.${index}.value`, defaultVal);
          }

          const placeholder = preparePlaceholder({
            i18n,
            questionType: typeWatch.key as QUESTION_TYPES,
            isKpiType,
          });

          return (
            <div className="inputWrapper" key={`${locale}-${index}`}>
              <Input
                error={!!formMethods.formState.errors.name?.[index]?.value}
                name={`name.${index}.value`}
                label={isFirstField ? i18n._(t`Question`) : undefined}
                height="38px"
                placeholder={placeholder}
                register={register(`name.${index}.value`, { required: true })}
                leftIcon={
                  size(nameFieldArray.fields) > 1
                    ? getUnicodeFlagIcon(locale.substring(locale.indexOf('_') + 1))
                    : undefined
                }
                onKeyDownCapture={handleEnterKeyPressOnFields}
                defaultValue={currentValue || defaultVal}
              />
            </div>
          );
        })}
      </Row>
      <Row>
        <LabelWrapper>
          <Label>
            <Trans>Description</Trans>
          </Label>
          <SubText>
            <Trans>(optional)</Trans>
          </SubText>
        </LabelWrapper>
        {descriptionFieldArray.fields?.map(({ locale }, index) => (
          <div className="inputWrapper" key={`${locale}-${index}`}>
            <Controller
              {...register(`description.${index}.value`)}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Editor
                  minHeight={'120px'}
                  value={value}
                  compact
                  // @ts-ignore
                  onChange={(args) => {
                    onChange(args);
                  }}
                  big
                  leftIcon={
                    size(nameFieldArray.fields) > 1
                      ? getUnicodeFlagIcon(locale.substring(locale.indexOf('_') + 1))
                      : undefined
                  }
                />
              )}
            />
          </div>
        ))}
      </Row>

      {shouldRenderCustomSkill() && (
        <CustomSkill
          formMethods={formMethods}
          selectedSkills={selectedSkills}
          skills={skills}
          skillCategories={skillCategories}
          setSelectedSkill={setSelectedSkill}
          setIsSkillModalOpen={setIsSkillModalOpen}
          setCurrentStep={setCurrentStep}
          setSelectedSkills={setSelectedSkills}
          ratingLabels={skillLabels}
        />
      )}

      <Row paddingTop={8} paddingBottom={!selectTheme ? 28 : 0}>
        {getAnswerType(typeWatch?.key, skillCategoryWatch?.type)}
      </Row>

      {selectTheme && (
        <Row paddingBottom={28}>
          <ThemeSelector
            formMethods={formMethods}
            selectedTheme={selectedTheme}
            onThemeChange={onThemeChange}
          />
        </Row>
      )}
      {typeWatch && (
        <Footer
          isLeftSideVisible={!!selectedQuestionToEdit}
          formMethods={formMethods}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
          tooltips={{
            peers:
              typeWatch.key === QUESTION_TYPES.GOAL_PLAN
                ? i18n._(t`Peers can’t create goals for others.`)
                : '',
          }}
        />
      )}
    </Wrapper>
  );
};

export { EditableQuestion };
