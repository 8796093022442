import styled from 'styled-components';

import { COLORS } from '~/styles';

const PageWrapper = styled.div`
  padding: 40px 56px;

  div.secondary-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    div.review-start-date {
      color: ${COLORS.ICONS_PRIMARY};
      margin-right: 30px;
      white-space: nowrap;
    }

    .logo {
      background-size: contain;
      width: 100%;
      background-position: left;
    }
  }
`;

export { PageWrapper };
