import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{ isMenuOpened?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ isMenuOpened }) => (isMenuOpened ? '380px' : '56px')};
  height: calc(100vh - 64px);
  border-right: 1px solid ${COLORS.BORDERS};
  box-sizing: border-box;
  padding: 8px 0;
  position: absolute;
  top: 64px;
  left: 0;
  bottom: 0;
  background-color: ${COLORS.BG_PAGE};
  z-index: 1000;

  ${({ isMenuOpened }) =>
    isMenuOpened
      ? css`
          .toggleButton {
            margin-right: 12px;
            align-self: flex-start;
            justify-content: flex-end;
          }
        `
      : css`
          .title {
            display: none;
          }
          .toggleButton {
            justify-content: center;
          }
        `}

  .toggleButton {
    display: flex;
    width: 100%;
    gap: 2px;
    align-items: center;
    background-color: transparent;
    border: none;
    font-size: 12px;
    color: ${COLORS.PLACEHOLDERS};
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

export const ItemsWrapper = styled.div`
  overflow-y: auto;
`;

export const ItemWrapper = styled.div`
  padding: 0 8px;
`;

export const Item = styled.div<{ isMenuOpened?: boolean; isContentVisible?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  border-radius: 6px;
  padding: 16px;
  box-sizing: border-box;
  color: ${COLORS.TEXT_HOVER};
  cursor: pointer;

  .title {
    display: flex;
    align-items: center;
    margin-left: 16px;
    font-size: 14px;
    font-weight: 600;
  }

  .left {
    display: flex;
    box-sizing: border-box;
  }

  .right {
    box-sizing: border-box;
    display: ${({ isContentVisible }) => (isContentVisible ? 'flex' : 'none')};

    svg {
      rotate: ${({ isContentVisible }) => (isContentVisible ? '-90deg' : '0deg')};
    }
  }

  ${({ isMenuOpened }) =>
    isMenuOpened
      ? css``
      : css`
          justify-content: center;
        `}

  &:hover {
    color: ${COLORS.COMPANY};

    ${({ isMenuOpened }) =>
      isMenuOpened
        ? css`
            background-color: ${COLORS.WHITE};
            .right {
              display: block;
            }
          `
        : css`
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: ${COLORS.WHITE};
              min-width: 24px;
              height: 24px;
              border-radius: 100px;
              box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
            }
          `}
  }

  &:not(:hover) {
    .right {
      color: ${({ isContentVisible }) => (isContentVisible ? COLORS.GRAY_MIDDLE : 'unset')};
    }
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 8px 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  & > div {
    width: 100%;
  }
`;

export const Button = styled.button<{
  color?: string;
  isMenuOpened?: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 14px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  ${({ isMenuOpened }) =>
    !isMenuOpened &&
    css`
      justify-content: center;
    `}

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ color }) => color || COLORS.CYAN};
    color: ${COLORS.WHITE};
    width: 24px;
    height: 24px;
    border-radius: 100px;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.08);
  }

  .title {
    color: ${COLORS.SUBTEXT};
    font-size: 12px;
  }

  &:hover {
    .title {
      color: ${COLORS.COMPANY};
    }

    .icon {
      box-shadow: none;
    }
  }

  &:disabled {
    cursor: not-allowed;
    &:hover .title {
      color: ${COLORS.SUBTEXT};
    }
    .icon {
      background-color: ${COLORS.PLACEHOLDERS};
    }
  }
`;
