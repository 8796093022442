import React from 'react';

import { DashboardSidebar } from '~/components/DashboardSidebar';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { UserPreviewDrawer } from '~/components/UserPreviewDrawer';

import { MainWrapper, Wrapper } from './design';
import { DashboardLayoutProps } from './types';

import useBoolState from '~/hooks/useBoolState';

const DashboardLayout = ({
  userId,
  sidebar,
  main,
  isLoading,
  showUserPreviewDrawer,
}: DashboardLayoutProps) => {
  const isMenuOpened = useBoolState(true);

  return (
    <>
      <ShowSpinnerIfLoading loading={!!isLoading && !userId}>
        <Wrapper isMenuOpened={isMenuOpened.value}>
          <DashboardSidebar
            isMenuOpened={isMenuOpened.value}
            setIsMenuOpened={isMenuOpened.set}
            items={sidebar.items}
            buttons={sidebar.buttons}
          />
          <MainWrapper>
            <div className="content">{main}</div>
          </MainWrapper>
          {userId && showUserPreviewDrawer && <UserPreviewDrawer userId={userId} />}
        </Wrapper>
      </ShowSpinnerIfLoading>
    </>
  );
};

export { DashboardLayout };
