import React from 'react';

import { IReview } from '@learned/types';
import { useSelector } from 'react-redux';

import ResultsTable from './ResultsTable';

import { getSettingsRole } from '~/selectors/baseGetters';

const ResultsTabOldView = ({ review, hideSearch }: { review: IReview; hideSearch: boolean }) => {
  const userRole = useSelector(getSettingsRole);

  return (
    <div>
      <ResultsTable viewAs={userRole} review={review} hideSearch={hideSearch} />
    </div>
  );
};

export default ResultsTabOldView;
